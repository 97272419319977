import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BaseListTablePage } from "app/common/components/pages/base-list-table.page";
import { DataTableAction, DataTableColumn, ListTableManager } from "app/common/components/table/types";
import { ModalService } from "app/common/services/modal.service";
import { DataTableUtils } from "app/common/utils/data-table.utils";
import { iconCancel } from "app/common/utils/icons.utils";
import { Roles } from "app/models/profile.models";
import { AuthService } from "app/services/auth.service";
import { ProfileService } from "app/services/profile.service";
import { Observable, of } from "rxjs";

class Evaluator {
	evaluationDescription;
	indiceQualitativo;
	directExperience;
	evaluationValidated;
	certificationDate;
	email;
	name;
	userId;
}

@Component({
    selector: 'evaluators-list',
    templateUrl: './evaluators-list.modal.html'
})

export class EvaluatorsList extends BaseListTablePage<Evaluator> {
	downloadExcelEnabled = false;
	deleteEntityEnabled = false;
	numberOfAvailableItems: number = 0;
    public tableColumns: DataTableColumn[] = [];
    public tableActions: DataTableAction[] = [];
    public filters: Filters = new Filters();
	privateKey;
	iconCancel = iconCancel;
    constructor(private _activeModal: NgbActiveModal, _activatedRoute: ActivatedRoute, private _modalService: ModalService, private _authService: AuthService, private _profileService: ProfileService) {
        super(_activatedRoute, true);
    }

	get title() {
		if (this.data) return "valutazioni di " + this.data.sign;
	}
	
	initialize = (): void => {

		this.createColumms();
		this.createActions();
    }

	data: any;
	
	private getEvaluation = (): Observable<Evaluator[]> => {
		console.log(this.data);
		
		if (this.data.evaluation) {
			console.log(this._profileService.getLoggedProfile());
			return of(this.data.evaluation.usersEvaluation)
		}
		return of([]);
	}

    protected getDataTableManager = (): ListTableManager<Evaluator> => {
        return new ListTableManager(this.getEvaluation)
    }

    private createColumms = (): void => {
			
		let fields = [
			{id: "userId", hidden: true},
			{id: "name", dataTableField: DataTableUtils.createStringColumn("name", "Nome del valutatore", true), hidden: false},
			{id: "certificationDate", dataTableField: DataTableUtils.createDateTimeColumn("certificationDate", "Data valutazione", false), hidden: false},

			{id: "indiceQualitativo", dataTableField: DataTableUtils.createStringColumn("indiceQualitativo", "indice di romanità qualitativa", false), hidden: false},
			{id: "evaluationDescription", dataTableField: DataTableUtils.createStringColumn("evaluationDescription", "Giudizio descrittivo", false), hidden: false},
			{id: "directExperience", dataTableField: DataTableUtils.createBooleanColumn("directExperience", "Visita Ristorante", false), hidden: false},
			{id: "evaluationValidated", dataTableField: DataTableUtils.createBooleanColumn("evaluationValidated", "Certificazione processo di valutazione", false), hidden: false},
			
		];

		
		fields.forEach(element => {
			if (!element.hidden)
				this.tableColumns.push(element.dataTableField);
		});
    }

    private createActions = (): void => {
		let button = new DataTableAction();
		button.funcToInvoke = this.showBlockchainCert;
		button.label = "Visualizza certificato in blockchain";
		button.icon = "fas fa-shield-alt";
		
		this.tableActions.push(button);

	
    }
	
	public showBlockchainCert = (row: Evaluator): void => {
		let id = "user_"+row.userId+ "_ROMANESCO_evaluation_" + this.data.id;
		let url = "https://verifier.posti.world/ROMANESCO/"+id+"/verify"
		window.open(url, "_blank");
	}
	public close = (): void => {
		this._activeModal.close(false);
    }
}


class Filters {
}