import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { iconCancel } from "app/common/utils/icons.utils";

@Component({
    selector: 'simple-qrcode',
    templateUrl: './simple-qrcode.component.html'
})
export class SimpleQRCodeComponent implements OnInit {
	public data: any;
	title = "QRcode";
	url = "";
	iconCancel= iconCancel;
	constructor(private _activeModal: NgbActiveModal) {
	}

	ngOnInit() {
		this.url = this.data.url;
		if ( this.data.title)
			this.title = this.data.title;
	}

	close = () => {
		this._activeModal.close(false);
	}
}