<base-page>
    <ng-container page-body>
        <div class="card">
            <div class="card-header">
                Elenco Utenti
            </div>
			<div class="card-header">
				<div class="row">
					<div class="col-6">
						<span class="badge badge-success">{{numberOfAvailableItems}}</span> utenti trovati
					</div>
					<!-- <div class="col-6 text-right">
						<button type="button" *ngIf="downloadExcelEnabled" (click)="downloadExcel()"  class="btn btn-primary">
							<i class="fas fa-file-excel"></i>&nbsp;scarica Excel
						</button>
						&nbsp;
						<button type="button" (click)="addEntity()"  class="btn btn-primary">
							<i class="fas fa-plus"></i>&nbsp;nuovo
						</button>
						&nbsp;
						<button type="button" *ngIf="currentDomain=='ROMANESCO'" (click)="openFipeExcel()"  class="btn btn-primary">
							report web FIPE 1.3
						</button>
					</div> -->
				</div>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <list-table [columns]="tableColumns" [manager]="dataTableManager" [actions]="tableActions">
                            </list-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</base-page>