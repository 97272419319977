<div [ngClass]="{ 'card-content': true, 'bordered': true, 'invalid': invalid}">
	<div class="card-header">
		{{label | translate}}
		<small *ngIf="invalid" class="form-text text-muted danger"><em>{{errorMessage}}</em></small>

	</div>
	<div class="card-body">
		<ul ngbNav #navContainer="ngbNav" [(activeId)]="activeId" class="nav-tabs">
			<li *ngFor="let item of currentItems; let i=index" [ngbNavItem]="i" [formGroup]="item">
				<a ngbNavLink>
					<img width="20px" [ngClass]="{'invalid-tab': invalidIndex(i)}" src="assets/img/flags/{{item.controls.language.value}}.png">
					<span class="close position-relative pl-2 font-weight-light" (click)="removeItem($event, i)">×</span>
				</a>
				<ng-template ngbNavContent>
					<div *ngFor="let field of fields">
						<input-string *ngIf="field.fieldType == 'inputstring' && !field.hidden" [formControlName]="field.fieldName" [label]="field.label"></input-string>
						<input-string *ngIf="field.fieldType == 'multiline' && !field.hidden" [formControlName]="field.fieldName" [label]="field.label" [rows]="10"></input-string>
						<input-html *ngIf="field.fieldType == 'html' && !field.hidden" [formControlName]="field.fieldName" [label]="field.label"></input-html>
						<input-file *ngIf="field.fieldType == 'file' && !field.hidden" [formControlName]="field.fieldName" [label]="field.label"></input-file>
					</div>
				</ng-template>
			</li>
			<li ngbDropdown class="nav-item" *ngIf="remainingCulture.length > 0">
				<a href (click)="false" class="nav-link" ngbDropdownToggle><i class="ft-plus"></i></a>
				<div ngbDropdownMenu>
					<button *ngFor="let lang of remainingCulture" ngbDropdownItem (click)="addItem($event, lang.language)">
						<img width="15px" src="assets/img/flags/{{lang.language}}.png">
						{{lang.name}}
					</button>
		
				</div>
			</li>
		</ul>
		<div [ngbNavOutlet]="navContainer" class="mt-2"></div>
	</div>	
</div>
