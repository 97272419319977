import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { FormFieldDescription } from 'app/common/models/forms';
import { ActionCommand, SelectListitem } from 'app/common/models/models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { iconAdd, iconEdit, iconPropertiesEdit, iconTrash } from "app/common/utils/icons.utils";
import { DOMAINS_CONFIG, RestaurantProvider } from 'app/services/restaurant.service';

import { ActivatedRoute, Router } from '@angular/router';
import { SubmissionResult } from 'app/pages/forms/result-form-page/result-form-submission.page';
import { catchError } from 'rxjs/operators';
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { FORM_SUBMISSION_RESULT } from '../routing-constants';
import { environment } from 'environments/environment';
import { CertificationBaseFormPage } from '../CertificationBaseFormPage';
import { domainConfig } from 'app/services/DomainConfigProvider';

@Component({
    selector: 'bonfit-form-page',
    templateUrl: './bonfit-form.page.html'
})

export class BonfigFormPage extends CertificationBaseFormPage implements OnInit {
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	iconAdd = iconAdd;
	iconDelete = iconTrash;
	editorOptions;

	public panettoneBonfit: FormGroup = null;
	public producerInfo: FormGroup = null;
	public generalInfo: FormGroup = null;
	public ingrediants: FormArray = null;

	

	ngOnInit() {
		this.certificationConfig = environment.certificationConfig.DEFAULT_CONFIG;
		this.currentDomainConfig = domainConfig.BONFIT;
		this._activatedRoute.params.subscribe(params => {
			let id = params['id'];
			if (id != undefined || id != null) {

				this._restaurantProvider.getRestaurant(DOMAINS_CONFIG.BONFIT,id).subscribe((result) => {
					this.initForm(result);					
					this.panettoneBonfit.patchValue(this.unmarshalData(result));
				})
			}
			else {
				this.initForm();
			}
		});
	}

    constructor(private _activatedRoute: ActivatedRoute, private _fb: FormBuilder, _restaurantProvider: RestaurantProvider, private _router: Router) {
		super(_restaurantProvider);
    }


	private initForm = (initData?: any) => {
		
		this.producerInfo =this._fb.group({
			company: ["ETICA food srl - Bonfitt", Validators.required],
			city: ["Roma", Validators.required],
			address: ["Piazza Roma, 6 - Santa Severa", Validators.required],
			product: ["Panettone classico", Validators.required]
		});
		this.generalInfo =this._fb.group({
			productionLot: [null, Validators.required],
			productCode: [null, [Validators.required,Validators.pattern("^[0-9][0-9][0-9]\.[0-9a-zA-Z][0-9a-zA-Z]*$")]],
			photo: [null, Validators.required],
			packagingDate: [null, Validators.required],
			expiryDate: [null, Validators.required]
		});

		let ingrediants = [];
		if (initData && initData.ingrediants && initData.ingrediants.length>0) {
			initData.ingrediants.forEach(() => {
				ingrediants.push(this.createIngrediantsFormGrou());
			})
		}

		this.panettoneBonfit =this._fb.group({
			id: [null],
			producerInfo: this.producerInfo,
			generalInfo: this.generalInfo,
			ingrediants: this._fb.array(ingrediants)
		});
	}

	private marshalData = (panettoneBonfit: any): any => {
		return panettoneBonfit;		
	}

	private unmarshalData = (panettoneBonfit: any): any => {
		return panettoneBonfit;
	}

	createIngrediantsFormGrou(): FormGroup {
		return this._fb.group({
			ingrediant: [null, Validators.required],
			lot: [null],
			producer: [null],
		});
	}
	addIngrediant() {
		(<FormArray>this.panettoneBonfit.get("ingrediants")).push(this.createIngrediantsFormGrou());
	}
	removeIngrediant(index) {
		(<FormArray>this.panettoneBonfit.get("ingrediants")).removeAt(index);
	}

    public save() {
        if (this.stepOneValid) {
            this._restaurantProvider.saveOrUpdateRestaurant(DOMAINS_CONFIG.BONFIT, this.marshalData(this.panettoneBonfit.value))
			.pipe(
				catchError(error => {
					return of(null);
				})
			)
			.subscribe(
				(result) => {
					
					this.certifyData(result).then((certResult) => {
						this._activatedRoute.queryParams.subscribe(qParam => {
							if (qParam.returnUrl) {
								this._router.navigateByUrl(qParam.returnUrl);
							}
							else {
								if (result) {
									//console.log("Salvataggio completato correttamente");
									this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_OK]);
								}
								else {
									//console.log("Salvataggio completato correttamente");
									this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
								}
							}
						})
					})
					

					
					
				},
				(error) => {
					//console.log("Salvataggio completato correttamente");
					this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
				}
			)
        }
		else {
			//console.log("form not valid");
			
		}
    }

	
	

	public get logoImage(): string {
		return "https://www.brunobarbieri.blog/wp-content/uploads/2017/12/10112534/firmabarbieri-nero.png";
	}

	public get stepOneValid(): boolean {
		return this.panettoneBonfit.valid;
	}
	
}


