<div class="form-group">
    <label [for]="id" [ngClass]="{ 'text-danger': !valid}">{{label | translate}}</label>
    <input [id]="id" type="text" class="form-control" [ngClass]="{ 'is-invalid': !valid}" [value]="initialText"
        (input)="onTextChange($event.target.value)" [ngbTypeahead]="search"
        placeholder="{{correctPlaceHolder | translate}}" [disabled]="disabled" [resultFormatter]="formatter"
        [inputFormatter]="formatter" (selectItem)="onSelect($event)" />
    <img src="assets/img/spinner.gif" class="spinner" *ngIf="loading">
    <!-- <small *ngIf="searching" class="form-text text-muted">searching...</small> -->
    <small *ngIf="!valid" class="form-text text-muted danger"><em>{{errorMessage}}</em></small>
</div>
<!-- 

<mat-form-field [floatLabel]="'always'" [appearance]="appearance" class="w-100-p"
    [class.mat-form-field-invalid]="!valid">
    <input matInput [id]="id" (input)="onTextChange($event.target.value)" [value]="initialText"
        placeholder="{{correctPlaceHolder | translate}}" [disabled]="disabled" [matAutocomplete]="autocomplete" />
    <mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="displayValue"
        (optionSelected)="onSelect($event.option.value)">
        <mat-option *ngFor="let item of items | async" [value]="item">
            {{item[itemLabel]}}
        </mat-option>
    </mat-autocomplete>
    <div matSuffix style="display:flex;align-items:center;">
        <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="20"></mat-progress-spinner>
        <button mat-icon-button [matMenuTriggerFor]="menuItems" *ngIf="actionItems.length>0">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
</mat-form-field>

<mat-error *ngIf="!valid">
    {{errorMessage}}
</mat-error>

<mat-menu #menuItems="matMenu">
    <button mat-menu-item *ngFor="let actionItem of visibleActionItems">
        <span (click)="actionItem.callback()">{{actionItem.label | translate}}</span>
    </button>
</mat-menu> -->