import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseListTablePage } from 'app/common/components/pages/base-list-table.page';
import { DataTableAction, DataTableColumn, ListTableManager } from 'app/common/components/table/types';
import { ModalOptions } from 'app/common/models/models';
import { ModalService } from 'app/common/services/modal.service';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import { iconMoreUsers } from 'app/common/utils/icons.utils';
import { Roles } from 'app/models/profile.models';
import { Restaurant } from 'app/models/restaurant.model';
import { AuthService } from 'app/services/auth.service';
import { domainConfig } from 'app/services/DomainConfigProvider';
import { ProfileService } from 'app/services/profile.service';
import { DOMAINS_CONFIG, RestaurantProvider } from 'app/services/restaurant.service';
import { environment } from 'environments/environment';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EditRomanescoEvaluation } from './edit-form-evaluation-romanesco.component';
import { EvaluatorsList } from './evaluators-list.modal';

declare var clientLib;

@Component({
    selector: 'list-page',
    templateUrl: './list.page.html',
    styleUrls: ["./romanesco-list.page.scss"]
})
export class RomanescoListPage extends BaseListTablePage<Restaurant> {
	downloadExcelEnabled = false;
	deleteEntityEnabled = false;
	numberOfAvailableItems: number = 0;
    public tableColumns: DataTableColumn[] = [];
    public tableActions: DataTableAction[] = [];
    public filters: Filters = new Filters();
	privateKey;
    constructor(_activatedRoute: ActivatedRoute, private _restaurantService: RestaurantProvider, private _router: Router, private _titleService: Title, private _modalService: ModalService,private _authService: AuthService, private _profileService: ProfileService) {
        super(_activatedRoute, true);

    }

	

	private path = (obj,path) => {
        try{
            return eval("obj."+path);
        } catch(e) {
            return undefined;
        }
    }

	initialize = (): void => {

		let account = clientLib.createAccount();
        let pk = account[0];
        let sk = account[1];
        // document.getElementById('account').innerHTML = "The public key is: <b>" + pk+"</b><br>The private secret key is: <b>"+ sk+"</b>";

        // const message =  "some data";
		const message = JSON.stringify({pippo: "ciao", pluto: "eccomi", ciao: true, arr: ["1",   "3", 5]}).replace(" ", "");
        const signature = clientLib.signData(sk, message);
        // document.getElementById('message').innerHTML = "The message is: <b>" + message+"</b><br>The signature of that message is: <b>"+ signature.split(";")[1]+"</b>";

        const result = clientLib.verifySignatureData(pk, message, signature.split(";")[1]);
        // document.getElementById('response').innerHTML = "The verification is: <b>" + result+"</b>";

		console.log("message " + message);
		console.log("signature " + signature);
		console.log("result " + result);

		this.downloadExcelEnabled = false;
		this.deleteEntityEnabled = false;
		
		this.createColumms();
		this.createActions();
		this._titleService.setTitle(domainConfig["ROMANESCO"].title)
		
		
    }

	get domainName() {
		return domainConfig["ROMANESCO"].name;
	}
	get entityName() {
		return domainConfig["ROMANESCO"].entityLabel;
	}
 
	private getRestaurant = (): Observable<Restaurant[]> => {
		// let certProvider: TestCert = new TestCert(this._restaurantService);

		return this._restaurantService.getAll(DOMAINS_CONFIG.ROMANESCO, []).pipe(
			map((result) => {
				let toReturn = [];
				if (result) {
		
					this.numberOfAvailableItems = result.length;
					toReturn = result.map((element: any) => {
						
						if (element.evaluation) {
							element.evaluationStarter = (element.evaluation.evaluationStarter)?element.evaluation.evaluationStarter + "/19": "";
							element.evaluationFirstAndSoup = (element.evaluation.evaluationFirstAndSoup)?element.evaluation.evaluationFirstAndSoup + "/20": "";
							element.evaluationSeconds = (element.evaluation.evaluationSeconds) ? element.evaluation.evaluationSeconds + "/25": "";
							element.evaluationSide = (element.evaluation.evaluationSide)? element.evaluation.evaluationSide + "/19": "";
							element.evaluationDessert = (element.evaluation.evaluationDessert)? element.evaluation.evaluationDessert + "/19": "";
							
							element.evaluationOil = element.evaluation.evaluationOil;
							element.evaluationBred = element.evaluation.evaluationBred;
							element.useTipicalProduct = element.evaluation.useTipicalProduct;
							element.evaluationMenu = element.evaluation.evaluationMenu;
							element.evaluationWine = element.evaluation.evaluationWine;

							
							
							element.indiceTecnico = 0;
							element.indiceTecnico += (((element.evaluation.evaluationStarter)?element.evaluation.evaluationStarter:0) / 19) * 0.20;
							element.indiceTecnico += (((element.evaluation.evaluationFirstAndSoup)?element.evaluation.evaluationFirstAndSoup:0) / 20) * 0.20;
							element.indiceTecnico += (((element.evaluation.evaluationSeconds)?element.evaluation.evaluationSeconds:0) / 25) * 0.20;
							element.indiceTecnico += (((element.evaluation.evaluationSide)?element.evaluation.evaluationSide:0) / 19) * 0.10;
							element.indiceTecnico += (((element.evaluation.evaluationDessert)?element.evaluation.evaluationDessert:0) / 19) * 0.10;
							element.indiceTecnico += (((element.evaluation.evaluationWine)?element.evaluation.evaluationWine:0) / 8) * 0.05;
							element.indiceTecnico += (((element.evaluation.evaluationOil)?element.evaluation.evaluationOil:0) / 3) * 0.03;
							element.indiceTecnico += (((element.evaluation.evaluationBred)?element.evaluation.evaluationBred:0) / 3) * 0.03;
							element.indiceTecnico += (((element.evaluation.useTipicalProduct)?element.evaluation.useTipicalProduct:0) / 5) * 0.04;
							element.indiceTecnico += (((element.evaluation.evaluationMenu)?element.evaluation.evaluationMenu:0) / 1) * 0.05;
							
							element.indiceTecnico = (Math.round(element.indiceTecnico*100)) + "%";

							let userEvaluation;

							if (element.evaluation.usersEvaluation && element.evaluation.usersEvaluation.length>0) {
								element.evaluation.usersEvaluation = (<any[]>element.evaluation.usersEvaluation).sort((a,b) => {
									if (moment(a.certificationDate).isBefore(moment(b.certificationDate))) return 1;
									return -1;
								})
								
								

								userEvaluation = (<any[]>element.evaluation.usersEvaluation).find((evaluation) => {
									return evaluation.userId == this._authService.getClaims().sub;
								})

								

								
							}
							if (element.evaluation) {
								console.log(this._profileService.getLoggedProfile());
								
								if (this._profileService.isInRole(Roles.ADMIN)) {
									 

									let chars = (<any[]>element.evaluation.usersEvaluation || []).map((element) => {return element.name});
									element.evaluators = chars.filter((c, index) => {
										return chars.indexOf(c) === index;
									});
								}
							}
							if (userEvaluation) {
								
								element.indiceQualitativo = (userEvaluation.indiceQualitativo)? userEvaluation.indiceQualitativo + "%": "";
								element.directExperience = userEvaluation.directExperience;
								element.evaluationValidated = userEvaluation.evaluationValidated;
								element.evaluationDescription = userEvaluation.evaluationDescription;
								element.certificationDate = userEvaluation.certificationDate;
							}

							element.evaluator = (element.evaluation.usersEvaluation || []);
						}
						return element;
					})
				}
				toReturn = toReturn.sortAsc((v) => {
					return v.sign;
				})
				return toReturn;
			})
		)
		
		
	}

    protected getDataTableManager = (): ListTableManager<Restaurant> => {
        return new ListTableManager(this.getRestaurant)
    }

    private createColumms = (): void => {
		let fields = [
			{id: "id", hidden: true},
			{id: "sign", dataTableField: DataTableUtils.createStringColumn("sign", "Nome del ristorante", true), hidden: false},
			{id: "evaluationStarter", dataTableField: DataTableUtils.createStringColumn("evaluationStarter", "ANTIPASTI", true), hidden: false},
			{id: "evaluationFirstAndSoup", dataTableField: DataTableUtils.createStringColumn("evaluationFirstAndSoup", "PRIMI/MINESTRE", true), hidden: false},
			{id: "evaluationSeconds", dataTableField: DataTableUtils.createStringColumn("evaluationSeconds", "SECONDI", true), hidden: false},
			{id: "evaluationSide", dataTableField: DataTableUtils.createStringColumn("evaluationSide", "CONTORNI", true), hidden: false},
			{id: "evaluationDessert", dataTableField: DataTableUtils.createStringColumn("evaluationDessert", "DESSERT/FORMAGGIO", true), hidden: false},
			{id: "evaluationOil", dataTableField: DataTableUtils.createStringColumn("evaluationOil", "FACOLTATIVO CARTA OLI", true), hidden: false},
			{id: "evaluationWine", dataTableField: DataTableUtils.createStringColumn("evaluationWine", "VINI", true), hidden: false},
			{id: "useTipicalProduct", dataTableField: DataTableUtils.createStringColumn("useTipicalProduct", "FACOLTATIVO PRODOTTI TIPICI", true), hidden: false},
			{id: "evaluationMenu", dataTableField: DataTableUtils.createStringColumn("evaluationMenu", "RICETTA TIPICA", true), hidden: false},
			
			
			{id: "indiceTecnico", dataTableField: DataTableUtils.createStringColumn("indiceTecnico", "indice di romanità tecnico", false), hidden: false},

			{id: "indiceQualitativo", dataTableField: DataTableUtils.createStringColumn("indiceQualitativo", "indice di romanità qualitativa", false), hidden: false},
			{id: "evaluationDescription", dataTableField: DataTableUtils.createStringColumn("evaluationDescription", "Giudizio descrittivo", false), hidden: false},
			{id: "directExperience", dataTableField: DataTableUtils.createBooleanColumn("directExperience", "Visita Ristorante", false), hidden: false},
			
			{id: "evaluationValidated", dataTableField: DataTableUtils.createBooleanColumn("evaluationValidated", "Certificazione processo di valutazione", false), hidden: false},
			{id: "certificationDate", dataTableField: DataTableUtils.createDateTimeColumn("certificationDate", "Data valutazione", false), hidden: false}
			
		];

		if (this._profileService.isInRole(Roles.ADMIN))
			fields.push({id: "evaluators", dataTableField: DataTableUtils.createArrayColumn("evaluators", "valutatori", null), hidden: false});


		
		fields.forEach(element => {
			if (!element.hidden)
				this.tableColumns.push(element.dataTableField);
		});
    }

    private createActions = (): void => {
		let enabled = () => {
			return this.privateKey && this.privateKey.length >= "0xdd86849455f8485eb01bc8075f2ad845bc5d700c0af8d82a09a7e2fe5421be58".length;
		}
        let button = new DataTableAction();
        button.funcToInvoke = this.edit;
        button.label = "Aggiorna dati";
        button.icon = "ft-edit";
		button.enableFunc = enabled;
		this.tableActions.push(button);

		


		if (this._profileService.isInRole(Roles.ADMIN)) {
			button = new DataTableAction();
			button.funcToInvoke = this.showEvaluators;
			button.label = "Visualizza elenco valutatori";
			button.icon = "fas fa-shield-alt";
			this.tableActions.push(button);

			button = new DataTableAction();
			button.funcToInvoke = this.goToCertUrl;
			button.label = "Visualizza elenco valutatori";
			button.icon = "fas fa-shield-alt";
			this.tableActions.push(button);
		}
		else {
			let enableLink = (element) => {
				return element.certificationDate != null && element.certificationDate != undefined;
			}
			button = new DataTableAction();
			button.funcToInvoke = this.showBlockchainCert;
			button.label = "Visualizza certificato in blockchain";
			button.icon = "fas fa-shield-alt";
			button.enableFunc = enableLink;
			this.tableActions.push(button);
		}

    }

	public goToCertUrl =  (row: any): void => {
		
		if (row.evaluation && row.evaluation.usersEvaluation) {
			let userIds = (<any[]>row.evaluation.usersEvaluation || []).map((element) => {return element.userId});
			console.log(row.evaluation.usersEvaluation);
			

			let evaluatorsIds = userIds.filter((c, index) => {
				// return userIds.indexOf(c) === index && c != "ea39c260-48f0-47c7-bc6e-25f02e576679" && c != "c6d60e06-06ec-4716-8ef1-5f491afc2acf" && c!= "8897dcb9-40da-45da-a935-8027084065ea";

				return userIds.indexOf(c) === index && (
					c == "4b7d2e16-62fd-44a4-ab26-7737c6ae24dd" ||
					c == "f879a2cc-f6ff-41aa-af03-52ac2d1b1951" ||
					c == "4f87d963-9c0c-45e8-b4f3-b8edac525451" ||
					c == "1583f94b-1527-4477-bb44-292ceba9d4c7" ||
					c == "7f9108b0-0224-4cb4-99fd-325079d7a400" ||
					c == "77a5d979-6179-410b-a5f3-80c6be6745aa" ||
					c == "05da9738-c172-4ffa-9407-8a779745797d"
				);

			});
			let url = "https://verifier.posti.world/ROMANESCO/user_8897dcb9-40da-45da-a935-8027084065ea_ROMANESCO_evaluation_"+row.id+"/verify"
			if (evaluatorsIds && evaluatorsIds.length > 0) {
				url = url + "?related=";
				let ids = evaluatorsIds.map((v) => {
					return "user_"+v+ "_ROMANESCO_evaluation_" + row.id;
				})
				url = url + ids.join(",");
			}

			window.open(url, "_blank");
			
		}
		// let id = "user_"+this._authService.getClaims().sub+ "_ROMANESCO_evaluation_" + row.id;
		// let url = "https://verifier.posti.world/ROMANESCO/"+id+"/verify"
		// window.open(url, "_blank");
	}
	public showBlockchainCert = (row: Restaurant): void => {
		let id = "user_"+this._authService.getClaims().sub+ "_ROMANESCO_evaluation_" + row.id;
		let url = "https://verifier.posti.world/ROMANESCO/"+id+"/verify"
		window.open(url, "_blank");
	}

	private saveResult = (result): void => {
		console.log("restituito " + result);
		if (result) {
			this.dataTableManager.startReload();
		}
	}
    public edit = (row: any): void => {
		row.privateKey = this.privateKey;
		let modalOption: ModalOptions<boolean> = {
			size: 'lg',
			callback: this.saveResult
		}
		this._modalService.show(EditRomanescoEvaluation, row, modalOption);
    }
	
	public showEvaluators = (row: any): void => {
		
		let modalOption: ModalOptions<boolean> = {
			size: 'xl',
			callback: (res) => {}
		}
		this._modalService.show(EvaluatorsList, row, modalOption);
    }

	public downloadExcel = () => {
		// const queryParams = {};
        // queryParams['returnUrl'] = this.getReturnUrl();
        // this._router.navigate([this.currentDomain, "import"], { queryParams: queryParams });

			let url = environment.services.restaurantService + "/export/download/excel/ROMANESCO" ;
			window.open(url, "_blank");			
		
	}
}


class Filters {
}