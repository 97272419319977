import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { FormFieldDescription } from 'app/common/models/forms';
import { ActionCommand, SelectListitem } from 'app/common/models/models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { iconEdit, iconPropertiesEdit, iconTrash } from "app/common/utils/icons.utils";
import { DOMAINS_CONFIG, RestaurantProvider } from 'app/services/restaurant.service';

import { ActivatedRoute, Router } from '@angular/router';
import { SubmissionResult } from 'app/pages/forms/result-form-page/result-form-submission.page';
import { catchError } from 'rxjs/operators';
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { FORM_SUBMISSION_RESULT } from '../routing-constants';
import { Restaurant } from 'app/models/restaurant.model';
import { CertificationBaseFormPage } from '../CertificationBaseFormPage';
import { environment } from 'environments/environment';
import { domainConfig } from 'app/services/DomainConfigProvider';




@Component({
    selector: 'pecora-nera-form-page',
    templateUrl: './pecora-nera-form.page.html',
	styleUrls: ['pecora-nera.page.scss']
})

export class PecoraNera2FormPage extends CertificationBaseFormPage implements OnInit {
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif', 'application/pdf'];

	editorOptions;
	data = {test: "Hello"}
	getData($event) {
		console.log("$event", $event);
	
	}
	private patchingInAction = false;
	// public evaluationForm: FormGroup = null;
    // public form: FormGroup = null;
	public contactInfo: FormGroup = null;
	public restaurantInfo: FormGroup = null;
	public usefullInfo: FormGroup = null;
	public stringValue = null;
    public guides: SelectListitem[] = [];
    public selectItemsTestedFor: SelectListitem[] = [];
    public initialItemTestedFor: SelectListitem = null;
	public selectItemsProposedEvaluation: SelectListitem[] = [];
    public initialItemProposedEvaluation: SelectListitem = null;
	public selectItemsAcc: SelectListitem[] = [];
    public initialItemAcc: SelectListitem = null;
	public selectItemsSocial: SelectListitem[] = [];
    public initialItemSocial: SelectListitem = null;
	public selectItemsProd: SelectListitem[] = [];
    public initialItemProd: SelectListitem = null
	public selectItemsPayment: SelectListitem[] = [];
    public initialItemPayment: SelectListitem = null;
	public selectItemsMenuType: SelectListitem[] = [];
    public initialItemMenuType: SelectListitem = null;
	public selectItemsAppetizers: SelectListitem[] = [];
    public initialItemAppetizers: SelectListitem = null;
	public selectItemsPastaAndSoups: SelectListitem[] = [];
    public initialItemPastaAndSoups: SelectListitem = null;
	public selectItemsMainCourses: SelectListitem[] = [];
    public initialItemMainCourses: SelectListitem = null;
	public selectItemsSideDishes: SelectListitem[] = [];
    public initialItemSideDishes: SelectListitem = null;
	public selectItemsDessertsAndCheeses: SelectListitem[] = [];
    public initialItemDessertsAndCheeses: SelectListitem = null;
	public selectItemsProducts: SelectListitem[] = [];
    public initialItemProducts: SelectListitem = null;
	public exampleCenter = [14.781447642043426, 41.130769884646625];  //coordinate del museo del sannio [long, lat]
	public _polygon: any = []
	public traditional:boolean = false;
	public isBtnDisabled: boolean= true;

	remove = () => {
		console.log("remove");
	}
	updateContent = () => {
		console.log("updateContent");
		
	}
	editProperties = () => {
		console.log("editProperties");
		
	}
	ngOnInit() {
		this.certificationConfig = environment.certificationConfig.PECORA_NERA;
		this.currentDomainConfig = domainConfig.PECORA_NERA;

		this._activatedRoute.params.subscribe(params => {
			let id = params['id'];
			if (id != undefined || id != null) {
				this._restaurantProvider.getRestaurant(DOMAINS_CONFIG.PECORA_NERA,id).subscribe((result) => {
					this.patchingInAction = true;
					this.initForm(result);
					this.restaurantInfo.patchValue(result)
					// this.evaluationForm.patchValue(result.evaluations[0]);
					this.patchingInAction = false;
				})
			}
			else {
				this.initForm();
			}
		});
	}

	addEvaluation() {
		(<FormArray>this.restaurantInfo.get("evaluations")).push(this.createEvaluationFormGroup());
	}
	removeEvaluation(index) {
		(<FormArray>this.restaurantInfo.get("evaluations")).removeAt(index);
	}
	initForm(restaurantData?: Restaurant) {
		this.guides = [
			{id: "Guida Roma e Lazio", label: "Guida Roma e Lazio"},
			{id: "Guida Milano", label: "Guida Milano"},
			{id: "Guida Torino", label: "Guida Torino"},
			{id: "Altre zone", label: "Altre zone"}
		]

		this.usefullInfo = this._fb.group({
			openingTime: [null],
			reservation: [null],
			weeklyClosing: [null],
			annualClosing: [null],
			creditCards: [null],
			accessibility: [null],
			parking: [null],
			petsAllowed: [null],
			wifi: [null],
			internalSeats: [null],
			externalSeats: [null],
			kitchenType: [null],
			specialDietsDishes: [null],
			specialProductsUsage: [null]
		});

		let evaluations = [];
		if (restaurantData && restaurantData.evaluations && restaurantData.evaluations.length>0) {
			restaurantData.evaluations.forEach(() => {
				evaluations.push(this.createEvaluationFormGroup());
			})
		}

		this.restaurantInfo = this._fb.group({
			// logo: [null],
			id: [null],
			guide:[null, Validators.required], 
			presentInTheOldEdition: [false],
			businessName: [null],
			sign: [null, Validators.required],
       		vatNumber: [null],
			// representative: [null],
			// sdiOrPec: [null],
			address: [null, Validators.required],
			zipCode: [null, Validators.required],
			city: [null, Validators.required],
			province: [null, Validators.required],
			phone: [null],
			// fipePartner: [null],
			// fipeCardNumber: [null],
	
			mobilePhone: [null],
			email: [null],
			website: [null],
			social: [null],
			socialFacebook: [null],
			socialInstagram:[null],

			lat: [null],
			lon: [null],
			usefulInfo: this.usefullInfo,
			evaluations: this._fb.array(evaluations)
		});
	}

	public createEvaluationFormGroup() {
		let evaluationForm = this._fb.group({
			firstName: [null, Validators.required],
			lastName: [null, Validators.required],
			visitDate: [null, Validators.required],
			removedFromBlockChain: [false],
			receiptPhoto: [null],
			firstDish: [null],
			secondDish: [null],
			thirdDish: [null],
			fourthDish: [null],
			fifthDish: [null],
			sixtDish: [null],
			sevenDish: [null],
			eighthDish: [null],
			ninthDish: [null],

			firstOil: [null],
			secondOil: [null],
			thirdOil: [null],
			// proposedEvaluation: [null],
			testedFor: [null, Validators.required]
		});

		let testedFor = [
			{id: "Ristorante gourmet", name: "Ristorante gourmet"},
			{id: "Neo bistrot/creativo", name: "Neo bistrot/creativo"},
			{id: "Ristorante tradizionale", name: "Ristorante tradizionale"},
			{id: "Enoteca con cucina", name: "Enoteca con cucina"},
			{id: "Osteria/trattoria", name: "Osteria/trattoria"},
			{id: "Etnico", name: "Etnico"},

			{id: "Pizzeria", name: "Pizzeria"},
			{id: "Pausa golosa - colazione", name: "Pausa golosa - colazione"},
			{id: "Pausa golosa - pranzo veloce", name: "pausa golosa - pranzo veloce"},
			{id: "Pausa golosa – merenda", name: "pausa golosa – merenda"},
			{id: "Pausa golosa – aperitivo", name: "pausa golosa – aperitivo"},
			{id: "Pausa golosa – cena veloce", name: "pausa golosa – cena veloce"},
			{id: "Pausa golosa – fino a tardi", name: "pausa golosa – fino a tardi"},
			{id: "Pausa golosa – a tutte le ore", name: "pausa golosa – a tutte le ore"},

			{id: "Spesa – enoteca", name: "spesa – enoteca"},
			{id: "Spesa – birroteca", name: "spesa – birroteca"},
			{id: "Spesa – oleoteca", name: "spesa – oleoteca"},
			{id: "Spesa – gastronomia", name: "spesa – gastronomia"},
			{id: "Spesa – panificio", name: "spesa – panificio"},
			{id: "Spesa – pastificio", name: "spesa – pastificio"},
			{id: "Spesa – macelleria", name: "spesa – macelleria"},
			{id: "Spesa – pescheria", name: "spesa – pescheria"},
			{id: "Spesa – formaggeria", name: "spesa – formaggeria"},
			{id: "Spesa – pasticceria", name: "spesa – pasticceria"},
			{id: "Spesa – cioccolateria", name: "spesa – cioccolateria"},
			{id: "Spesa – teeria", name: "spesa – teeria"},
			{id: "Spesa – torrefazione", name: "spesa – torrefazione"},
			{id: "Spesa – utensileria", name: "spesa – utensileria"},
			{id: "Spesa – bio&equo", name: "spesa – bio&equo"},
			{id: "Delivery", name: "delivery"},
		];

		
		this.selectItemsTestedFor = testedFor.map(m => new SelectListitem(m.id, m.name));
		this.initialItemTestedFor = this.selectItemsTestedFor[0];

		
		// MODIFICHE - richiesta di eliminare il campo voto proposto...
		// let proposedEvaluation025 = [
		// 	{id: "0", name: "0"},
		// 	{id: "0,25", name: "0,25"},{id: "0,50", name: "0,50"},{id: "0,75", name: "0,75"},{id: "1", name: "1"},
		// 	{id: "1,25", name: "1,25"},{id: "1,50", name: "1,50"},{id: "1,75", name: "1,75"},{id: "2", name: "2"},
		// 	{id: "2,25", name: "2,25"},{id: "2,50", name: "2,50"},{id: "2,75", name: "2,75"},{id: "3", name: "3"},
		// 	{id: "3,25", name: "3,25"},{id: "3,50", name: "3,50"},{id: "3,75", name: "3,75"},{id: "4", name: "4"},
		// 	{id: "4,25", name: "4,25"},{id: "4,50", name: "4,50"},{id: "4,75", name: "4,75"},{id: "5", name: "5"},
		// 	{id: "5,25", name: "5,25"},{id: "5,50", name: "5,50"},{id: "5,75", name: "5,75"},{id: "6", name: "6"},
		// 	{id: "6,25", name: "6,25"},{id: "6,50", name: "6,50"},{id: "6,75", name: "6,75"},{id: "7", name: "7"},
		// 	{id: "7,25", name: "7,25"},{id: "7,50", name: "7,50"},{id: "7,75", name: "7,75"},{id: "8", name: "8"},
		// 	{id: "8,25", name: "8,25"},{id: "8,50", name: "8,50"},{id: "8,75", name: "8,75"},{id: "9", name: "9"},
		// 	{id: "9,25", name: "9,25"},{id: "9,50", name: "9,50"},{id: "9,75", name: "9,75"},{id: "10", name: "10"},
		// ];
		// let proposedEvaluation05 = [
		// 	{id: "0,50", name: "0,50"},{id: "1", name: "1"},
		// 	{id: "1,50", name: "1,50"},{id: "2", name: "2"},
		// 	{id: "2,50", name: "2,50"},{id: "3", name: "3"},
		// 	{id: "3,50", name: "3,50"},{id: "4", name: "4"},
		// 	{id: "4,50", name: "4,50"},{id: "5", name: "5"},
		// 	{id: "5,50", name: "5,50"},{id: "6", name: "6"},
		// 	{id: "6,50", name: "6,50"},{id: "7", name: "7"},
		// 	{id: "7,50", name: "7,50"},{id: "8", name: "8"},
		// 	{id: "8,50", name: "8,50"},{id: "9", name: "9"},
		// 	{id: "9,50", name: "9,50"},{id: "10", name: "10"}
		// ];
		
		
		// this.selectItemsProposedEvaluation = [];

		// evaluationForm.get("testedFor").valueChanges.subscribe((changes) => {
		// 	if (changes) {
		// 		if (changes == "Ristorante gourmet" || changes == "Neo bistrot/creativo" || changes == "Ristorante tradizionale" || changes == "Enoteca con cucina" || changes == "Osteria/trattoria" || changes == "Etnico") {
		// 			this.selectItemsProposedEvaluation = proposedEvaluation025.map(m => new SelectListitem(m.id, m.name));
		// 		}
		// 		else if (changes == "Pizzeria" || changes == "Delivery" || (""+changes).toLowerCase().startsWith("pausa")) {
		// 			this.selectItemsProposedEvaluation = proposedEvaluation05.map(m => new SelectListitem(m.id, m.name));	
		// 		}
		// 		else {
		// 			this.selectItemsProposedEvaluation = [];
		// 		}
		// 		if (!this.patchingInAction)
		// 			evaluationForm.get("proposedEvaluation").patchValue(0);
		// 	}
		// 	else {
		// 		this.selectItemsProposedEvaluation = [];
		// 	}
		// })

		return evaluationForm;
	}

    constructor(private _fb: FormBuilder, private _activatedRoute: ActivatedRoute, _restaurantProvider: RestaurantProvider, private _router: Router) {
		super(_restaurantProvider);
		

		let accessibility = [
			{id: "servizi per disabili (rampe e servizi igienici)", name: "servizi per disabili (rampe e servizi igienici)"},
			{id: "assenza di barriere architettoniche", name: "assenza di barriere architettoniche"},
		];

		this.selectItemsAcc = accessibility.map(m => new SelectListitem(m.id, m.name));
		this.initialItemAcc = this.selectItemsAcc[0];


		let social = [
			{id: "Facebook", name: "Facebook"},
			{id: "Instagram", name: "Instagram"},
			{id: "Twitter", name: "Twitter"},
			{id: "LinkedIn", name: "LinkedIn"},
			{id: "TikTok", name: "TikTok"},
		];

		this.selectItemsSocial = social.map(m => new SelectListitem(m.id, m.name));
		this.initialItemSocial = this.selectItemsSocial[0];

		let payment = [
			{id: "Pagamento alla consegna", name: "Pagamento alla consegna"},
			{id: "Ritira e paga presso il locale", name: "Ritira e paga presso il locale"},
			{id: "PayPal", name: "PayPal"},
			{id: "Stripe", name: "Stripe"},
	
		];

		this.selectItemsPayment = payment.map(m => new SelectListitem(m.id, m.name));
		this.initialItemPayment = this.selectItemsPayment[0];


		

		let productsType = [
			{id: "Vegetariana", name: "Vegetariana"},
			{id: "Vegana", name: "Vegana"},
			{id: "Senza Glutine", name: "Senza Glutine"},
			{id: "Senza Lattosio", name: "Senza Lattosio"},
	
		];

		this.selectItemsProd = productsType.map(m => new SelectListitem(m.id, m.name));
		this.initialItemProd = this.selectItemsProd[0];


		

    }




    public save() {
		let toSave = this.restaurantInfo.value;
        if (this.stepTwoValid) {
            this._restaurantProvider.saveOrUpdateRestaurant(DOMAINS_CONFIG.PECORA_NERA, toSave)
			.pipe(
				catchError(error => {
					return of(null);
				})
			)
			.subscribe(
				(result) => {
					this._activatedRoute.queryParams.subscribe(qParam => {
						
						if (qParam.returnUrl) {
							this._router.navigateByUrl(qParam.returnUrl);
						}
						else {
							if (result) {
								console.log("Salvataggio completato correttamente");
								this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_OK]);
							}
							else {
								console.log("Salvataggio completato correttamente");
								this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
							}
						}
					})
					
				},
				(error) => {
					console.log("Salvataggio completato correttamente");
					this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
				}
			)
        }
		else {
			console.log("form not valid");
			
		}
    }

	

	public get logoImage(): string {
		return "assets/img/logo-pecora-nera.png";
	}

	public get stepOneValid(): boolean {
		// return this.restaurantInfo.valid && this.usefullInfo.valid;
		return true;
	}
	public get stepTwoValid(): boolean {
		if (this.restaurantInfo)
			return this.restaurantInfo.valid;
		// return false;
		// return this.stepOneValid && this.evaluationForm.valid;
	}
	
}


