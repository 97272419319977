<base-page>
    <ng-container page-body>
        <div class="card form-card">
            <form [formGroup]="entityForm" *ngIf="entityForm">
                <aw-wizard>
                    <aw-wizard-step stepTitle="Dati Generali">
                        <div class="card-header logo-form" style="background-color: white;">
                            <img class="" [src]="logoImage">
                        </div>
                        <div class="card-content form-content">
                            <div class="card-body">
                                <div class="row formContent">
                                    <div class="col-12">
                                        <div class="subtitle-form">
                                            <h3>Informazioni Generali</h3>
                                        </div>
                                        
										<div class="row">
											<div class="col-12 col-lg-6 col-md-6 col-sm-12">
												<input-string formControlName="companyName" label="Azienda"></input-string>
											</div>
											<div class="col-12 col-lg-6 col-md-6 col-sm-12">
												<input-string formControlName="region" label="regione dell'allevamento"></input-string>
											</div>
										</div>
										<div class="row ">
											<div class="col-12 col-lg-6 col-md-6 col-sm-12">
												<input-string formControlName="companyCoordinates" label="coordinate azienda dove sorge l'allevamento"></input-string>
											</div>
											<div class="col-12 col-lg-6 col-md-6 col-sm-12">
												<input-string formControlName="province" label="provincia di provenienza allevamento" placeHolder="Inserisci la provincia di provenienza allevamento"></input-string>
											</div>
										</div>
										<div class="row ">
											<div class="col-12 col-lg-6 col-md-6 col-sm-12">
												<input-string formControlName="companyCoordinates" label="coordinate azienda dove sorge l'allevamento"></input-string>
											</div>
											<div class="col-12 col-lg-6 col-md-6 col-sm-12">
												<input-string formControlName="product" label="prodotto"></input-string>
											</div>
										</div>
										<div class="row ">
											<div class="col-12">
												<input-string formControlName="giftDescription" label="Descrizione del regalo associato"></input-string>
											</div>
										</div>
										


                                        <div class="subtitle-form">
                                            <h3>Informazioni generiche dipendenti dal lotto</h3>
                                        </div>
                                        <form [formGroup]="lotInfo">
                                           
											<div class="row">
												<div class="col-4">
													<input-string formControlName="lotNumber" label="Lotto di produzione">
													</input-string>
												</div>
												<div class="col-4">
													<input-date utc="false" formControlName="slaughterDate" label="data di macellazione"></input-date>
												</div>
												<div class="col-4">
													<input-date utc="false" formControlName="expiryDate" label="data di scadenza"></input-date>
												</div>
											</div>
                                            <div class="row">
                                                <div class="col-6 ">
                                                    <input-string formControlName="slaughterSite" label="sito di macellazione"></input-string>
                                                </div>
												<div class="col-6 ">
                                                    <input-string formControlName="laboratory" label="laboratorio di sezionamento"></input-string>
                                                </div>
                                            </div>
                                        </form>
										<div class="subtitle-form">
                                            <h3>Caratteristiche del prodotto</h3>
                                        </div>
                                        <form [formGroup]="productInfo">
                                            <div class="row">
                                                <div class="col-12 ">
                                                    <input-string formControlName="origin" label="origine prodotto"></input-string>
                                                </div>
                                            </div>
											<div class="row">
                                                <div class="col-12 ">
                                                    <input-string formControlName="farm" label="allevamento"></input-string>
                                                </div>
                                            </div>
											<div class="row">
                                                <div class="col-12 ">
                                                    <input-string formControlName="diet" label="alimentazione specifica"></input-string>
                                                </div>
                                            </div>
											<div class="row">
                                                <div class="col-12 ">
                                                    <input-string formControlName="dietSpec" label="caratteristiche alimentazione Marango Fioc"></input-string>
                                                </div>
                                            </div>
                                        </form>
										<div class="subtitle-form">
                                            <h3>chiavi di sicurezza</h3>
                                        </div>
                                        <form [formGroup]="walletForm">
                                            <div class="row">
                                                <div class="col-12 ">
                                                    <input-string formControlName="secret" label="Chiave privata del tuo wallet"></input-string>
                                                </div>
                                            </div>
											
                                        </form>
                                        <button type="button" class="wizardBtn" awPreviousStep>INDIETRO</button>
                                        <button id="btnNext1" type="button" class="wizardBtn" [disabled]="!canSaveForm" (click)="save()" >SALVA</button>
                                        <br>
                                        <div *ngIf="!canSaveForm" class="subtitle-form">
                                            <p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aw-wizard-step>

                    
                </aw-wizard>
            </form>
        </div>
    </ng-container>
</base-page>