<base-page>
    <ng-container page-body>
        <div class="card form-card" *ngIf="currentEntityDef">
			<div class="subtitle-form">
				<h1>{{currentEntityDef.entityName}}</h1>
			</div>
			<aw-wizard *ngIf="entityForm && currentEntityDef">
				<aw-wizard-step *ngFor="let group of currentEntityDef.groups" [stepTitle]="group.groupLabel">
					<div class="card-header logo-form" style="background-color: white;">
						<img class="" [src]="logoImage">
					</div>
					<div class="card-content form-content">
						<div class="card-body">
							<div class="row formContent">
								<div class="col-12">
									<form [formGroup]="entityForm.get(group.groupId)">
										<div class="subtitle-form">
											<h3>{{group.groupLabel}}</h3>
										</div>
										<div class="row" *ngFor="let field of group.fields" >
											<div class="col-12">
												<input-string *ngIf="field.fieldType == 'STRING'" [formControlName]="field.fieldId" [label]="field.fieldLabel"></input-string>
												<input-int *ngIf="field.fieldType == 'INTEGER'" [formControlName]="field.fieldId" [label]="field.fieldLabel"></input-int>
											</div>
										</div>
									</form>
									
									<!-- <form [formGroup]="walletForm">
										<div class="subtitle-form">
											<h3>chiavi di sicurezza</h3>
										</div>
										
										<div class="row">
											<div class="col-12 ">
												<input-string formControlName="secret" label="Chiave privata del tuo wallet"></input-string>
											</div>
										</div>
										
									</form> -->
									<button type="button" class="wizardBtn" awPreviousStep>INDIETRO</button>
									<button id="btnNext1" type="button" class="wizardBtn" [disabled]="!entityForm.get(group.groupId).valid" awNextStep>AVANTI</button>
									<!-- <button id="btnNext1" type="button" class="wizardBtn" [disabled]="!canSaveForm" (click)="save()" >SALVA</button> -->
									<br>
									<div *ngIf="!entityForm.get(group.groupId).valid" class="subtitle-form">
										<p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</aw-wizard-step>
				<aw-wizard-step [stepTitle]="'Riepilogo'">
					<div class="card-header logo-form" style="background-color: white;">
						<img class="" [src]="logoImage">
					</div>
					<div class="card-content form-content">
						<div class="card-body">
							<div class="row formContent">
								<div class="col-12">
									<div *ngFor="let group of currentEntityDef.groups" >
										<div class="subtitle-form">
											<h3>{{group.groupLabel}}</h3>
										</div>
										<div class="row"  *ngFor="let field of group.fields">
											<div class="col-12 text-white">
												<strong>{{field.fieldLabel}}:</strong> {{entityForm.get(group.groupId).get(field.fieldId).value}}
											</div>
										</div>
									</div>
									
									
									
									<form [formGroup]="walletForm">
										<div class="subtitle-form">
											<h3>chiavi di sicurezza</h3>
										</div>
										
										<div class="row">
											<div class="col-12 ">
												<input-string formControlName="secret" label="Chiave privata del tuo wallet"></input-string>
											</div>
										</div>
										
									</form>
									<button type="button" class="wizardBtn" awPreviousStep>INDIETRO</button>
									<button id="btnNext1" type="button" class="wizardBtn" [disabled]="!entityForm.valid" (click)="save(false)" >SALVA</button>
									<button id="btnNext1" type="button" class="wizardBtn" [disabled]="!canSaveForm" (click)="save(true)" >SALVA E CERTIFICA</button>
									<br>
									<div *ngIf="!canSaveForm" class="subtitle-form">
										<p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</aw-wizard-step>

			</aw-wizard>
        </div>
    </ng-container>
</base-page>