import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from 'app/common/components/components.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ResultSubmissionFormPage } from './result-form-page/result-form-submission.page'
import { ArchwizardModule } from 'angular-archwizard';
import { PecoraNeraFormPage } from './pecora-nera/pecora-nera-form.page';
import { GiustaFormPage } from './giusta/giusta-form.page';
import { FORM_CHOICE, FORM_SUBMISSION_RESULT, PECORA_NERA_PATH, GIUSTA_PATH, ROMANESCO_PATH, BONFIT_PATH, PAT_PATH, PECORA_NERA_2_PATH, ROMANESCO_2, BASE_HQF_PATH, BASE_CASEARIA_PATH, BASE_FORM_PATH, BASE_BIOLU_PATH, BASE_CASEARIA_PECORINO_PATH, BASE_AGRICOLA_S_MAURIZIO_PATH, BASE_RISO_BUONO_PATH, EDIT_ENTITY_PATH } from './routing-constants';
import { FormChoicePage } from './form-choice/form-choice.page';
import { RomanescoFormPage } from './romanesco/romanesco-form.page';
import { BonfigFormPage } from './bonfit-form/bonfit-form.page';
import { ListPage } from './entities-list/list.page';
import { ImportPage } from './import-page/import.page';
import { PatFormPage } from './PAT-form/pat-form.page';
import { PecoraNera2FormPage } from './pecora-nera-2/pecora-nera-2-form.page';
import { RomanescoListPage } from './entities-list-romanesco/list.page';
import { EditRomanescoEvaluation } from './entities-list-romanesco/edit-form-evaluation-romanesco.component';
import { HQFFormPage } from './qhfagricola/hqfagricola-form.page';
import { CaseariaFormPage } from './casearia/casearia-form.page';
import { EvaluatorsList } from './entities-list-romanesco/evaluators-list.modal';
import { BioluFormPage } from './biolu/biolu-form.page';
import { CaseariaPecorinoFormPage } from './casearia-pecorino/casearia-pecorino-form.page';
import { AgricolaSanMaurizioFormPage } from './agricola-san-maurizio/agricola-san-maurizio-form.page';
import { RisoBuonoFormPage } from './agricola-riso-buono/riso-buono-form.page';
import { EditEntityPage } from './edit-entity-component/edit-entity.page';

export const FormsRoutes: Routes = [
    { path: BASE_FORM_PATH, component: FormChoicePage, canActivate: []},

    { path: FORM_CHOICE, component: FormChoicePage, canActivate: []},
    { path: ':domain/entity-list', component: ListPage, canActivate: []},
    { path: ':domain/import', component: ImportPage, canActivate: []},

	{ path: ROMANESCO_PATH, component: RomanescoFormPage, canActivate: [] },
	{ path: ROMANESCO_PATH+ "/:id", component: RomanescoFormPage, canActivate: [] },
	{ path: GIUSTA_PATH, component: GiustaFormPage, canActivate: [] },
	{ path: GIUSTA_PATH + "/:id", component: GiustaFormPage, canActivate: [] },

	{ path: BONFIT_PATH, component: BonfigFormPage, canActivate: [] },
	{ path: BONFIT_PATH + "/:id", component: BonfigFormPage, canActivate: [] },

	{ path: PAT_PATH, component: PatFormPage, canActivate: [] },
	{ path: PAT_PATH + "/:id", component: PatFormPage, canActivate: [] },

    { path: PECORA_NERA_PATH, component: PecoraNeraFormPage, canActivate: [] },
    { path: PECORA_NERA_PATH + "/:id", component: PecoraNeraFormPage, canActivate: [] },
    { path: PECORA_NERA_2_PATH, component: PecoraNera2FormPage, canActivate: [] },
    { path: PECORA_NERA_2_PATH + "/:id", component: PecoraNera2FormPage, canActivate: [] },
    { path: FORM_SUBMISSION_RESULT+ "/:submissionResult", component: ResultSubmissionFormPage, canActivate: [] },
	{ path: ROMANESCO_2, component: RomanescoListPage },

	{ path: BASE_HQF_PATH, component: HQFFormPage, canActivate: [] },
	{ path: BASE_HQF_PATH+ "/:id", component: HQFFormPage, canActivate: [] },

	{ path: BASE_CASEARIA_PATH, component: CaseariaFormPage, canActivate: [] },
	{ path: BASE_CASEARIA_PATH+ "/:id", component: CaseariaFormPage, canActivate: [] },

	{ path: BASE_CASEARIA_PECORINO_PATH, component: CaseariaPecorinoFormPage, canActivate: [] },
	{ path: BASE_CASEARIA_PECORINO_PATH+ "/:id", component: CaseariaPecorinoFormPage, canActivate: [] },

	{ path: BASE_AGRICOLA_S_MAURIZIO_PATH, component: AgricolaSanMaurizioFormPage, canActivate: [] },
	{ path: BASE_AGRICOLA_S_MAURIZIO_PATH+ "/:id", component: AgricolaSanMaurizioFormPage, canActivate: [] },

	{ path: BASE_RISO_BUONO_PATH, component: RisoBuonoFormPage, canActivate: [] },
	{ path: BASE_RISO_BUONO_PATH+ "/:id", component: RisoBuonoFormPage, canActivate: [] },

	
	
	{ path: BASE_BIOLU_PATH, component: BioluFormPage, canActivate: [] },
	{ path: BASE_BIOLU_PATH+ "/:id", component: BioluFormPage, canActivate: [] },
	
	{ path: EDIT_ENTITY_PATH+ "/:domain/:entityClass", component: EditEntityPage, canActivate: [] },
	{ path: EDIT_ENTITY_PATH+ "/:domain/:entityClass/:id", component: EditEntityPage, canActivate: [] }
	
];


@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        RouterModule.forChild(FormsRoutes),
		NgJsonEditorModule,
		ArchwizardModule
    ],
    exports: [
		EditRomanescoEvaluation,
		EvaluatorsList
	],
    declarations: [
		EditRomanescoEvaluation,
		GiustaFormPage,
        PecoraNeraFormPage,
		RomanescoFormPage,
		FormChoicePage,
		ResultSubmissionFormPage,
		ListPage,
		BonfigFormPage,
		ImportPage,
		PatFormPage,
		PecoraNera2FormPage,
		RomanescoListPage,
		HQFFormPage,
		CaseariaFormPage,
		EvaluatorsList,
		BioluFormPage,
		CaseariaPecorinoFormPage,
		AgricolaSanMaurizioFormPage,
		RisoBuonoFormPage,
		EditEntityPage
    ],
    providers: [],
	entryComponents: [EditRomanescoEvaluation, EvaluatorsList]
})
export class DataFormsModule { }
