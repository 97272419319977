


<div class="full-height full-width position-relative">
	<div #mapPreview style="width: 100%; height: 100%;">
	</div>
	<div class="map-preview-action-buttons">
		<button
			*ngFor="let action of actions"
			(click)="invokeAction($event, action, reference)"
			[disabled]="!action.enableFunc(reference)"
			class="btn-clear {{ (!action.enableFunc(reference))?'element-disabled':'text-primary'}} cursor-pointer button-color-red">
			<i class="{{action.icon}} button-color-red"></i>
			<!-- <i class="{{action.icon}}" placement="top" [ngbTooltip]="action.label | translate"></i> -->
		</button>
	</div>
</div>
