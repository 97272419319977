import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AdminRoutes } from './pages/admin/admin.module';
import { FormsRoutes } from './pages/forms/forms.module';
import { FORM_CHOICE, PECORA_NERA_PATH } from './pages/forms/routing-constants';
import { UserProfileRoutes } from './pages/user-profile/user-profile.module';

const appRoutes: Routes = [
	{
        path: '',
        redirectTo: "user-profile",
        pathMatch: 'full',
    },
    // { path: '',  pathMatch: 'full', component: AppComponent, data: { title: 'full Views' } },
    {
        path: '**',
        redirectTo: 'pages/error'
    }
];

@NgModule({
    imports: [
		RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
		RouterModule.forRoot(UserProfileRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
		RouterModule.forRoot(FormsRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
		RouterModule.forRoot(AdminRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
	],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
