<modal title="{{title}}" (onClose)="close()" headerBgColor="gradient-man-of-steel">
	<div body>
		<div class="row">
			<div class="col-12">
				<form [formGroup]="acrForm" *ngIf="acrForm">
					<div class="row">
						<div class="col-12">
							<select-box formControlName="users" label="Utenti cui è garantito l'accesso a {{entityName}}" [items]="selectUsersItems" [multiple]="true">
							</select-box>
						</div>
						
					</div>
				</form>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<p>&nbsp;</p>
				<p>&nbsp;</p>
				<p>&nbsp;</p>
				<p>&nbsp;</p>
				<p>&nbsp;</p>
			</div>
		</div>
	</div>
	
    <div class="text-right" footer>
		<button type="button" (click)="close()" class="btn btn-outline-secondary"><i class="{{iconCancel}}"></i> {{'common.cancelButtonLabel' | translate}}</button>
		<button *ngIf="acrForm" type="button" (click)="save()" [disabled]="!acrForm.valid" class="btn btn-primary"><i class="{{iconSave}}"></i> {{'common.saveButtonLabel' | translate}}</button>
    </div>
</modal>