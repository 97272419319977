import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseListTablePage } from 'app/common/components/pages/base-list-table.page';
import { DataTableAction, DataTableColumn, ListTableManager } from 'app/common/components/table/types';
import { ModalService } from 'app/common/services/modal.service';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import { Restaurant } from 'app/models/restaurant.model';
import { domainConfig } from 'app/services/DomainConfigProvider';
import { DOMAINS_CONFIG, RestaurantProvider } from 'app/services/restaurant.service';
import { environment } from 'environments/environment';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CertificationBaseFormPage } from '../CertificationBaseFormPage';
import { BASE_FORM_PATH } from '../routing-constants';

import * as XLSX from 'xlsx';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'app/services/http.service';
import { HttpClient } from '@angular/common/http';

class brewings {
	data_confezionamento;
	linea_produzione;

	lotto_malto; // referenzia il lotto malto attraverso il campo lotto_malto
	sample_mosto;
	
	colore;
	do;
	durata_ciclo_cottura;
	durata_fermentazione;
	durata_maturazione;
	fornitore_malto;
	grado_primitivo;
	n_tank_fermentazione;
	orario_produzione;
	published_at;
	sample_confezionamento;
	sample_deposito;
	sample_fermentazione;
	sample_visa;
	transaction;
}

class Malto {
	lotto_malto;
	ID_granella;

	transaction;
	att_limite;
	azot_sol;
	b_gluc;
	cal_mag_2_5mm;
	cal_min_2_2mm;
	cod_silo_malto;
	codice_articolo_tipologia_malto;
	colore;
	data_inizio_carico_silo_malto;
	data_lotto_malto;
	data_preparazione_orzo;
	destinatario;
	destinazione_stabilimento;
	estratto_ss;
	fan;
	friabilita;
	gg_maltazione;
	grani_rotti;
	kolbach;
	malteria;
	mat_estr;
	p_hl;
	ph;
	proteine;
	published_at;
	pug;
	qty_kg_mato_in_uscita;
	targa_cisterna;
	umidita;
	vettore;
	viscosita;
	wug;
}

class Grain {
	id_granella;
	codice_fornitore; //referenzia il fornitore
	
	transaction;
	anno_mese_fine_raccolta;
	anno_mese_fine_semina;
	anno_mese_inizio_raccolta;
	anno_mese_inizio_semina;
	area_coltivazione_orzo;
	articolo_silo_orzo;
	cod_silo_orzo;
	data_ricezione_orzo;
	id_varieta;
	published_at;
	qty_orzo_consegnata;
	block_height;
}

class Farmer {
	codice_fornitore;

	transaction;
	anno;
	categoria;
	id_varieta;
	indirizzo_cap_citta;
	nome_fornitore;
	provincia;
	published_at;
	regione;
}

@Component({
    selector: 'import-page',
    templateUrl: './import.page.html'
})
export class ImportPage implements OnInit {
	currentDomain;
	formControl: FormGroup;

    constructor(private _activatedRoute: ActivatedRoute, private httpService: HttpClient, private _restaurantService: RestaurantProvider, private _fb: FormBuilder, private _router: Router, private _titleService: Title, private _modalService: ModalService) {
       
    }

	ngOnInit() {
		this._activatedRoute.paramMap.subscribe(queryParams => {
			if (domainConfig[queryParams.get("domain")]) {
				this.currentDomain = domainConfig[queryParams.get("domain")]
				this._titleService.setTitle(this.currentDomain.title)
				let favIcon: HTMLLinkElement = document.querySelector('#appIcon');
				favIcon.href = this.currentDomain.pageIcon;
			}
		});

		this.formControl = this._fb.group({
			fileExcel: [null, Validators.required],
			certifyData: [false]
		});
	}
	
	private path = (obj,path) => {
        try{
            return eval("obj."+path);
        } catch(e) {
            return undefined;
        }
    }

	get domainName() {
		if (this.currentDomain) {
			return this.currentDomain.name;
		}
	}
	get entityName() {
		if (this.currentDomain ) {
			return this.currentDomain.entityLabel;
		}
	}

	private file;

	onFileChange(ev) {
		this.file = ev.target.files[0];
	}

	public counter = 0;

	async loadExcel() {
// 		let workBook = null;
// 		let jsonData = null;
// 		const reader = new FileReader();
// +		
// 		console.log(this.formControl.value);
		
// 		reader.onload = (event) => {
// 			const data = reader.result;
// 			workBook = XLSX.read(data, { type: 'binary' });
// 			jsonData = workBook.SheetNames.reduce((initial, name) => {
// 				const sheet = workBook.Sheets[name];
// 				initial[name] = XLSX.utils.sheet_to_json(sheet);
// 				return initial;
// 			}, {});
// 			console.log(jsonData);
			
// 			// const dataString = JSON.stringify(jsonData);
// 			// document.getElementById('output').innerHTML = dataString;
// 			let map = {
// 				"malto": "https://dashboard.posti.world/bp-service/api/v1/bo/importer/malts",
// 				"granella": "https://dashboard.posti.world/bp-service/api/v1/bo/importer/grains",
// 				"brewing": "https://dashboard.posti.world/bp-service/api/v1/bo/importer/brewings",
// 				"fornitore": "https://dashboard.posti.world/bp-service/api/v1/bo/importer/farmers"

// 			}
			
// 			for (let key in jsonData) {
// 				if (jsonData[key].length > 0) {
// 					let dataToSend: any[] = jsonData[key].map((el) => {return el.txhash}).filter((value, index, self) => {
// 						return self.indexOf(value) === index;
// 					})
// 					// console.log(key, dataToSend);
// 					let base = 0;
// 					while (dataToSend.slice(base,base+30) && dataToSend.slice(base,base+30).length>0) {
// 						let data = {txHashes: dataToSend.slice(base,base+30)};
// 						base += 30;
// 						// console.log(map[key], data);
// 						this.httpService.post(map[key],data).subscribe((result) => {
// 							console.log(key + " caricato");
// 						})
// 					}
					
// 				}
				
// 			}
// 		}
// 		reader.readAsBinaryString(this.file);




		let arr = {
			

		};
		// for (let key in jsonData) {
		// 	if (jsonData[key].length > 0) {
		// 		let dataToSend: any[] = jsonData[key].map((el) => {return el.txhash}).filter((value, index, self) => {
		// 			return self.indexOf(value) === index;
		// 		})
		// 		// console.log(key, dataToSend);
		// 		let base = 0;
		// 		while (dataToSend.slice(base,base+30) && dataToSend.slice(base,base+30).length>0) {
		// 			let data = {txHashes: dataToSend.slice(base,base+30)};
		// 			base += 30;
		// 			// console.log(map[key], data);
		// 			this.httpService.post(map[key],data).subscribe((result) => {
		// 				console.log(key + " caricato");
		// 			})
		// 		}
				
		// 	}
			
		// }
		
		// for (let key in arr) {

		// 	await this.httpService.get("https://peroni.westeurope.cloudapp.azure.com/api/cert/"+arr[key]).toPromise().then((result) => {
		// 		// console.log(key + " caricato ", result);
		// 		let original_data = result.original_data;
		// 		try {
		// 			original_data = JSON.parse(result.original_data);
		// 		} catch (error) {
		// 			original_data = result.original_data;
		// 		}
		// 		console.log(original_data);
		// 		if (original_data) {
		// 			original_data.forEach(element => {
		// 				if (element.lotto_malto && element.linea_produzione &&  this.lots.indexOf(element.lotto_malto + element.linea_produzione) < 0){
		// 					console.log("Adding " + element.lotto_malto + element.linea_produzione);
		// 					this.lots.push(element.lotto_malto + element.linea_produzione);
		// 				}
						
						
		// 			});
		// 		}
				
		// 	})
			
		// }

		
		extracted.forEach((c) => {
			arr[c] = "";

		})
		

		var wb = XLSX.utils.book_new();

		let brewings: brewings[] = [];
		let malts: Malto[] = [];
		let grains: Grain[] = [];
		let farmers: Farmer[] = [];

		let obj = {
			brewings: [],
			malto: [] ,
			granella: [],
			fornitori: []
		};
		let countBr = 0;
		let countMalto = 0;
		let countGranella = 0;
		let countFornitori = 0;
		let certIds = {};
		for (let key in arr) {
			this.counter++;
			// ########## recupero certificati...
			// try {
			// 	await this.httpService.get("http://dashboard.posti.world:18081/api/v1/bo/productionchain?lot="+key).toPromise().then((result) => {
			// 	// console.log(key + " caricato ", result);
			// 	let r: any = result;
			// 	// try {
			// 	// 	r = JSON.parse(<any>result);
			// 	// } catch (e) {
			// 	// 	console.log(e);
					
			// 	// }
			// 	// console.log(key + " caricato ", result);


			// 	if (r && r["brewings"] && r["brewings"].length > 0) {
			// 		// obj.brewings = [...r[0]["brewings"]];
			// 		brewings = r["brewings"].map((element: brewings) => {
			// 			if (element.transaction && element.transaction.cert_id)
			// 				certIds[element.transaction.cert_id] = "";
			// 			return element
			// 		})
			// 	}

				
			// 	if (r && r["malto"] && r["malto"].length > 0) {
			// 		// obj.malto = [...r[0]["malto"]];
			// 		malts  = r["malto"].map((element: Malto) => {
			// 			if (element.transaction && element.transaction.cert_id)
			// 				certIds[element.transaction.cert_id] = "";
			// 			return element
			// 		})
					
			// 	}

				
			// 	if (r && r["granella"] && r["granella"].length > 0) {
			// 		// obj.granella = [...r["granella"]];
			// 		grains = r["granella"].map((element: Grain) => {
			// 			if (element.transaction && element.transaction.cert_id)
			// 				certIds[element.transaction.cert_id] = "";
			// 			return element
			// 		})
					
			// 	}
				
			// 	if (r && r["fornitori"] && r["fornitori"].length > 0) {
			// 		// obj.fornitori = [...r[0]["fornitori"]];
			// 		farmers = r["fornitori"].map((element: Farmer) => {
			// 			if (element.transaction && element.transaction.cert_id)
			// 				certIds[element.transaction.cert_id] = "";
			// 			return element
			// 		})
					
			// 	}
				
			// })
			// } catch (error) {
			// 	console.log(error);
					
			// }

			

			// ########## prima versione recupero dati...

			// try {
			// 	await this.httpService.get("https://dashboard.posti.world/peroni/service/dati.php?lotto="+key+"&action=approfondimento").toPromise().then((result) => {
			// 	// console.log(key + " caricato ", result);
			// 	let r: any = result;
			// 	try {
			// 		r = JSON.parse(<any>result);
			// 	} catch (e) {
			// 		console.log(e);
					
			// 	}
			// 	// console.log(key + " caricato ", result);


			// 	if (r && r[0] && r[0]["brewings"] && r[0]["brewings"].length > 0) {
			// 		// obj.brewings = [...r[0]["brewings"]];
			// 		brewings = r[0]["brewings"].map((element: brewings) => {
			// 			let newValue: any = new Object();
			// 			Object.assign(newValue,element);
			// 			newValue.lotto_malto = key + "_" + element.lotto_malto;

			// 			if (element.transaction) {
			// 				newValue.transaction = {
			// 					cert_id: (element.transaction.txhash.lastIndexOf("?") >= 0)?  element.transaction.txhash.substring(0, element.transaction.txhash.lastIndexOf("?")): element.transaction.txhash,
			// 					azienda: "birraperoni"
			// 				}
			// 				if ((element.data_confezionamento + element.linea_produzione) != key) {
			// 					console.log("Warning: " + key + " => " + element.data_confezionamento + "-" + element.linea_produzione);
								
			// 					let data = key.substring(0,key.length-2);
			// 					let linea = key.substring(key.length-2);
			// 					newValue.data_confezionamento = data;
			// 					newValue.linea_produzione = linea;

			// 				}

			// 			}
			// 			return newValue;
			// 		})

			// 		// console.log("aggiunto brewings " + key, brewings);

			// 		this.httpService.post("http://dashboard.posti.world:18081/api/v1/bo/entities/brewings", brewings).subscribe((rbrewings) => {
			// 			console.log("aggiunto brewings " + key);
						
			// 		})
			// 	}

				
			// 	if (r && r[0] && r[0]["malto"] && r[0]["malto"].length > 0) {
			// 		// obj.malto = [...r[0]["malto"]];
			// 		malts  = r[0]["malto"].map((element: Malto) => {
			// 			let newValue: any = new Object();
			// 			Object.assign(newValue,element);
			// 			newValue.lotto_malto = key + "_" + element.lotto_malto;
			// 			newValue.ID_granella = key + "_" + element.ID_granella;
			// 			if (element.transaction) {
			// 				newValue.transaction = {
			// 					cert_id: (element.transaction.txhash.lastIndexOf("?") >= 0)?  element.transaction.txhash.substring(0, element.transaction.txhash.lastIndexOf("?")): element.transaction.txhash,
			// 					azienda: "birraperoni"
			// 				}
			// 			}
			// 			return newValue;
			// 		})
			// 		// console.log("aggiunto malts " + key, malts);

			// 		// brewings.forEach((b, index) => {
			// 		// 	if (malts.findIndex((el)=> {return el.lotto_malto == b.lotto_malto}) < 0 ) {
			// 		// 		console.log("	WARNING Lotto malto not found. " + b.lotto_malto + " index "+ index);
							
			// 		// 	}
			// 		// })
			// 		// malts.forEach((malt,index) => {
			// 		// 	if (brewings.findIndex((el)=> {return el.lotto_malto == malt.lotto_malto}) < 0 ) {
			// 		// 		console.log("	WARNING brewings not found. " + malt.lotto_malto + " index "+ index);
			// 		// 	}
			// 		// })
			// 		this.httpService.post("http://dashboard.posti.world:18081/api/v1/bo/entities/malts", malts).subscribe((rmalts) => {
			// 			// console.log("aggiunto malts " + key);
			// 		})
			// 	}

				
			// 	if (r && r[0] && r[0]["granella"] && r[0]["granella"].length > 0) {
			// 		// obj.granella = [...r["granella"]];
			// 		grains = r[0]["granella"].map((element: Grain) => {
			// 			let newValue: any = new Object();
			// 			Object.assign(newValue,element);
			// 			newValue.id_granella = key + "_" + element.id_granella;
			// 			newValue.codice_fornitore = key + "_" + element.codice_fornitore;

			// 			if (element.transaction) {
			// 				newValue.transaction = {
			// 					cert_id: (element.transaction.txhash.lastIndexOf("?") >= 0)?  element.transaction.txhash.substring(0, element.transaction.txhash.lastIndexOf("?")): element.transaction.txhash,
			// 					azienda: "birraperoni"
			// 				}
			// 			}
			// 			return newValue;
			// 		})
			// 		// console.log("aggiunto grains " + key, grains);
			// 		// grains.forEach((malt,index) => {
			// 		// 	if (malts.findIndex((el)=> {return el.ID_granella == malt.id_granella}) < 0 ) {
			// 		// 		console.log("	WARNING malto da granella non trovato . " + malt.id_granella + " index "+ index);
			// 		// 	}
			// 		// })
			// 		// malts.forEach((malt,index) => {
			// 		// 	if (grains.findIndex((el)=> {return el.id_granella == malt.ID_granella}) < 0 ) {
			// 		// 		console.log("	WARNING granella da malto non trovato . " + malt.ID_granella + " index "+ index);
			// 		// 	}
			// 		// })
			// 		this.httpService.post("http://dashboard.posti.world:18081/api/v1/bo/entities/grains", grains).subscribe((rgrains) => {
			// 			// console.log("aggiunto grains " + key);
						
			// 		})
			// 	}
				
			// 	if (r && r[0] && r[0]["fornitori"] && r[0]["fornitori"].length > 0) {
			// 		// obj.fornitori = [...r[0]["fornitori"]];
			// 		farmers = r[0]["fornitori"].map((element: Farmer) => {
			// 			let newValue: any = new Object();
			// 			Object.assign(newValue,element);
			// 			newValue.codice_fornitore = key + "_" + element.codice_fornitore;
						
			// 			if (element.transaction) {
			// 				newValue.transaction = {
			// 					cert_id: (element.transaction.txhash.lastIndexOf("?") >= 0)?  element.transaction.txhash.substring(0, element.transaction.txhash.lastIndexOf("?")): element.transaction.txhash,
			// 					azienda: "birraperoni"
			// 				}
			// 			}
			// 			return newValue;
			// 		})
			// 		// console.log("aggiunto farmers " + key, farmers);
			// 		// grains.forEach((malt,index) => {
			// 		// 	if (farmers.findIndex((el)=> {return el.codice_fornitore == malt.codice_fornitore}) < 0 ) {
			// 		// 		console.log("	WARNING fornitore da granella non trovato . " + malt.codice_fornitore + " index "+ index);
			// 		// 	}
			// 		// })
			// 		// farmers.forEach((malt,index) => {
			// 		// 	if (grains.findIndex((el)=> {return el.codice_fornitore == malt.codice_fornitore}) < 0 ) {
			// 		// 		console.log("	WARNING granella da fornitore non trovato . " + malt.codice_fornitore + " index "+ index);
			// 		// 	}
			// 		// })
			// 		this.httpService.post("http://dashboard.posti.world:18081/api/v1/bo/entities/farmers", farmers).subscribe((rfarmers) => {
			// 			// console.log("aggiunto farmers " + key);
			// 		})
			// 	}
				
			// })
			// } catch (error) {
			// 	console.log(error);
					
			// }
			
			



			// ###########seconda versione dell'algoritmo

			try {
				await this.httpService.get("https://dashboard.posti.world/peroni/service/dati.php?lotto="+key+"&action=approfondimento").toPromise().then((result) => {
				let r: any = result;
				try {
					r = JSON.parse(<any>result);
				} catch (e) {
					console.log(e);
					
				}


				if (r && r[0] && r[0]["brewings"] && r[0]["brewings"].length > 0) {
					brewings = r[0]["brewings"].map((element: brewings) => {
						let newValue: any = new Object();
						Object.assign(newValue,element);
						// newValue.lotto_malto = key + "_" + element.lotto_malto;

						if (element.transaction) {
							newValue.transaction = {
								cert_id: (element.transaction.txhash.lastIndexOf("?") >= 0)?  element.transaction.txhash.substring(0, element.transaction.txhash.lastIndexOf("?")): element.transaction.txhash,
								azienda: "birraperoni"
							}
							if ((element.data_confezionamento + element.linea_produzione) != key) {
								console.log("Warning: " + key + " => " + element.data_confezionamento + "-" + element.linea_produzione);
								
								let data = key.substring(0,key.length-2);
								let linea = key.substring(key.length-2);
								newValue.data_confezionamento = data;
								newValue.linea_produzione = linea;

							}

						}
						obj.brewings.push(newValue);
						return newValue;
					})
					countBr+=brewings.length;
					// console.log("aggiunto brewings " + key, brewings);

					// this.httpService.post("http://dashboard.posti.world:18081/api/v1/bo/entities/brewings", brewings).subscribe((rbrewings) => {
					// 	console.log("aggiunto brewings " + key);
						
					// })
				}

				
				if (r && r[0] && r[0]["malto"] && r[0]["malto"].length > 0) {
					// obj.malto = [...r[0]["malto"]];
					malts  = r[0]["malto"].map((element: Malto) => {
						let newValue: any = new Object();
						Object.assign(newValue,element);
						// newValue.lotto_malto = key + "_" + element.lotto_malto;
						// newValue.ID_granella = key + "_" + element.ID_granella;
						if (element.transaction) {
							newValue.transaction = {
								cert_id: (element.transaction.txhash.lastIndexOf("?") >= 0)?  element.transaction.txhash.substring(0, element.transaction.txhash.lastIndexOf("?")): element.transaction.txhash,
								azienda: "birraperoni"
							}
						}
						obj.malto.push(newValue);
						return newValue;
					})
					countMalto+=malts.length;
					// obj.malto = [...malts];
					// console.log("aggiunto malts " + key, malts);

					// brewings.forEach((b, index) => {
					// 	if (malts.findIndex((el)=> {return el.lotto_malto == b.lotto_malto}) < 0 ) {
					// 		console.log("	WARNING Lotto malto not found. " + b.lotto_malto + " index "+ index);
							
					// 	}
					// })
					// malts.forEach((malt,index) => {
					// 	if (brewings.findIndex((el)=> {return el.lotto_malto == malt.lotto_malto}) < 0 ) {
					// 		console.log("	WARNING brewings not found. " + malt.lotto_malto + " index "+ index);
					// 	}
					// })
					// this.httpService.post("http://dashboard.posti.world:18081/api/v1/bo/entities/malts", malts).subscribe((rmalts) => {
					// 	// console.log("aggiunto malts " + key);
					// })
				}

				
				if (r && r[0] && r[0]["granella"] && r[0]["granella"].length > 0) {
					// obj.granella = [...r["granella"]];
					grains = r[0]["granella"].map((element) => {
						let newValue: any = new Object();
						if (element.id_granella == "06C23072020") console.log(element.id_granella + "=>" + element.transaction.block_height);
						
						Object.assign(newValue,element);
						// newValue.id_granella = key + "_" + element.id_granella;
						// newValue.codice_fornitore = key + "_" + element.codice_fornitore;

						if (element.transaction) {
							newValue.transaction = {
								cert_id: (element.transaction.txhash.lastIndexOf("?") >= 0)?  element.transaction.txhash.substring(0, element.transaction.txhash.lastIndexOf("?")): element.transaction.txhash,
								azienda: "birraperoni",
								block_height: element.transaction.block_height
							}
						}
						obj.granella.push(newValue);
						return newValue;
					})
					countGranella+=grains.length;
					// obj.granella = [...grains];
					// console.log("aggiunto grains " + key, grains);
					grains.forEach((malt,index) => {
						if (malts.findIndex((el)=> {return el.ID_granella == malt.id_granella}) < 0 ) {
							console.log("	WARNING malto da granella non trovato . " + malt.id_granella + " index "+ index);
						}
					})
					malts.forEach((malt,index) => {
						if (grains.findIndex((el)=> {return el.id_granella == malt.ID_granella}) < 0 ) {
							console.log("	WARNING granella da malto non trovato . " + malt.ID_granella + " index "+ index);
						}
					})
					// this.httpService.post("http://dashboard.posti.world:18081/api/v1/bo/entities/grains", grains).subscribe((rgrains) => {
					// 	// console.log("aggiunto grains " + key);
						
					// })
				}
				
				if (r && r[0] && r[0]["fornitori"] && r[0]["fornitori"].length > 0) {
					// obj.fornitori = [...r[0]["fornitori"]];
					farmers = r[0]["fornitori"].map((element: Farmer) => {
						let newValue: any = new Object();
						Object.assign(newValue,element);
						// newValue.codice_fornitore = key + "_" + element.codice_fornitore;
						
						if (element.transaction) {
							newValue.transaction = {
								cert_id: (element.transaction.txhash.lastIndexOf("?") >= 0)?  element.transaction.txhash.substring(0, element.transaction.txhash.lastIndexOf("?")): element.transaction.txhash,
								azienda: "birraperoni"
							}
						}
						obj.fornitori.push(newValue);
						return newValue;
					})
					countFornitori += farmers.length;
					// obj.fornitori = [...farmers];
					// console.log("aggiunto farmers " + key, farmers);
					// grains.forEach((malt,index) => {
					// 	if (farmers.findIndex((el)=> {return el.codice_fornitore == malt.codice_fornitore}) < 0 ) {
					// 		console.log("	WARNING fornitore da granella non trovato . " + malt.codice_fornitore + " index "+ index);
					// 	}
					// })
					// farmers.forEach((malt,index) => {
					// 	if (grains.findIndex((el)=> {return el.codice_fornitore == malt.codice_fornitore}) < 0 ) {
					// 		console.log("	WARNING granella da fornitore non trovato . " + malt.codice_fornitore + " index "+ index);
					// 	}
					// })
					// this.httpService.post("http://dashboard.posti.world:18081/api/v1/bo/entities/farmers", farmers).subscribe((rfarmers) => {
					// 	// console.log("aggiunto farmers " + key);
					// })
				}
				
			})
			} catch (error) {
				console.log(error);
					
			}



		}
		console.log("Estrazione completata");
		console.log(obj);
		console.log("conteggio countBr " +countBr );
		console.log("conteggio countFornitori " +countFornitori );
		console.log("conteggio countGranella " +countGranella );
		console.log("conteggio countMalto " +countMalto );
		
		let filteredFarmers : any = {};
		let filteredFarmersArr = [];
		obj.fornitori.forEach((f) => {filteredFarmers[f.codice_fornitore] = f});
		for (let fk in filteredFarmers) {
			filteredFarmersArr.push(filteredFarmers[fk]);
		}

		console.log("filtered farmers: ", filteredFarmersArr);
		

		let filteredGRains : any = {};
		let filteredGRainsArr = [];
		let duplicate = 0;
		obj.granella.forEach((g) => {
			// if (filteredGRains[g.id_granella+"-"+g.codice_fornitore + "-" + g.qty_orzo_consegnata + "-"+((g.transaction)?g.transaction.block_height:"")]) {
			// 	console.log("Trovato duplicato " + (g.id_granella+"-"+g.codice_fornitore + "-" + g.qty_orzo_consegnata + "-"+((g.transaction)?g.transaction.block_height:"")));
			// 	console.log(g);
				
			// }
			filteredGRains[g.id_granella+"-"+g.codice_fornitore + "-" + g.qty_orzo_consegnata ]=g
		});
		for (let fk in filteredGRains) {
			filteredGRainsArr.push(filteredGRains[fk]);
		}
		console.log("filtered grains: ", filteredGRainsArr);


		let filteredMalts : any = {};
		let filteredMaltsArr = [];
		obj.malto.forEach((g) => {filteredMalts[g.lotto_malto+"-"+g.ID_granella]=g});
		for (let fk in filteredMalts) {
			filteredMaltsArr.push(filteredMalts[fk]);
		}
		console.log("filtered malti: ", filteredMaltsArr);


		// let filteredBrewings : any = {};
		// let filteredBrewingsArr = [];
		// brewings.forEach((g) => {filteredBrewings[g.data_confezionamento+"-"+g.linea_produzione]=g});
		// for (let fk in filteredBrewings) {
		// 	filteredBrewingsArr.push(filteredBrewings[fk]);
		// }
		// console.log("filtered brewings: ", filteredBrewingsArr);

		this.httpService.post("http://dashboard.posti.world:18081/api/v1/bo/entities/farmers", filteredFarmersArr).subscribe((rfarmers) => {
			
		})
		this.httpService.post("http://dashboard.posti.world:18081/api/v1/bo/entities/grains", filteredGRainsArr).subscribe((rgrains) => {
			// console.log("aggiunto grains " + key);

		})
		this.httpService.post("http://dashboard.posti.world:18081/api/v1/bo/entities/malts", filteredMaltsArr).subscribe((rmalts) => {
			// console.log("aggiunto malts " + key);
		})
		for (let i=0; i < obj.brewings.length; i+=300) {
			this.httpService.post("http://dashboard.posti.world:18081/api/v1/bo/entities/brewings", obj.brewings.slice(i,i+300)).subscribe((rbrewings) => {
			
			})
		}


		// for (let key in certIds) {
		// 	console.log(key);
			
		// }
		// console.log(obj);
		
		var ws_name = "SheetJS";

		// /* make worksheet */
		// var ws_data = [
		//   [ "S", "h", "e", "e", "t", "J", "S" ],
		//   [  1 ,  2 ,  3 ,  4 ,  5 ]
		// ];
		// var ws = XLSX.utils.aoa_to_sheet(ws_data);
		// XLSX.utils.book_append_sheet(wb, ws, ws_name);
		// XLSX.writeFile(wb, 'out.xlsb');
		// let workBook = XLSX.writeFile(XLSX.utils.json_to_sheet(obj),"test");



	}

	lots = [];
}

var extracted = [
	"1097P2",
"1162P2",
"1110B2",
"1110B4",
"1046R2",
"1136R2",
"1139R2",
"1055R2",
"1050R2",
"1051R2",
"1114R2",
"1115R2",
"1143R2",
"1116R2",
"1137R2",
"1141R2",
"1050R1",
"1135R1",
"1136R1",
"1191B2",
"1124B2",
"1208B2",
"1130R2",
"1127R2",
"1126R2",
"1120B2",
"1133B2",
"1135R4",
"1127R4",
"1126R4",
"1163P2",
"1196R2",
"1207R2",
"1183P2",
"1194P2",
"1162P1",
"1270R2",
"1271R2",
"1273R2",
"1235B4",
"1227B2",
"1212P1",
"1284P1",
"1236B2",
"1236B4",
"1247B4",
"1220R2",
"1234R2",
"1221R2",
"1219R2",
"1228R2",
"1229R2",
"1248B1",
"1258B1",
"1258B2",
"1245B2",
"1241R2",
"1244R2",
"1273R4",
"1278B1",
"1278B2",
"1294B2",
"1294B4",
"1302B2",
"1308R1",
"1284P2",
"1077P1",
"1308R2",
"1300R2",
"1307B2",
"1348R2",
"1347R2",
"1319R2",
"1082P2",
"1077P2",
"1083P2",
"1096B4",
"1163P1",
"1324B2",
"1321R2",
"1321R4",
"1317R4",
"1285R4",
"1298R4",
"1282R4",
"1097P1",
"1299R2",
"1279R2",
"1291R2",
"1309R1",
"1326R1",
"1340R2",
"1333R2",
"1330R2",
"1356R2",
"1352R2",
"1152B4",
"1141B4",
"1092R2",
"1127B1",
"1170B1",
"1335R2",
"1338R2",
"1175B2",
"1127B2",
"1170B2",
"1179B2",
"1175B4",
"1176B4",
"1188B2",
"1192B2",
"1188B4",
"1192B4",
"1207B4",
"1203B2",
"1217B4",
"1243B2",
"1212B2",
"1217B2",
"1212B1",
"1221B1",
"1224B1",
"1243B1",
"1254B2",
"1259B2",
"1254B1",
"1092R1",
"1259B1",
"1061R4",
"1060R2",
"1057R2",
"1061R2",
"1071R2",
"1123R2",
"1125R2",
"1121R2",
"1121R4",
"1036B2",
"1062R2",
"1091R2",
"1203R4",
"1046R4",
"1298B1",
"1293B1",
"1301B1",
"1291B1",
"1298B2",
"1322B2",
"1315B4",
"1298B4",
"1351B4",
"1347B2",
"1261P1",
"1351B1",
"1354B2",
"1261P2",
"1354B4",
"2054R2",
"2045R2",
"2052R2",
"2055R2",
"2019R2",
"2013R2",
"2012R2",
"2044R2",
"2043R2",
"2042R2",
"2014R2",
"2041B2",
"2055R4"
]

// var certs =[

// "661P1",
// "49P2",
// "874P2",
// "661P2",
// "878P2",
// "875P2",
// "876P2",
// "738B2",
// "808B2",
// "813B2",
// "813B4",
// "738B4",
// "808B4",
// "442R2",
// "442R1",
// "827B2",
// "839B2",
// "823B2",
// "816B2",
// "442R4",
// "798B2",
// "790B2",
// "788B2",
// "784B2",
// "813B1",
// "738B1",
// "823B1",
// "816B1",
// "839B1",
// "827B1",
// "808B1",
// "816B4",
// "823B4",
// "839B4",
// "827B4",
// "905B4",
// "879B4",
// "883B4",
// "879B2",
// "883B2",
// "905B2",
// "892B2",
// "890B2",
// "890B4",
// "892B4",
// "883B1",
// "879B1",
// "905B1",
// "890B1",
// "918B1",
// "925B1",
// "892B1",
// "918B2",
// "925B2",
// "943B1",
// "939B1",
// "928B1",
// "943B2",
// "928B2",
// "939B2",
// "949B1",
// "951B1",
// "705B1",
// "949B2",
// "951B2",
// "705B2",
// "949B4",
// "943B4",
// "939B4",
// "951B4",
// "705B4",
// "959B1",
// "956B1",
// "959B2",
// "956B2",
// "959B4",
// "956B4",
// "798B4",
// "788B4",
// "790B4",
// "784B4",
// "49P1",
// "874P1",
// "878P1",
// "876P1",
// "875P1",
// "981B2",
// "977B2",
// "999B2",
// "990B2",
// "983B2",
// "993B2",
// "993B1",
// "983B1",
// "990B1",
// "999B1",
// "868B2",
// "860B2",
// "860B4",
// "868B4",
// "257P2",
// "258P2",
// "260P2",
// "267P2",
// "217P2",
// "219P2",
// "222P2",
// "228P2",
// "687B4",
// "358B4",
// "986B4",
// "995B4",
// "680B4",
// "986B2",
// "995B2",
// "680B2",
// "358B2",
// "687B2",
// "358B1",
// "687B1",
// "995B1",
// "692B1",
// "693B1",
// "986B1",
// "680B1",
// "991R4",
// "989R4",
// "991R2",
// "989R2",
// "715B2",
// "730B2",
// "728B2",
// "724B2",
// "1729P2",
// "1729P1",
// "4421R4",
// "4421R2",
// "254P1",
// "254P2",

// ]