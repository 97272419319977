import { Component, forwardRef, Input, Optional, Host, SkipSelf, Inject, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseInputComponent } from './base-input.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'input-html',
    templateUrl: './input-html.component.html',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputHtmlComponent), multi: true }
    ]
})

export class InputHtmlComponent extends BaseInputComponent {
    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, translateService: TranslateService) {
        super(controlContainer, translateService);
    }
	maxLength = 20;
	// maxLength = 10485760;
    protected toInternalFormat(value: string): string {
		if (value) return value.substring(0,this.maxLength);
        return value;
    }

    protected toExternalFormat(value: string): string {
        if (value == "") {
            return null;
        }
		if (value) return value.substring(0,this.maxLength);
        
		return value;
    }

}
