import { RestaurantProvider } from "app/services/restaurant.service";

export class CertificationConfig {
	name: string;
    identity?: string;
    pubkey?: string;
    external_api?: string;
}

export class CertificationBaseFormPage {
	protected certificationConfig: CertificationConfig;
	protected currentDomainConfig;
	
	constructor(protected _restaurantProvider: RestaurantProvider) {

	}

	private path = (obj,path) => {
        try{
            return eval("obj."+path);
        } catch(e) {
            return undefined;
        }
    }

	protected  certifyData = async (dataToCertify: any, secret?: string) => {
		let data = this.currentDomainConfig.marshalCerdData(dataToCertify);
		let certificationFieldsSpec = this.currentDomainConfig.resetCertificationFieldsSpec(dataToCertify);
		
		
		let fieldsToCertify : any = {};
		for (const element of certificationFieldsSpec) {
			if (element.isArray) {
				fieldsToCertify = [];
				for (const obj of data[element.fieldId]) {
					if (!obj.removedFromBlockChain) {
						let objToCert: any = {};
						for (const spec of element.arrayFieldsSpec) {
							if (this.path(obj, spec.fieldId)) {
								objToCert[spec.fieldLabel] =  (spec.startValue) ? spec.startValue + this.path(obj, spec.fieldId): this.path(obj, spec.fieldId);
								if (spec.isImage) {
									await this._restaurantProvider.getImageHash(this.path(obj, spec.fieldId)).toPromise().then((imgHash) => {
										objToCert[spec.fieldLabel.replaceAll("_url","_hash")] = imgHash;
									});
								}
	
							}
						}
						fieldsToCertify.push(objToCert);
					}
					
				}
			}
			else {
				if (element.isImage) {
					fieldsToCertify[element.fieldLabel + "_url"] =  (element.startValue) ? element.startValue + this.path(data, element.fieldId): this.path(data, element.fieldId);
					await this._restaurantProvider.getImageHash(this.path(data, element.fieldId)).toPromise().then((imgHash) => {
						fieldsToCertify[element.fieldLabel+ "_hash"]= imgHash;
					});
				}
				else {
					fieldsToCertify[element.fieldLabel] =  (element.startValue) ? element.startValue + this.path(data, element.fieldId): this.path(data, element.fieldId);

				}

			}
		}
		let toSend: any = new Object();
		Object.assign(toSend, this.certificationConfig);
		toSend.azienda = this.currentDomainConfig.domainId;
		toSend.cert_id = this.path(data, this.currentDomainConfig.certIdField);
		toSend.data = fieldsToCertify;
		return this._restaurantProvider.certifyRestaurant(toSend).toPromise();
	}

}