import { Validators } from "@angular/forms";
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { DataTableUtils } from "app/common/utils/data-table.utils";
import { environment } from "environments/environment";
import moment from "moment";
import { DOMAINS_CONFIG } from "./restaurant.service";


export var domainConfig = {
	ROMANESCO : {
		domainId: "ROMANESCO",
		name: "Romanesco",
		title: "Tipico Romanesco",
		showBlockChainUrl: true,
		blockchainUrlVerify: "https://verifier.posti.world/{domain}/{id}/verify",
		downloadExcelEnabled: true,
		deleteEntityEnabled: true,
		certIdField: "id",
		fields: [
			{id: "id", hidden: true},
			{id: "lastUpdateDate", hidden: true},
			{id: "sign", dataTableField: DataTableUtils.createStringColumn("sign", "Nome del ristorante", true), hidden: false},
			{id: "address", dataTableField: DataTableUtils.createStringColumn("address", "Indirizzo", true), hidden: false}
		],
		map: (entity) => {return entity},
		entityLabel: "ristoranti",
		managedEntity: [
			{
				entityType: "Restaurant",
				entityLabel: "Ristorante"
			}
		],
		marshalCerdData: (data) => {
			return data;
		},
		resetCertificationFieldsSpec: (data?: any) => {
			return [
				{fieldId: "sign", fieldLabel: "Nome locale"},
				{fieldId: "representative", fieldLabel: "rappresentante"},
				{fieldId: "email", fieldLabel: "email"},
				{fieldId: "address", fieldLabel: "indirizzo"},
				{fieldId: "city", fieldLabel: "comune"},
				{fieldId: "province", fieldLabel: "provincia"},
				{fieldId: "suggestedAndRecipe.imageSuggested", fieldLabel: "foto ricetta", startValue: environment.services.mediaContentsBasePath + "/", isImage: true},
				{fieldId: "suggestedAndRecipe.name", fieldLabel: "nome ricetta"},
				{fieldId: "suggestedAndRecipe.chefName", fieldLabel: "autore"},
				{fieldId: "suggestedAndRecipe.ingredients", fieldLabel: "ingredienti per 4 persone"},
				{fieldId: "suggestedAndRecipe.suggestedByChef", fieldLabel: "la preparazione e il tocco dello chef"},
			];
		}
	},
	PECORA_NERA: {
		domainId: "PECORA_NERA",
		name: "Pecora Nera",
		title: "Blockchain Pecora Nera",
		pageIcon: "https://www.lapecoranera.net/wp-content/themes/lapecoranera/images/logo-footer.png",
		showBlockChainUrl: true,
		blockchainUrlVerify: "https://verifier.posti.world/{domain}/{id}/verify",
		downloadExcelEnabled: true,
		deleteEntityEnabled: true,
		certIdField: "id",
		fields: [
			{id: "id", hidden: true},
			{id: "sign", dataTableField: DataTableUtils.createStringColumn("sign", "Nome del ristorante", true), hidden: false, filter: true},
			{id: "city", dataTableField: DataTableUtils.createStringColumn("city", "Città", true), hidden: false, filter: true},
			{id: "address", dataTableField: DataTableUtils.createStringColumn("address", "Indirizzo", true), hidden: false, filter: true},
			{id: "guide", dataTableField: DataTableUtils.createStringColumn("guide", "Guida", true), hidden: false, filter: true},
			{id: "evaluations", dataTableField: DataTableUtils.createStringColumn("evaluationNumbers", "valutazioni", true), hidden: false},
			{id: "evaluations", dataTableField: DataTableUtils.createStringColumn("reciptNumbers", "scontrini caricati", true), hidden: false}
		],
		map: (entity) => {
			entity["reciptNumbers"] = 0;
			entity["evaluationNumbers"] = 0;
			
			if (entity.evaluations) {
				entity["evaluationNumbers"] = entity.evaluations.length;

				entity.evaluations.forEach(element => {
					if (element.receiptPhoto)
					entity["reciptNumbers"]++;
				});
			}
			return entity
		},
		entityLabel: "ristoranti",
		managedEntity: [
			{
				entityType: "Restaurant",
				entityLabel: "Ristorante"
			}
		],
		marshalCerdData: (data) => {
			return data;
		},
		resetCertificationFieldsSpec: (data?: any) => {
			return [
				{
					fieldId: "evaluations",
					isArray: true,
					arrayFieldsSpec: [
						{fieldId: "visitDate", fieldLabel: "Data della visita"},
						{fieldId: "receiptPhoto", fieldLabel: "Foto dello scontrino_url", startValue: environment.services.mediaContentsBasePath + "/", isImage: true},
						{fieldId: "firstDish", fieldLabel: "foto del piatto 1_url", startValue: environment.services.mediaContentsBasePath + "/", isImage: true},
						{fieldId: "secondDish", fieldLabel: "foto del piatto 2_url", startValue: environment.services.mediaContentsBasePath + "/", isImage: true},
						{fieldId: "thirdDish", fieldLabel: "foto del piatto 3_url", startValue: environment.services.mediaContentsBasePath + "/", isImage: true},
						{fieldId: "fourthDish", fieldLabel: "foto del piatto 4_url", startValue: environment.services.mediaContentsBasePath + "/", isImage: true},
						{fieldId: "fifthDish", fieldLabel: "foto del piatto 5_url", startValue: environment.services.mediaContentsBasePath + "/", isImage: true},
						{fieldId: "sixtDish", fieldLabel: "foto del piatto 6_url", startValue: environment.services.mediaContentsBasePath + "/", isImage: true},
						{fieldId: "sevenDish", fieldLabel: "foto del piatto 7_url", startValue: environment.services.mediaContentsBasePath + "/", isImage: true},
						{fieldId: "eighthDish", fieldLabel: "foto del piatto 8_url", startValue: environment.services.mediaContentsBasePath + "/", isImage: true},
						{fieldId: "ninthDish", fieldLabel: "foto del piatto 9_url", startValue: environment.services.mediaContentsBasePath + "/", isImage: true},
						{fieldId: "firstOil", fieldLabel: "Olio 1_url", startValue: environment.services.mediaContentsBasePath + "/", isImage: true},
						{fieldId: "secondOil", fieldLabel: "Olio 2_url", startValue: environment.services.mediaContentsBasePath + "/", isImage: true},
						{fieldId: "thirdOil", fieldLabel: "Olio 3_url", startValue: environment.services.mediaContentsBasePath + "/", isImage: true}
					
					]
				}
			]
		}
	},
	PECORA_NERA_2: {
		domainId: "PECORA_NERA",
		name: "Pecora Nera",
		title: "Blockchain Pecora Nera",
		pageIcon: "https://www.lapecoranera.net/wp-content/themes/lapecoranera/images/logo-footer.png",
		showBlockChainUrl: false,
		blockchainUrlVerify: "https://verifier.posti.world/{domain}/{id}/verify",
		downloadExcelEnabled: false,
		deleteEntityEnabled: false,
		certIdField: null,
		fields: [
			{id: "id", hidden: true},
			{id: "sign", dataTableField: DataTableUtils.createStringColumn("sign", "Nome del ristorante", true), hidden: false, filter: true},
			{id: "city", dataTableField: DataTableUtils.createStringColumn("city", "Città", true), hidden: false, filter: true},
			{id: "address", dataTableField: DataTableUtils.createStringColumn("address", "Indirizzo", true), hidden: false, filter: true},
			{id: "guide", dataTableField: DataTableUtils.createStringColumn("guide", "Guida", true), hidden: false, filter: true},
			{id: "evaluations", dataTableField: DataTableUtils.createStringColumn("evaluationNumbers", "valutazioni", true), hidden: false},
			{id: "evaluations", dataTableField: DataTableUtils.createStringColumn("reciptNumbers", "scontrini caricati", true), hidden: false}
		],
		map: (entity) => {
			entity["reciptNumbers"] = 0;
			entity["evaluationNumbers"] = 0;
			
			if (entity.evaluations) {
				entity["evaluationNumbers"] = entity.evaluations.length;

				entity.evaluations.forEach(element => {
					if (element.receiptPhoto)
					entity["reciptNumbers"]++;
				});
			}
			return entity
		},
		entityLabel: "ristoranti",
		managedEntity: [
			{
				entityType: "Restaurant",
				entityLabel: "Ristorante"
			}
		],
		marshalCerdData: (data) => {
			return data;
		},
		resetCertificationFieldsSpec: (data?: any) => {
			return []
		}
	},
	GIUSTA: {
		domainId: "GIUSTA",
		name: "Giusta Delivery",
		title: "Giusta Delivery",
		showBlockChainUrl: true,
		blockchainUrlVerify: "https://verifier.posti.world/{domain}/{id}/verify",
		downloadExcelEnabled: true,
		deleteEntityEnabled: true,
		fields: [
			{id: "id", hidden: true},
			{id: "businessName", dataTableField: DataTableUtils.createStringColumn("businessName", "Ragione sociale", true), hidden: false},
			{id: "sign", dataTableField: DataTableUtils.createStringColumn("sign", "Nome del ristorante", true), hidden: false},
			{id: "address", hidden: true},
			{id: "city", hidden: true},
			{id: "simpleAddress", dataTableField: DataTableUtils.createStringColumn("simpleAddress", "Indirizzo", true), hidden: false}

		],
		map: (entity) => {
			entity["simpleAddress"] = entity["city"] + ", " + entity["address"];
			return entity
		},
		entityLabel: "ristoranti",
		managedEntity: [
			{
				entityType: "Restaurant",
				entityLabel: "Ristorante"
			}
		],

		certIdField: "id",
		marshalCerdData: (data) => {
			let result: any = new Object();
			Object.assign(result,data);
			if (result.usefullInfo.accessibility && result.usefullInfo.accessibility.length > 0)
				result.usefullInfo.accessibility = result.usefullInfo.accessibility.join(", ");

			if (result.deliveryInfo.menuType && result.deliveryInfo.menuType.length > 0)
				result.deliveryInfo.menuType = result.deliveryInfo.menuType.join(", ");
			
			if (result.deliveryInfo.paymentType && result.deliveryInfo.paymentType.length > 0)
				result.deliveryInfo.paymentType = result.deliveryInfo.paymentType.join(", ");
				
			return data;
		},

		

		resetCertificationFieldsSpec: (data?: any) => {
			let certificationFieldsSpec = [
				{fieldId: "businessName", fieldLabel: "Azienda"},
				{fieldId: "logo", fieldLabel: "Città"},
				{fieldId: "logo", fieldLabel: "Logo", isImage:true, startValue: environment.services.mediaContentsBasePath + "/"},
				{fieldId: "sign", fieldLabel: "Nome locale"},
				{fieldId: "vatNumber", fieldLabel: "Partita IVA"},
				{fieldId: "sdiOrPec", fieldLabel: "SDI"},
				{fieldId: "pec", fieldLabel: "PEC"},
				{fieldId: "representative", fieldLabel: "Rappresentante"},
				{fieldId: "address", fieldLabel: "Indirizzo"},
				{fieldId: "zipCode", fieldLabel: "CAP"},
				{fieldId: "city", fieldLabel: "Citta"},
				{fieldId: "province", fieldLabel: "Provincia"},
				{fieldId: "phone", fieldLabel: "Telefono"},
				{fieldId: "fipePartner", fieldLabel: "Socio Fipe Roma"},
				{fieldId: "fipeCardNumber", fieldLabel: "Numero tessera"},
				{fieldId: "mobilePhone", fieldLabel: "Cellulare"},
				{fieldId: "email", fieldLabel: "Email"},
				{fieldId: "website", fieldLabel: "Sito web"},
				{fieldId: "socialFacebook", fieldLabel: "Facebook"},
				{fieldId: "socialInstagram", fieldLabel: "Instagram"},
				{fieldId: "socialTikTok", fieldLabel: "Tik Tok"},
				{fieldId: "description", fieldLabel: "Descrizione"},
				{fieldId: "ownerStory", fieldLabel: "Storia del locale o titolare"},
				
				{fieldId: "usefullInfo.openingTime", fieldLabel: "Orari di apertura"},
				{fieldId: "usefullInfo.reservation", fieldLabel: "Necessaria la Prenotazione"},
				{fieldId: "usefullInfo.weeklyClosing", fieldLabel: "Chiusura settimanale"},
				{fieldId: "usefullInfo.annualClosing", fieldLabel: "Chiusura annuale"},
				{fieldId: "usefullInfo.creditCards", fieldLabel: "Carte di credito e Bancomat"},
				{fieldId: "usefullInfo.accessibility", fieldLabel: "Accessibilità"},
				{fieldId: "usefullInfo.parking", fieldLabel: "Parcheggio"},
				{fieldId: "usefullInfo.petsAllowed", fieldLabel: "Animali ammessi"},
				{fieldId: "usefullInfo.wifi", fieldLabel: "WiFi"},
				{fieldId: "usefullInfo.internalSeats", fieldLabel: "coperti sala interna"},
				{fieldId: "usefullInfo.externalSeats", fieldLabel: "coperti all aperto"},
				{fieldId: "usefullInfo.kitchenType", fieldLabel: "Tipo di cucina"},
				{fieldId: "usefullInfo.specialDietsDishes", fieldLabel: "Piatti adatti a diete speciali"},
				{fieldId: "usefullInfo.specialProductsUsage", fieldLabel: "Utilizzo prodotti biologici"},
				{fieldId: "usefullInfo.priceRange", fieldLabel: "Fascia di prezzo - bevande escluse"},
				
			
				{fieldId: "contactInfo.firstName", fieldLabel: "contatto - Nome"},
				{fieldId: "contactInfo.lastName", fieldLabel: "contatto - Cognome"},
				{fieldId: "contactInfo.phone", fieldLabel: "contatto - telefono"},
				{fieldId: "contactInfo.email", fieldLabel: "contatto - email"},
				
				
				{fieldId: "deliveryInfo.paymentType", fieldLabel: "Tipologie di consegna e pagamento"},
				{fieldId: "deliveryInfo.menuType", fieldLabel: "Tipologia menu delivery"},
				{fieldId: "deliveryInfo.deliveryDays", fieldLabel: "giorni di consegna"},
				{fieldId: "deliveryInfo.lunchDeliveryHours", fieldLabel: "Orari di servizio pranzo"},
				{fieldId: "deliveryInfo.dinnerDeliveryHours", fieldLabel: "Orari di servizio cena"},
				{fieldId: "deliveryInfo.deliverySolutionsTimeInterval", fieldLabel: "intervalli tempi di consegna"},
				{fieldId: "deliveryInfo.orderRestrictions", fieldLabel: "restrizioni di ordine"},
				{fieldId: "deliveryInfo.notes", fieldLabel: "Note e suggerimenti"},
				
				{fieldId: "deliveryMenu.menuImage", fieldLabel: "menu",isImage:true, startValue: environment.services.mediaContentsBasePath + "/"}
				

			];
			
			return certificationFieldsSpec;
		}

	},
	BONFIT: {
		domainId: "BONFIT",
		name: "Bonfit",
		title: "ETICA food srl - Bonfitt",
		showBlockChainUrl: true,
		blockchainUrlVerify: "https://verifier.posti.world/{domain}/{id}/verify",
		downloadExcelEnabled: true,
		deleteEntityEnabled: true,
		certIdField: "generalInfo.productCode",
		fields: [
			{id: "id", hidden: true},
			{id: "producerInfo.product", dataTableField: DataTableUtils.createStringColumn("product", "prodotto", true), hidden: false},
			{id: "generalInfo.productCode", dataTableField: DataTableUtils.createStringColumn("productCode", "Codice etichetta", true), hidden: false},
			{id: "generalInfo.packagingDate", dataTableField: DataTableUtils.createDateColumn("packagingDate", "Data di confezionamento", true), hidden: false},
			{id: "generalInfo.expiryDate", dataTableField: DataTableUtils.createDateColumn("expiryDate", "Data di scadenza", true), hidden: false}

		],
		map: (entity) => {
			entity.product = entity["producerInfo"].product;
			entity.productCode = entity["generalInfo"].productCode;
			entity.packagingDate = entity["generalInfo"].packagingDate;
			entity.expiryDate = entity["generalInfo"].expiryDate;
			return entity
		},
		entityLabel: "Panettoni",
		managedEntity: [
			{
				entityType: "Panettone",
				entityLabel: "Panettone"
			}
		],

		marshalCerdData: (data) => {
			let result: any = {};
			result.id = data.id;
			result.producerInfo = {};
			result.producerInfo.company = data.producerInfo.company;
			result.producerInfo.city = data.producerInfo.city;
			result.producerInfo.address = data.producerInfo.address;
			result.producerInfo.product = data.producerInfo.product;
			
			result.generalInfo = {};
			result.generalInfo.productionLot = data.generalInfo.productionLot;
			result.generalInfo.productCode = data.generalInfo.productCode;
			result.generalInfo.photo = data.generalInfo.photo;
			result.generalInfo.packagingDate = moment(data.generalInfo.packagingDate).format("DD/MM/YYYY");
			result.generalInfo.expiryDate =  moment(data.generalInfo.expiryDate).format("DD/MM/YYYY");
			if (data.ingrediants && data.ingrediants.length>0) {
				data.ingrediants.forEach((ingredient,index) => {
					result["ingrediants_"+index] = ingredient.ingrediant +  " - " + ((ingredient.producer)? ingredient.producer: "/");
				});
			}
			return result;
		},
		resetCertificationFieldsSpec: (data?: any) => {
			let certificationFieldsSpec = [
				{fieldId: "producerInfo.company", fieldLabel: "Azienda"},
				{fieldId: "producerInfo.city", fieldLabel: "Città"},
				{fieldId: "producerInfo.address", fieldLabel: "Indirizzo"},
				{fieldId: "producerInfo.product", fieldLabel: "Prodotto"},
	
				{fieldId: "generalInfo.productionLot", fieldLabel: "Lotto di produzione"},
				{fieldId: "generalInfo.productCode", fieldLabel: "Codice Etichetta"},
				{fieldId: "generalInfo.photo", fieldLabel: "Foto", isImage:true, startValue: environment.services.mediaContentsBasePath + "/"},
				{fieldId: "generalInfo.packagingDate", fieldLabel: "Data di confezionamento"},
				{fieldId: "generalInfo.expiryDate", fieldLabel: "Data di scadenza"}
			];
			if (data) {
				if (data.ingrediants && data.ingrediants.length>0) {
					data.ingrediants.forEach((ingredient,index) => {
						certificationFieldsSpec.push(
							{fieldId: "ingrediants_"+index, fieldLabel: (index+1) + ". ingrediente - produttore"}
						)
					});
				}
			}
			return certificationFieldsSpec;
		}
	},
	NEW_PAT_2: {
		domainId: "NEW_PAT_2",
		name: "Patt",
		title: "ETICA food srl",
		showBlockChainUrl: true,
		blockchainUrlVerify: "https://verifier.posti.world/{domain}/{id}/verify",
		downloadExcelEnabled: true,
		deleteEntityEnabled: true,
		certIdField: "generalInfo.productCode",
		fields: [
			{id: "id", hidden: true},
			{id: "producerInfo.product", dataTableField: DataTableUtils.createStringColumn("product", "prodotto", true), hidden: false},
			{id: "generalInfo.productCode", dataTableField: DataTableUtils.createStringColumn("productCode", "Codice etichetta", true), hidden: false},
			{id: "generalInfo.packagingDate", dataTableField: DataTableUtils.createDateColumn("packagingDate", "Data di confezionamento", true), hidden: false},
			{id: "generalInfo.expiryDate", dataTableField: DataTableUtils.createDateColumn("expiryDate", "Data di scadenza", true), hidden: false}

		],
		map: (entity) => {
			entity.product = entity["producerInfo"].product;
			entity.productCode = entity["generalInfo"].productCode;
			entity.packagingDate = entity["generalInfo"].packagingDate;
			entity.expiryDate = entity["generalInfo"].expiryDate;
			return entity
		},
		entityLabel: "Panettoni",
		managedEntity: [
			{
				entityType: "Panettone",
				entityLabel: "Panettone"
			}
		],

		marshalCerdData: (data) => {
			let result: any = {};
			result.id = data.id;
			result.producerInfo = {};
			result.producerInfo.company = data.producerInfo.company;
			result.producerInfo.city = data.producerInfo.city;
			result.producerInfo.address = data.producerInfo.address;
			result.producerInfo.product = data.producerInfo.product;
			
			result.generalInfo = {};
			result.generalInfo.productionLot = data.generalInfo.productionLot;
			result.generalInfo.productCode = data.generalInfo.productCode;
			result.generalInfo.photo = data.generalInfo.photo;
			result.generalInfo.packagingDate = moment(data.generalInfo.packagingDate).format("DD/MM/YYYY");
			result.generalInfo.expiryDate =  moment(data.generalInfo.expiryDate).format("DD/MM/YYYY");
			if (data.ingrediants && data.ingrediants.length>0) {
				data.ingrediants.forEach((ingredient,index) => {
					result["ingrediants_"+index] = ingredient.ingrediant +  " - " + (((ingredient.producer)? ingredient.producer: "/") + ((ingredient.lot)? ", lotto "+ingredient.lot+"": "") );
				});
			}
			return result;
		},
		resetCertificationFieldsSpec: (data?: any) => {
			let certificationFieldsSpec = [
				{fieldId: "producerInfo.company", fieldLabel: "Azienda"},
				{fieldId: "producerInfo.city", fieldLabel: "Città"},
				{fieldId: "producerInfo.address", fieldLabel: "Indirizzo"},
				{fieldId: "producerInfo.product", fieldLabel: "Prodotto"},
	
				{fieldId: "generalInfo.productionLot", fieldLabel: "Lotto di produzione"},
				{fieldId: "generalInfo.productCode", fieldLabel: "Codice Etichetta"},
				{fieldId: "generalInfo.photo", fieldLabel: "Foto", isImage:true, startValue: environment.services.mediaContentsBasePath + "/"},
				{fieldId: "generalInfo.packagingDate", fieldLabel: "Data di confezionamento"},
				{fieldId: "generalInfo.expiryDate", fieldLabel: "Data di scadenza"}
			];
			if (data) {
				if (data.ingrediants && data.ingrediants.length>0) {
					data.ingrediants.forEach((ingredient,index) => {
						certificationFieldsSpec.push(
							{fieldId: "ingrediants_"+index, fieldLabel: (index+1) + ". ingrediente - produttore"}
						)
					});
				}
			}
			return certificationFieldsSpec;
		}
	},


	hqfagricola_demo: {
		domainId: DOMAINS_CONFIG.HQFAgricola,
		name: "hqfagricola_demo",
		title: "HQF Agricola",
		showBlockChainUrl: true,
		blockchainUrlVerify: "https://verifier.posti.world/{domain}/{id}/verify",
		landingPageUrl: "https://www.posti.world/marango?id={id}",
		downloadExcelEnabled: false,
		deleteEntityEnabled: true,
		certIdField: "id",
		fields: [
			{id: "id", hidden: true},
			{id: "product", dataTableField: DataTableUtils.createStringColumn("product", "prodotto", true), hidden: false},
			{id: "lotInfo.lotNumber", dataTableField: DataTableUtils.createStringColumn("lotNumber", "Codice lotto", true), hidden: false},
			{id: "lotInfo.slaughterDate", dataTableField: DataTableUtils.createDateColumn("slaughterDate", "Data di macellazione", true), hidden: false},
			{id: "lotInfo.slaughterSite", dataTableField: DataTableUtils.createStringColumn("slaughterSite", "Sito di macellazione", true), hidden: false},
			{id: "lotInfo.expiryDate", dataTableField: DataTableUtils.createDateColumn("expiryDate", "Data di scadenza", true), hidden: false}

		],
		map: (entity) => {
			entity.lotNumber = entity["lotInfo"].lotNumber;
			entity.slaughterDate = entity["lotInfo"].slaughterDate;
			entity.slaughterSite = entity["lotInfo"].slaughterSite;
			entity.expiryDate = entity["lotInfo"].expiryDate;
			return entity
		},
		entityLabel: "Marango Italiano",
		managedEntity: [
			{
				entityType: "Prodotto",
				entityLabel: "Marango Italiano"
			}
		],

		marshalCerdData: (data) => {

			let result: any = {};
			result.id = data.id;
			result.companyName = data.companyName;
			result.region = data.region;
			result.companyCoordinates = data.companyCoordinates;
			result.province = data.province;
			result.product = data.product;
			result.giftDescription = data.giftDescription;

			result.lotInfo = {};
			
			result.lotInfo.lotNumber = data.lotInfo.lotNumber;
			result.lotInfo.slaughterDate =  moment(data.lotInfo.slaughterDate).format("DD/MM/YYYY");
			result.lotInfo.slaughterSite = data.lotInfo.slaughterSite;
			result.lotInfo.laboratory = data.lotInfo.laboratory;
			result.lotInfo.expiryDate =  moment(data.lotInfo.expiryDate).format("DD/MM/YYYY");


			result.productInfo = {};
			result.productInfo.origin = data.productInfo.origin;
			result.productInfo.farm = data.productInfo.farm;
			result.productInfo.diet = data.productInfo.diet;
			result.productInfo.dietSpec = data.productInfo.dietSpec;
		

			return result;
		},
		resetCertificationFieldsSpec: (data?: any) => {
			let certificationFieldsSpec = [
				{fieldId: "companyName", fieldLabel: "azienda"},
				{fieldId: "region", fieldLabel: "regione allevamento"},
				{fieldId: "companyCoordinates", fieldLabel: "coordinate azienda dove sorge allevamento"},
				{fieldId: "province", fieldLabel: "provincia di provenienza allevamento"},
				{fieldId: "product", fieldLabel: "prodotto"},
				{fieldId: "giftDescription", fieldLabel: "Gift collegato"},

				
				{fieldId: "lotInfo.lotNumber", fieldLabel: "lotto"},
				{fieldId: "lotInfo.slaughterDate", fieldLabel: "data di macellazione"},
				{fieldId: "lotInfo.slaughterSite", fieldLabel: "sito di macellazione"},
				{fieldId: "lotInfo.laboratory", fieldLabel: "laboratorio di sezionamento"},
				{fieldId: "lotInfo.expiryDate", fieldLabel: "data di scadenza"},
			
				{fieldId: "productInfo.origin", fieldLabel: "origine prodotto"},
				{fieldId: "productInfo.farm", fieldLabel: "allevamento"},
				{fieldId: "productInfo.diet", fieldLabel: "alimentazione specifica"},
				{fieldId: "productInfo.dietSpec", fieldLabel: "caratteristiche alimentazione Marango Fioc"},

			];
			
			return certificationFieldsSpec;
		}
	},

	casearia: {
		domainId: DOMAINS_CONFIG.casearia,
		name: "casearia",
		title: "Casearea Agri In",
		showBlockChainUrl: true,
		blockchainUrlVerify: "https://verifier.posti.world/{domain}/{id}/verify",
		landingPageUrl: "https://www.posti.world/conciatodisanvittore?id={id}",
		claimedFieldName: "giftDescription",
		downloadExcelEnabled: false,
		deleteEntityEnabled: true,
		certIdField: "id",
		fields: [
			{id: "id", hidden: true},
			{id: "productInfo.productName", dataTableField: DataTableUtils.createStringColumn("product", "prodotto", true), hidden: false},
			{id: "bolloCe", dataTableField: DataTableUtils.createStringColumn("bolloCe", "Bollo CE", true), hidden: false},
			{id: "giftDescription", dataTableField: DataTableUtils.createStringColumn("giftDescription", "Sconto disponibile", true), hidden: false},

		],
		map: (entity) => {
			entity.product = entity.productInfo.productName;
			return entity;
		},
		entityLabel: "Formaggio",
		managedEntity: [
			{
				entityType: "Prodotto",
				entityLabel: "Formaggio"
			}
		],

		marshalCerdData: (data) => {

			let result: any = new Object();
			Object.assign(result, data);
			
			if (data.lotsInfo && data.lotsInfo.length>0) {
				data.lotsInfo.forEach((ingredient,index) => {
					result["lot_"+index] = ingredient.ingrediant +  " - " + (((ingredient.producer)? ingredient.producer: "/") + ((ingredient.lot)? ", lotto "+ingredient.lot+"": "") );
				});
			}
		

			return result;
		},
		resetCertificationFieldsSpec: (data?: any) => {
			let certificationFieldsSpec = [
				{fieldId: "companyName", fieldLabel: "Azienda"},
				{fieldId: "bolloCe", fieldLabel: "Bollo CE"},
				{fieldId: "productionPlace", fieldLabel: "Luogo di produzione"},
				{fieldId: "province", fieldLabel: "Provincia provenienza"},
				{fieldId: "giftDescription", fieldLabel: "Descrizione del regalo associato"},
				{fieldId: "image", fieldLabel: "immagine_url", startValue: environment.services.mediaContentsBasePath + "/", isImage: true},

				{fieldId: "productInfo.productName", fieldLabel: "Nome prodotto"},
				{fieldId: "productInfo.producerSpecie", fieldLabel: "Specie produttrice"},
				{fieldId: "productInfo.diet", fieldLabel: "Alimentazione animale"},
				{fieldId: "productInfo.milkOrigin", fieldLabel: "Provenienza latte utilizzato"},
				
				{fieldId: "socialImpact.s1", fieldLabel: "Principio etico n.1"},
				{fieldId: "socialImpact.s2", fieldLabel: "Principio etico n.2"},
				{fieldId: "socialImpact.s3", fieldLabel: "Principio etico n.3"},
				{fieldId: "socialImpact.s4", fieldLabel: "Principio etico n.4"}
			];

			if (data) {
				if (data.lotsInfo && data.lotsInfo.length>0) {
					data.lotsInfo.forEach((lot,index) => {
						certificationFieldsSpec.push(
							{fieldId: "lot_"+index, fieldLabel: (index+1) + ". ingrediente - produttore"}
						)
					});
				}
			}
			
			return certificationFieldsSpec;
		}
	},

	biolu: {
		domainId: DOMAINS_CONFIG.biolu,
		name: "Biolu",
		title: "Biolu",
		showBlockChainUrl: true,
		blockchainUrlVerify: "https://verifier.posti.world/{domain}/{id}/verify",
		landingPageUrl: "https://www.posti.world/olioevo?id={id}",
		claimedFieldName: null,
		downloadExcelEnabled: false,
		deleteEntityEnabled: true,
		certIdField: "id",
		fields: [
			{id: "id", hidden: true},
			{id: "productName", dataTableField: DataTableUtils.createStringColumn("productName", "prodotto", true), hidden: false},
			{id: "lotInfo.lotNumber", dataTableField: DataTableUtils.createStringColumn("lotNumber", "Lotto", true), hidden: false},
			{id: "lotInfo.collectionDate", dataTableField: DataTableUtils.createDateColumn("collectionDate", "Data di raccolta", true), hidden: false},
			{id: "lotInfo.productionDate", dataTableField: DataTableUtils.createDateColumn("productionDate", "Data di molitura", true), hidden: false},
			{id: "lotInfo.bottlingDate", dataTableField: DataTableUtils.createDateColumn("bottlingDate", "Data di imbottigliamento", true), hidden: false}
		],
		map: (entity) => {
			entity.lotNumber = entity.lotInfo.lotNumber;
			entity.collectionDate = entity.lotInfo.collectionDate;
			entity.productionDate = entity.lotInfo.productionDate;
			entity.bottlingDate = entity.lotInfo.bottlingDate;

			return entity;
		},
		entityLabel: "Olio",
		managedEntity: [
			{
				entityType: "Prodotto",
				entityLabel: "Olio Evo"
			}
		],

		marshalCerdData: (data) => {

			let result: any = new Object();
			Object.assign(result, data);
			result.lotInfo.collectionDate =  moment(data.lotInfo.collectionDate).format("DD/MM/YYYY");
			result.lotInfo.productionDate =  moment(data.lotInfo.productionDate).format("DD/MM/YYYY");
			result.lotInfo.bottlingDate =  moment(data.lotInfo.bottlingDate).format("DD/MM/YYYY");
			
			// if (data.lotsInfo && data.lotsInfo.length>0) {
			// 	data.lotsInfo.forEach((ingredient,index) => {
			// 		result["lot_"+index] = ingredient.ingrediant +  " - " + (((ingredient.producer)? ingredient.producer: "/") + ((ingredient.lot)? ", lotto "+ingredient.lot+"": "") );
			// 	});
			// }
		

			return result;
		},
		resetCertificationFieldsSpec: (data?: any) => {
			let certificationFieldsSpec = [
				{fieldId: "companyName", fieldLabel: "Azienda"},
				{fieldId: "city", fieldLabel: "Provenienza"},
				{fieldId: "province", fieldLabel: "Provincia provenienza"},
				{fieldId: "productName", fieldLabel: "Prodotto"},
				{fieldId: "productSpecie", fieldLabel: "Varieta"},

				{fieldId: "lotInfo.lotNumber", fieldLabel: "Lotto"},
				{fieldId: "lotInfo.collectionDate", fieldLabel: "Data di raccolta"},
				{fieldId: "lotInfo.productionDate", fieldLabel: "Data di molitura"},
				{fieldId: "lotInfo.bottlingDate", fieldLabel: "Data di imbottigliamento"},
				{fieldId: "lotInfo.giftDescription", fieldLabel: "Descrizione del regalo associato"}
				
			];
			if (data && data.image) {
				let d: any = {fieldId: "image", fieldLabel: "immagine_url", startValue: environment.services.mediaContentsBasePath + "/", isImage: true};
				certificationFieldsSpec.push(d);
			}
			return certificationFieldsSpec;
		}
	},

	casearia_pecorino: {
		domainId: DOMAINS_CONFIG.casearia_pecorino,
		name: "casearia - Pecorino Romano",
		title: "Casearea Agri In",
		showBlockChainUrl: true,
		blockchainUrlVerify: "https://verifier.posti.world/{domain}/{id}/verify",
		landingPageUrl: "https://www.posti.world/pecorinostagionato?id={id}",
		claimedFieldName: "giftDescription",
		downloadExcelEnabled: false,
		deleteEntityEnabled: true,
		certIdField: "id",
		fields: [
			{id: "id", hidden: true},
			{id: "productInfo.productName", dataTableField: DataTableUtils.createStringColumn("product", "prodotto", true), hidden: false},
			{id: "lotInfo.lot", dataTableField: DataTableUtils.createDateColumn("lot", "Lotto", true), hidden: false},
			{id: "lotInfo.seasoning", dataTableField: DataTableUtils.createDateColumn("seasoning", "Stagionatura", true), hidden: false},
			{id: "giftDescription", dataTableField: DataTableUtils.createStringColumn("giftDescription", "Sconto disponibile", true), hidden: false},

		],
		map: (entity) => {
			entity.product =( entity.productInfo)? entity.productInfo.productName:"";
			entity.lot =( entity.lotInfo)?  entity.lotInfo.lot:"";
			entity.seasoning = (entity.lotInfo)? entity.lotInfo.seasoning:"";
			return entity;
		},
		entityLabel: "Pecorino Romano",
		managedEntity: [
			{
				entityType: "Prodotto",
				entityLabel: "Pecorino Romano"
			}
		],

		marshalCerdData: (data) => {
			let result: any = new Object();
			Object.assign(result, data);
			if (result.lotInfo && result.lotInfo.lot) result.lotInfo.lot =  moment(data.lotInfo.lot).format("DD/MM/YYYY");
			if (result.seasoning && result.lotInfo.seasoning) result.lotInfo.seasoning =  moment(data.lotInfo.seasoning).format("DD/MM/YYYY");
			return result;
		},
		resetCertificationFieldsSpec: (data?: any) => {
			let certificationFieldsSpec = [
				{fieldId: "companyName", fieldLabel: "Azienda"},
				{fieldId: "productionPlace", fieldLabel: "Luogo di produzione"},
				{fieldId: "province", fieldLabel: "Provincia provenienza"},
				
				{fieldId: "group", fieldLabel: "Rete"},
				{fieldId: "productionChain", fieldLabel: "Filiera"},
				
				{fieldId: "giftDescription", fieldLabel: "Descrizione del regalo associato"},
				{fieldId: "image", fieldLabel: "immagine_url", startValue: environment.services.mediaContentsBasePath + "/", isImage: true},

				{fieldId: "productInfo.productName", fieldLabel: "Nome prodotto"},
				{fieldId: "productInfo.producerSpecie", fieldLabel: "Specie produttrice"},
				{fieldId: "productInfo.diet", fieldLabel: "Alimentazione animale"},
				{fieldId: "productInfo.milkOrigin", fieldLabel: "Provenienza latte utilizzato"},
				
				{fieldId: "lotInfo.lot", fieldLabel: "Lotto"},
				{fieldId: "lotInfo.seasoning", fieldLabel: "Stagionatura"}
			];
			return certificationFieldsSpec;
		}
	},




	agricola_san_maurizio: {
		domainId: DOMAINS_CONFIG.agricola_san_maurizio,
		name: "Agricola San Maurizio",
		title: "Agricola San Maurizio",
		showBlockChainUrl: true,
		blockchainUrlVerify: "https://verifier.posti.world/{domain}/{id}/verify",
		landingPageUrl: "https://www.posti.world/pecorinosanmaurizio?id={id}",
		claimedFieldName: "giftDescription",
		downloadExcelEnabled: false,
		deleteEntityEnabled: true,
		certIdField: "id",
		fields: [
			{id: "id", hidden: true},
			{id: "productInfo.productName", dataTableField: DataTableUtils.createStringColumn("product", "prodotto", true), hidden: false},
			{id: "lotInfo.lot", dataTableField: DataTableUtils.createDateColumn("lot", "Lotto", true), hidden: false},
			{id: "lotInfo.packaging", dataTableField: DataTableUtils.createDateColumn("packaging", "Confezionamento", true), hidden: false},
			{id: "giftDescription", dataTableField: DataTableUtils.createStringColumn("giftDescription", "Sconto disponibile", true), hidden: false},

		],
		map: (entity) => {
			entity.product = (entity.productInfo)? entity.productInfo.productName: "";
			entity.lot = (entity.lotInfo)?entity.lotInfo.lot:"";
			entity.packaging = (entity.lotInfo)?entity.lotInfo.packaging:"";
			return entity;
		},
		entityLabel: "Pecorino di Picinisco DOP",
		managedEntity: [
			{
				entityType: "Prodotto",
				entityLabel: "Pecorino di Picinisco DOP"
			}
		],

		marshalCerdData: (data) => {
			let result: any = new Object();
			Object.assign(result, data);
			if (result.lotInfo && result.lotInfo.lot) result.lotInfo.lot =  moment(data.lotInfo.lot).format("DD/MM/YYYY");
			if (result.lotInfo && result.lotInfo.packaging) result.lotInfo.packaging =  moment(data.lotInfo.packaging).format("DD/MM/YYYY");
			return result;
		},
		resetCertificationFieldsSpec: (data?: any) => {
			let certificationFieldsSpec = [
				{fieldId: "companyName", fieldLabel: "Azienda"},
				{fieldId: "productionPlace", fieldLabel: "Luogo di produzione"},
				{fieldId: "province", fieldLabel: "Provenienza"},
				{fieldId: "group", fieldLabel: "Rete"},
				{fieldId: "giftDescription", fieldLabel: "Descrizione del regalo associato"},
				{fieldId: "image", fieldLabel: "immagine_url", startValue: environment.services.mediaContentsBasePath + "/", isImage: true},

				{fieldId: "productInfo.productName", fieldLabel: "Prodotto"},
				{fieldId: "productInfo.producerSpecie", fieldLabel: "Specie"},
				{fieldId: "productInfo.diet", fieldLabel: "Alimentazione animale"},
				{fieldId: "productInfo.seasoning", fieldLabel: "Stagionatura"},
				
				{fieldId: "lotInfo.lot", fieldLabel: "Lotto"},
				{fieldId: "lotInfo.packaging", fieldLabel: "Confezionamento"}
			];
			return certificationFieldsSpec;
		}
	},


	riso_buono: {
		domainId: DOMAINS_CONFIG.riso_buono,
		name: "Azienda Agricola Semplice Luigi e Carlo Guidobono Cavalchini",
		title: "Azienda Agricola Semplice Luigi e Carlo Guidobono Cavalchini",
		showBlockChainUrl: true,
		blockchainUrlVerify: "https://verifier.posti.world/{domain}/{id}/verify",
		landingPageUrl: "https://www.posti.world/pecorinosanmaurizio?id={id}",
		// claimedFieldName: "giftDescription",
		downloadExcelEnabled: false,
		deleteEntityEnabled: true,
		certIdField: "id",
		fields: [
			{id: "id", hidden: true},
			{id: "entityType", hidden: true},
			{id: "entityName", dataTableField: DataTableUtils.createStringColumn("entityName", "elemento", true), hidden: false},
			{id: "lotInfo.lot", dataTableField: DataTableUtils.createStringColumn("lot", "Lotto", true), hidden: false}
			
		],
		map: (entity) => {
			entity.lot = (entity.lotInfo)?entity.lotInfo.lot:"";
			return entity;
		},
		entityLabel: "Lotto",
		managedEntity: [
			{
				entityType: "Lotto",
				entityLabel: "Lotto"
			}
		],

		marshalCerdData: (data) => {
			return data;
		},
		
		entityDefinition: [
			{
				entityType: "CarnaroliClassicoGranRiserva",
				entityName: "Lotto RISO BUONO Carnaroli Classico Gran Riserva",
				landingPageUrl: "https://www.posti.world/risobuono?id={id}",
				groups: [
					{
						groupId: "companyData",
						groupLabel: "Informazioni Generali",
						fields: [
							{fieldId: "companyName", fieldLabel: "Azienda", defaultValue: "RISO BUONO - Azienda Agricola Semplice Luigi e Carlo Guidobono Cavalchini", fieldType: "STRING", validators: [CommonValidators.required]},
							{fieldId: "productionPlace", fieldLabel: "Provenienza", defaultValue: "Casalbeltrame - Novara", fieldType: "STRING", validators: [CommonValidators.required]},
							{fieldId: "address", fieldLabel: "Indirizzo", defaultValue: "Via Gautieri, 2/4", fieldType: "STRING", validators: [CommonValidators.required]},
							{fieldId: "producerName", fieldLabel: "Produttore", defaultValue: "Cristina Brizzolari - Guidobono Cavalchini", fieldType: "STRING", validators: [CommonValidators.required]}
						]
					},
					{
						groupId: "lotInfo",
						groupLabel: "Informazioni dipendenti dal lotto",
						fields: [
							{fieldId: "sowingPeriod", fieldLabel: "Periodo di semina", defaultValue: "aprile-maggio", fieldType: "STRING", validators: [CommonValidators.required]},
							{fieldId: "collectionPeriod", fieldLabel: "Periodo di raccolta", defaultValue: "settembre-ottobre", fieldType: "STRING", validators: [CommonValidators.required]},
							{fieldId: "lot", fieldLabel: "Lotto", defaultValue: null, fieldType: "STRING", validators: [CommonValidators.required,CommonValidators.noWhiteSpaceAtStardAndEnd("no spazi")]},
							{fieldId: "dryingTemperature", fieldLabel: "Temperatura di essiccazione", defaultValue: "45", fieldType: "INTEGER", validators: [CommonValidators.required]},
							{fieldId: "storagePeriod", fieldLabel: "Periodo di stoccaggio", defaultValue: "12 mesi", fieldType: "STRING", validators: [CommonValidators.required]},
							{fieldId: "processingCompany", fieldLabel: "Azienda trasformatrice della materia prima", defaultValue: "Riso Margherita s.r.l.", fieldType: "STRING", validators: [CommonValidators.required]},
							{fieldId: "processingCompanyCity", fieldLabel: "Comune", defaultValue: "Desana", fieldType: "STRING", validators: [CommonValidators.required]},
							{fieldId: "processingCompanyProvince", fieldLabel: "Provincia", defaultValue: "Vercelli", fieldType: "STRING", validators: [CommonValidators.required]}
						]
					}
				],
				resetCertificationFieldsSpec: (data?: any) => {
					let certificationFieldsSpec = [
						{fieldId: "entityName", fieldLabel: "Prodotto"},
						{fieldId: "companyData.companyName", fieldLabel: "Azienda"},
						{fieldId: "companyData.productionPlace", fieldLabel: "Provenienza"},
						{fieldId: "companyData.address", fieldLabel: "Indirizzo"},
						{fieldId: "companyData.producerName", fieldLabel: "Produttore"},
						
						{fieldId: "lotInfo.sowingPeriod", fieldLabel: "Periodo di semina"},
						{fieldId: "lotInfo.collectionPeriod", fieldLabel: "Periodo di raccolta"},
						{fieldId: "lotInfo.lot", fieldLabel: "Lotto"},
						{fieldId: "lotInfo.dryingTemperature", fieldLabel: "Temperatura di essiccazione"},
						{fieldId: "lotInfo.storagePeriod", fieldLabel: "Periodo di stoccaggio"},
						{fieldId: "lotInfo.processingCompany", fieldLabel: "Azienda trasformatrice della materia prima"},
						{fieldId: "lotInfo.processingCompanyCity", fieldLabel: "Comune"},
						{fieldId: "lotInfo.processingCompanyProvince", fieldLabel: "Provincia"}
						
					];
					return certificationFieldsSpec;
				},
			},
			{
				entityType: "ArtemideGranRiserva",
				entityName: "Lotto Riso Buono Artemide Gran Riserva",
				landingPageUrl: "https://www.posti.world/risobuonoartemide?id={id}",
				groups: [
					{
						groupId: "companyData",
						groupLabel: "Informazioni Generali",
						fields: [
							{fieldId: "companyName", fieldLabel: "Azienda", defaultValue: "RISO BUONO - Azienda Agricola Semplice Luigi e Carlo Guidobono Cavalchini", fieldType: "STRING", validators: [CommonValidators.required]},
							{fieldId: "productionPlace", fieldLabel: "Provenienza", defaultValue: "Casalbeltrame - Novara", fieldType: "STRING", validators: [CommonValidators.required]},
							{fieldId: "address", fieldLabel: "Indirizzo", defaultValue: "Via Gautieri, 2/4", fieldType: "STRING", validators: [CommonValidators.required]},
							{fieldId: "producerName", fieldLabel: "Produttore", defaultValue: "Cristina Brizzolari - Guidobono Cavalchini", fieldType: "STRING", validators: [CommonValidators.required]}
						]
					},
					{
						groupId: "lotInfo",
						groupLabel: "Informazioni dipendenti dal lotto",
						fields: [
							{fieldId: "sowingPeriod", fieldLabel: "Periodo di semina", defaultValue: "aprile-maggio", fieldType: "STRING", validators: [CommonValidators.required]},
							{fieldId: "collectionPeriod", fieldLabel: "Periodo di raccolta", defaultValue: "settembre-ottobre", fieldType: "STRING", validators: [CommonValidators.required]},
							{fieldId: "lot", fieldLabel: "Lotto", defaultValue: null, fieldType: "STRING", validators: [CommonValidators.required,CommonValidators.noWhiteSpaceAtStardAndEnd("no spazi")]},
							{fieldId: "dryingTemperature", fieldLabel: "Temperatura di essiccazione", defaultValue: "45", fieldType: "INTEGER", validators: [CommonValidators.required]},
							{fieldId: "processingCompany", fieldLabel: "Azienda trasformatrice della materia prima", defaultValue: "Greppi S.r.l.", fieldType: "STRING", validators: [CommonValidators.required]},
							{fieldId: "processingCompanyCity", fieldLabel: "Comune", defaultValue: "Tronzano Vercellese", fieldType: "STRING", validators: [CommonValidators.required]},
							{fieldId: "processingCompanyProvince", fieldLabel: "Provincia", defaultValue: "Vercelli", fieldType: "STRING", validators: [CommonValidators.required]}
						]
					}
				],
				resetCertificationFieldsSpec: (data?: any) => {
					let certificationFieldsSpec = [
						{fieldId: "entityName", fieldLabel: "Prodotto"},
						{fieldId: "companyData.companyName", fieldLabel: "Azienda"},
						{fieldId: "companyData.productionPlace", fieldLabel: "Provenienza"},
						{fieldId: "companyData.address", fieldLabel: "Indirizzo"},
						{fieldId: "companyData.producerName", fieldLabel: "Produttore"},
						
						{fieldId: "lotInfo.sowingPeriod", fieldLabel: "Periodo di semina"},
						{fieldId: "lotInfo.collectionPeriod", fieldLabel: "Periodo di raccolta"},
						{fieldId: "lotInfo.lot", fieldLabel: "Lotto"},
						{fieldId: "lotInfo.dryingTemperature", fieldLabel: "Temperatura di essiccazione"},
						{fieldId: "lotInfo.processingCompany", fieldLabel: "Azienda trasformatrice della materia prima"},
						{fieldId: "lotInfo.processingCompanyCity", fieldLabel: "Comune"},
						{fieldId: "lotInfo.processingCompanyProvince", fieldLabel: "Provincia"}
						
					];
					return certificationFieldsSpec;
				},
			}
		]
	}
}