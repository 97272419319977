<div class="form-group">
    <label [for]="id" [ngClass]="{ 'text-danger': !valid}">{{label | translate}}</label>
	<base-view-file-component
		[mediaId]="value"
		[invalid]="!valid"
		[notShowAlertOnFileDelete]="notShowAlertOnFileDelete"
		[possibileMediaTypes]="possibileMediaTypes"
		(onMediaUpdate)="updateFile($event)">
	</base-view-file-component>
    <input type="hidden"  [id]="id" class="form-control" placeholder="{{correctPlaceHolder | translate}}"
        [(ngModel)]="value" [disabled]="disabled" [ngClass]="{ 'is-invalid': !valid}" int />
    <small *ngIf="!valid" class="form-text text-muted danger"><em>{{errorMessage}}</em></small>
</div>