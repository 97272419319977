<!-- <base-page [title]="'Tables' | translate" [description]="'Componenti Tabelle' | translate"> -->
<base-page>
    <ng-container page-body>
        <div class="card">
            <div class="card-header">
                Le tue valutazioni per i Ristoranti Romanesco
            </div>
			<div class="card-header">
				<div class="row">
					<div class="col-6">
						<span class="badge badge-success">{{numberOfAvailableItems}}</span> Ristoranti trovati
						<button type="button" (click)="downloadExcel()"  class="btn btn-primary">
							<i class="fas fa-file-excel"></i>&nbsp;scarica Excel
						</button>
					</div>
					<div class="col-6 text-right">
						inserisci la tua chiave privata per effettuare le valutazioni<br>
						<input type="password" size="40" [(ngModel)]="privateKey">
					</div>
				</div>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <list-table [columns]="tableColumns" [manager]="dataTableManager" [actions]="tableActions">
                            </list-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		
    </ng-container>
</base-page>