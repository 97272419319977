<div class="row">
    <div class="col-12">
        <div class="content-header">{{title}}</div>
        <p class="content-sub-header mb-1" *ngIf="description">{{description}}</p>
    </div>
</div>
<section>
    <div class="row">
        <div class="col-12">
            <ng-content select="[page-body]"></ng-content>
        </div>
    </div>
</section>