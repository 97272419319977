<base-page>
    <ng-container page-body>
        <div class="card form-card">
            <form [formGroup]="entityForm" *ngIf="entityForm">
                <aw-wizard>
                    <aw-wizard-step stepTitle="Dati Generali">
                        <div class="card-header logo-form" style="background-color: white;">
                            <img class="" [src]="logoImage">
                        </div>
                        <div class="card-content form-content">
                            <div class="card-body">
                                <div class="row formContent">
                                    <div class="col-12">
                                        <div class="subtitle-form">
                                            <h3>Informazioni Generali</h3>
                                        </div>
										<div class="row">
											<div class="col-12">
												<input-string formControlName="companyName" label="Azienda"></input-string>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<input-string formControlName="productionPlace" label="Provenienza"></input-string>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<input-string formControlName="address" label="Indirizzo"></input-string>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<input-string formControlName="producerName" label="Produttore"></input-string>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<select-box formControlName="product" label="Prodotto" [items]="products" [multiple]="false">
												</select-box>
											</div>
										</div>

										
                                        
										<div class="subtitle-form">
											<h3>
												Informazioni generiche dipendenti dal lotto
											</h3>
										</div>
                                        <form [formGroup]="lotInfo">
											<div class="row">
												<div class="col-6">
													
													<input-string formControlName="sowingPeriod" label="Periodo di semina"></input-string>
												</div>
												<div class="col-6">
													<input-string formControlName="collectionPeriod" label="Periodo di raccolta"></input-string>
												</div>
												
											</div>
											
											<div class="row">
												<div class="col-12">
													<input-string formControlName="lot" label="Lotto"></input-string>
												</div>
											</div>
											<div class="row">
												<div class="col-12">
													<input-int formControlName="dryingTemperature" label="Temperatura di essiccazione"></input-int>
												</div>
											</div>
											<div class="row">
												<div class="col-12">
													<input-string formControlName="storagePeriod" label="Periodo di stoccaggio"></input-string>
												</div>
											</div>
											<div class="row">
												<div class="col-12">
													<input-string formControlName="processingCompany" label="Azienda trasformatrice della materia prima"></input-string>
												</div>
											</div>
											<div class="row">
												<div class="col-12">
													<input-string formControlName="processingCompanyCity" label="Comune"></input-string>
												</div>
											</div>
											<div class="row">
												<div class="col-12">
													<input-string formControlName="processingCompanyProvince" label="Provincia"></input-string>
												</div>
											</div>
                                        </form>
										
										<div class="subtitle-form">
                                            <h3>chiavi di sicurezza</h3>
                                        </div>
                                        <form [formGroup]="walletForm">
                                            <div class="row">
                                                <div class="col-12 ">
                                                    <input-string formControlName="secret" label="Chiave privata del tuo wallet"></input-string>
                                                </div>
                                            </div>
											
                                        </form>
                                        <button type="button" class="wizardBtn" awPreviousStep>INDIETRO</button>
                                        <button id="btnNext1" type="button" class="wizardBtn" [disabled]="!canSaveForm" (click)="save()" >SALVA</button>
                                        <br>
                                        <div *ngIf="!canSaveForm" class="subtitle-form">
                                            <p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aw-wizard-step>

                    
                </aw-wizard>
            </form>
        </div>
    </ng-container>
</base-page>