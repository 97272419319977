<base-page>
    <ng-container page-body>
        <div class="card form-card">
            <form [formGroup]="restaurantInfo" *ngIf="restaurantInfo">
                <aw-wizard>
                    <aw-wizard-step stepTitle="Dati Generali" *ngIf="isMerchant()">
                        <div class="card-header logo-form">
                            <img class="" [src]="logoImage">
                        </div>
                        <div class="card-content form-content">
                            <div class="card-body">
                                <div class="row formContent">
                                    <div class="col-12">
                                        <div class="subtitle-form">
                                            <h3>{{'formContactLabels.contactsTitle' | translate}}</h3>
                                        </div>
                                        <form [formGroup]="contactInfo">
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="firstName" label="{{'formContactLabels.firstName' | translate}}"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="lastName" label="{{'formContactLabels.lastName' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row ">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="phone" label="{{'formContactLabels.phone' | translate}}" placeHolder="{{'formPlaceholder.phone' | translate}}"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="email" label="{{'formContactLabels.email' | translate}}" placeHolder="{{'formPlaceholder.email' | translate}}"></input-string>
                                                </div>
                                            </div>
                                        </form>
                                        <div class="subtitle-form">
                                            <h3>{{'formLabels.title' | translate}}</h3>
                                        </div>
                                        <form [formGroup]="restaurantInfo">
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-file formControlName="logo" [possibileMediaTypes]="possibileMediaTypes" label="{{'deliveryLabel.logo' | translate}}"></input-file>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-2 col-md-2 col-sm-12">
                                                    <input-slide formControlName="fipePartner" label=formLabels.fipePartner [horizontal]="false">
                                                    </input-slide>
                                                </div>
                                                <div *ngIf="restaurantInfo && restaurantInfo.value.fipePartner" class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="fipeCardNumber" label=formLabels.fipeCardNumber placeHolder=formPlaceholder.fipeCardNumber></input-string>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="businessName" label="{{'formLabels.businessName' | translate}}*"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-122">
                                                    <input-string formControlName="sign" label="{{'formLabels.sign' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <!-- <div class="row">
                                                <div class="col-12">
													<input-string formControlName="sdiOrPec" label="SDI/PEC per la fatturazione*">
													</input-string>
                                                </div>
                                            </div> -->
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="vatNumber" label="{{'formLabels.vatNumber' | translate}}*"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="representative" label="{{'formLabels.representative' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="address" label="{{'formLabels.address' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="zipCode" label="{{'formLabels.zipCode' | translate}}"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="city" label="{{'formLabels.city' | translate}}"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="province" label="{{'formLabels.province' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="phone" label="{{'formLabels.phone' | translate}}" placeHolder="{{'formPlaceholder.phone' | translate}}"></input-string>
                                                </div>

                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="mobilePhone" label="{{'formLabels.mobilePhone' | translate}}" placeHolder="{{'formPlaceholder.phone' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="email" label="{{'formLabels.email' | translate}}*" placeHolder="{{'formPlaceholder.email' | translate}}"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="website" label="{{'formLabels.website' | translate}}" placeHolder="{{'formPlaceholder.website' | translate}}"></input-string>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="socialFacebook" label="{{'formLabels.socialFacebook' | translate}}" placeHolder="{{'formPlaceholder.socialFacebook' | translate}}"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="socialInstagram" label="{{'formLabels.socialInstagram' | translate}}" placeHolder="{{'formPlaceholder.socialInstagram' | translate}}"></input-string>
                                                </div>
                                                <!-- <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="socialTikTok" label="{{'formLabels.socialTikTok' | translate}}" placeHolder="{{'formPlaceholder.socialTikTok' | translate}}"></input-string>
                                                </div> -->
                                            </div>

                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="description" label="{{'formLabels.description' | translate}}" placeHolder="{{'formPlaceholder.description' | translate}}" [rows]="5"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="ownerStory" label="{{'formLabels.ownerStory' | translate}}" placeHolder="{{'formPlaceholder.ownerStory' | translate}}" [rows]="5"></input-string>
                                                </div>
                                            </div>
                                            <div class="subtitle-form">
                                                <h3>{{'infoLabels.utilTitle' | translate}}</h3>
                                            </div>
                                        </form>
                                        <form [formGroup]="usefullInfo" class="my-4">
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="openingTime" label="{{'infoLabels.openingTime' | translate}}*" placeHolder="{{'formPlaceholder.openingTime' | translate}}"></input-string>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="weeklyClosing" label="{{'infoLabels.weeklyClosing' | translate}}*" placeholder="{{'formPlaceholder.weeklyClosing' | translate}}"></input-string>

                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="annualClosing" label="{{'infoLabels.annualClosing' | translate}}*" placeholder="{{'formPlaceholder.annualClosing' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="creditCards" label="{{'infoLabels.creditCards' | translate}}" placeholder="{{'formPlaceholder.creditCards' | translate}}"></input-string>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <select-box formControlName="accessibility" label="{{'infoLabels.accessibility' | translate}}" [items]="selectItemsAcc" [multiple]="true">
                                                    </select-box>
                                                </div>
                                                <div class="col-12">
                                                    <input-slide formControlName="reservation" label="{{'infoLabels.reservation' | translate}}" [horizontal]="true">
                                                    </input-slide>
                                                </div>
                                                <div class="col-12">
                                                    <input-slide formControlName="parking" label="{{'infoLabels.parking' | translate}}" [horizontal]="true">
                                                    </input-slide>
                                                </div>
                                                <div class="col-12">
                                                    <input-slide formControlName="petsAllowed" label=infoLabels.petsAllowed [horizontal]="true">
                                                    </input-slide>
                                                </div>
                                                <div class="col-12">
                                                    <input-slide formControlName="wifi" label="{{'infoLabels.wifi' | translate}}" [horizontal]="true"> </input-slide>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-int formControlName="internalSeats" label="{{'infoLabels.internalSeats' | translate}}"></input-int>
                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-int formControlName="externalSeats" label="{{'infoLabels.externalSeats' | translate}}"></input-int>
                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="kitchenType" label="{{'infoLabels.kitchenType' | translate}}*" placeholder="{{'formPlaceholder.kitchenType' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <select-box formControlName="specialDietsDishes" label="{{'infoLabels.specialDietsDishes' | translate}}" [items]="selectItemsProd" [multiple]="true">
                                                    </select-box>
                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-slide formControlName="specialProductsUsage" label="{{'infoLabels.specialProductsUsage' | translate}}" [horizontal]="false"> </input-slide>

                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="priceRange" label="{{'infoLabels.priceRange' | translate}}" placeHolder="{{'formPlaceholder.priceRange' | translate}}"></input-string>
                                                </div>
                                            </div>
                                        </form>



										<form [formGroup]="sectionCheck" class="my-4" *ngIf="isAdmin()">
											<div class="subtitle-form">
                                                <h3>Verifica conformità FIPE</h3>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-slide formControlName="firstCardCheck" label="La scheda è conforme ai criteri del progetto?" [horizontal]="false"> </input-slide>
                                                    
                                                </div>
                                               
                                            </div>
                                        </form>




                                        <button type="button" class="wizardBtn" awPreviousStep>INDIETRO</button>
                                        <button id="btnNext1" type="button" class="wizardBtn" [disabled]="!stepOneValid" awNextStep>AVANTI</button>
                                        <br>
                                        <div *ngIf="!stepOneValid" class="subtitle-form">
                                            <p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aw-wizard-step>

                    <aw-wizard-step *ngIf="traditional" stepTitle="Menù"> 
                        <div class="card-content form-content">
                            <div class="card-body">
                                <div class="row formContent">
                                    <div class="col-12" >
                                        <div class="subtitle-form" *ngIf="isMerchant()">
                                            <!-- <h3>{{'menuLabel.title' | translate}}</h3> -->
                                            <h3>CUCINA TIPICA ROMANA</h3>

                                        </div>
                                        <form [formGroup]="menuInfo" *ngIf="isMerchant()">
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-file formControlName="menuImage" [possibileMediaTypes]="possibileMediaTypes" label="menuLabel.menuImage"></input-file>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <p style="color: white; font-size: x-large;">Per ogni tipologia di pietanza, selezionare una o più voci presenti in elenco.</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <select-box formControlName="appetizers" label="menuLabel.appetizers" [items]="selectItemsAppetizers" [multiple]="true">
                                                    </select-box>
                                                </div>
                                                <div class="col-6">
                                                    <input-tags class="tagStyle" formControlName="appetizersOther" label="Altri Antipasti non in elenco"></input-tags>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <select-box formControlName="pastaAndSoups" label="menuLabel.pastaAndSoups" [items]="selectItemsPastaAndSoups" [multiple]="true">
                                                    </select-box>
                                                </div>
                                                <div class="col-6">
                                                    <input-tags class="tagStyle" formControlName="pastaAndSoupsOther" label="Altre paste/minestre non in elenco"></input-tags>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <select-box formControlName="mainCourses" label="menuLabel.mainCourses" [items]="selectItemsMainCourses" [multiple]="true">
                                                    </select-box>
                                                </div>
                                                <div class="col-6">
                                                    <input-tags class="tagStyle" formControlName="mainCoursesOther" label="Altri Secondi di carne/Secondi di pesce non in elenco"></input-tags>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <select-box formControlName="sideDishes" label="menuLabel.sideDishes" [items]="selectItemsSideDishes" [multiple]="true">
                                                    </select-box>
                                                </div>
                                                <div class="col-6">
                                                    <input-tags class="tagStyle" formControlName="sideDishesOther" label="Altri contorni non in elenco"></input-tags>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <select-box formControlName="dessertsAndCheeses" label="menuLabel.dessertsAndCheeses" [items]="selectItemsDessertsAndCheeses" [multiple]="true">
                                                    </select-box>
                                                </div>
                                                <div class="col-6">
                                                    <input-tags class="tagStyle" formControlName="dessertsAndCheesesOther" label="Altri dolci e formaggi non in elenco"></input-tags>
                                                </div>
                                            </div>
                                        </form>

                                        <div class="subtitle-form" *ngIf="isMerchant()">
                                            <h3>{{'productsLabel.title' | translate}}</h3>
                                        </div>
                                        <form [formGroup]="restaurantInfo.get('productInfo')" *ngIf="isMerchant()">
                                            <div class="row">
                                                <div class="col-6">
                                                    <select-box formControlName="products" label="productsLabel.products" [items]="selectItemsProducts" [multiple]="true">
                                                    </select-box>
                                                </div>

                                                <div class="col-6">
                                                    <input-tags class="tagStyle" formControlName="additionalProducts" label="Altri prodotti non in elenco"></input-tags>
                                                </div>
                                            </div>

                                        </form>

                                        <form class="recipeForm" [formGroup]="restaurantInfo.get('suggestedAndRecipe')">

                                            <div class="subtitle-form">
                                                <h3>{{'suggestedAndRecipeLabel.titleRecipe' | translate}}</h3>
                                                <p>{{'suggestedAndRecipeLabel.subTitleRecipe' | translate}}  Ricorda, inoltre, che le i dati che fornirai diverranno il tuo biglietto da visita nei confronti della commissione di valutazione.</p>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-file formControlName="imageSuggested" [possibileMediaTypes]="possibileMediaTypes" label="Foto della ricetta*"></input-file>
                                                </div>
                                            </div>


                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="name" label="suggestedAndRecipeLabel.name"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="chefName" label="Autore*"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="ingredients" label="suggestedAndRecipeLabel.ingredients" [rows]="5"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="suggestedByChef" label="La preparazione e il tocco dello chef*" [rows]="5"></input-string>
                                                </div>
                                            </div>

                                            <div class="subtitle-form" *ngIf="isMerchant()">
                                                <h3>{{'suggestedAndRecipeLabel.titleSuggested' | translate}}</h3>
                                                <p>{{'suggestedAndRecipeLabel.subTitleSuggested' | translate}} (anche solo un antipasto, un primo, etc..)</p>
                                            </div>
											<div *ngIf="isMerchant()">
												<div class="row">
													<div class="col-12">
														<input-string formControlName="appetizer" label="suggestedAndRecipeLabel.appetizer"></input-string>
													</div>
												</div>
												<div class="row">
													<div class="col-12">
														<input-string formControlName="firstDish" label="suggestedAndRecipeLabel.firstDish"></input-string>
													</div>
												</div>
												<div class="row">
													<div class="col-12">
														<input-string formControlName="secondDish" label="suggestedAndRecipeLabel.secondDish"></input-string>
													</div>
												</div>
												<div class="row">
													<div class="col-12">
														<input-string formControlName="dessert" label="suggestedAndRecipeLabel.dessert"></input-string>
													</div>
												</div>
											</div>
                                        </form>
                                        <a href="" class="my-4">
											La valutazione dei ristoranti tipici romani sarà effettuata sulla base del disciplinare (clicca qui)
                                            <!-- Prima di proseguire, clicca qui per leggere il disciplinare -->
                                        </a>
                                        <br><br>
                                        <div *ngIf="traditional && isMerchant()" class="subtitle-form">
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-slide formControlName="providesData" label="vuoi inserire ulteriori informazioni su vini, oli, pani, ecc.?" [horizontal]="true">
                                                    </input-slide>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="restaurantInfo && restaurantInfo.value.providesData && isMerchant()">

                                            <div class="subtitle-form">
                                                <h3>{{'wineLabel.title' | translate}}</h3>
                                            </div>
                                            <form [formGroup]="restaurantInfo.get('wineInfo')">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <input-string formControlName="docgWines" label="wineLabel.docgWines"></input-string>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <input-string formControlName="docWines" label="wineLabel.docWines"></input-string>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <input-string formControlName="igtWines" label="wineLabel.igtWines"></input-string>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <input-string formControlName="houseWine" label=wineLabel.houseWine></input-string>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <input-file formControlName="wineListImage" [possibileMediaTypes]="possibileMediaTypes" label="wineLabel.wineListImage"></input-file>
                                                    </div>
                                                </div>
                                            </form>

                                            <div class="subtitle-form">
                                                <h3>{{'oilLabel.title' | translate}}</h3>
                                            </div>
                                            <form [formGroup]="restaurantInfo.get('oilInfo')">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <input-tags class="tagStyle" formControlName="oils" label="Inserire gli oli disponibili. Premere invio per confermare."></input-tags>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <input-file formControlName="oilsListImage" [possibileMediaTypes]="possibileMediaTypes" label="oilLabel.oilsListImage"></input-file>
                                                    </div>
                                                </div>
                                            </form>
                                            <div class="subtitle-form">
                                                <h3>{{'breadLabel.title' | translate}}</h3>
                                            </div>
                                            <form [formGroup]="restaurantInfo.get('breadInfo')">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <input-tags class="tagStyle" formControlName="breads" label="Inserire i vari tipi di pane disponibili. Premere invio per confermare."></input-tags>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <input-file formControlName="breadsListImage" [possibileMediaTypes]="possibileMediaTypes" label="breadLabel.breadsListImage"></input-file>
                                                    </div>
                                                </div>
                                            </form>
											

                                        </div>
										<form [formGroup]="sectionCheck" *ngIf="isAdmin()" class="my-4">
											<div class="subtitle-form">
												<h3>Verifica conformità FIPE</h3>
											</div>
											<div class="row">
												<div class="col-12 col-lg-4 col-md-4 col-sm-12">
													<input-slide formControlName="secondCardCheck" label="La scheda è conforme ai criteri del progetto?" [horizontal]="false"> </input-slide>
													
												</div>
											   
											</div>
										</form>

                                        <button type="button" class="wizardBtn" awPreviousStep>INDIETRO</button>
                                        <button id="btnNext2" type="button" class="wizardBtn" [disabled]="!stepTwoValid" awNextStep>AVANTI</button>
                                        <div *ngIf="!stepTwoValid" class="subtitle-form">
                                            <p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aw-wizard-step>

                    <aw-wizard-step stepTitle="Asporto e Delivery" *ngIf="isMerchant()">
                        <div class="card-content form-content">
                            <div class="card-body">
                                <div class="row formContent">
                                    <div class="col-12">
                                        <div class="subtitle-form">
                                            <h3>{{'deliveryLabel.title' | translate}}</h3>
                                        </div>
                                        <form [formGroup]="deliveryInfo">
                                            <div class="row" *ngIf="traditional">
                                                <div class="col-12 col-lg-3 col-md-3 col-sm-12">
                                                    <input-slide formControlName="sameMenu" label="deliveryLabel.sameMenu" [horizontal]="false">
                                                    </input-slide>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="(traditional && deliveryInfo && deliveryInfo.value.sameMenu) || !traditional">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <form [formGroup]="restaurantInfo.get('deliveryMenu')" class="my-4">

                                                        <input-file formControlName="menuImage" [possibileMediaTypes]="possibileMediaTypes" label="Menù specifico per il delivery (PDF, DOC, IMMAGINI)"></input-file>

                                                    </form>

                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <select-box formControlName="paymentType" label="{{'deliveryLabel.paymentType' | translate}}" [items]="selectItemsPayment" [multiple]="true">
                                                    </select-box>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <select-box formControlName="menuType" label="{{'deliveryLabel.menuType' | translate}}" [items]="selectItemsMenuType" [multiple]="true">
                                                    </select-box>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="deliveryDays" label="{{'deliveryLabel.deliveryDays' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row ">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="lunchDeliveryHours" label="{{'deliveryLabel.lunchDeliveryHours' | translate}}"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="dinnerDeliveryHours" label="{{'deliveryLabel.dinnerDeliveryHours' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row ">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="deliverySolutionsTimeInterval" label="{{'deliveryLabel.deliverySolutionsTimeInterval' | translate}}"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="orderRestrictions" label="{{'deliveryLabel.orderRestrictions' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="menuSectionsToExclude" label="{{'deliveryLabel.menuSectionsToExclude' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="notes" label="{{'deliveryLabel.notes' | translate}}" [rows]="5"></input-string>
                                                </div>
                                            </div>

                                        </form>
										<form [formGroup]="sectionCheck" class="my-4" *ngIf="isAdmin()">
											<div class="subtitle-form">
												<h3>Verifica conformità FIPE</h3>
											</div>
											<div class="row">
												<div class="col-12 col-lg-4 col-md-4 col-sm-12">
													<input-slide formControlName="thirdCardCheck" label="La scheda è conforme ai criteri del progetto?" [horizontal]="false"> </input-slide>
													
												</div>
											   
											</div>
										</form>
                                        <button type="button" awPreviousStep class="wizardBtn">INDIETRO</button>
                                        <button id="btnNext3" type="button" class="wizardBtn" [disabled]="!stepThreeValid" awNextStep>AVANTI</button>
                                        

                                        <div *ngIf="!stepThreeValid" class="subtitle-form">
                                            <p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aw-wizard-step>

					<aw-wizard-step stepTitle="Validazione" *ngIf="isAdmin()">
                        <div class="card-content form-content">
                            <div class="card-body">
                                <div class="row formContent">
                                    <div class="col-12">
                                        <div class="subtitle-form">
                                            <h3>Valutazione complessiva</h3>
                                        </div>
                                        <form [formGroup]="evaluation">
                                            <div class="row">
                                                <div class="col-12 col-lg-3 col-md-3 col-sm-12">
													<input-date formControlName="evaluationDate" label="data di validazione"></input-date>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-3">
													<select-box formControlName="evaluationOil"  label="romanità oli" [items]="[1,2,3,4,5]" [multiple]="false"></select-box>
                                                </div>
                                                <div class="col-3">
													<select-box formControlName="evaluationBred"  label="romanità pane" [items]="[1,2,3,4,5]" [multiple]="false"></select-box>
                                                </div>
                                                <div class="col-3">
													<select-box formControlName="useTipicalProduct"  label="impiego di prodotti tipici romani" [items]="[1,2,3,4,5,6,7,8,9,10]" [multiple]="false"></select-box>
                                                </div>
												<div class="col-3">
													<select-box formControlName="tipicalMenu"  label="romanità menù tipico" [items]="[1,2,3,4,5,6,7,8,9,10]" [multiple]="false"></select-box>
                                                </div>
                                            </div>
                                            <div class="row">
                                               
                                                <div class="col-3">
													<select-box formControlName="evaluationStarter"  label="romanità antipasti" [items]="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]" [multiple]="false"></select-box>
                                                </div>
                                                <div class="col-3">
													<select-box formControlName="evaluationFirstAndSoup"  label="romanità primi e minestre" [items]="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]" [multiple]="false"></select-box>
                                                </div>
												<div class="col-3">
													<select-box formControlName="evaluationSeconds"  label="romanità secondi" [items]="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25]" [multiple]="false"></select-box>
                                                </div>
                                                <div class="col-3">
													<select-box formControlName="evaluationSide"  label="romanità contorni" [items]="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]" [multiple]="false"></select-box>
                                                </div>
                                            </div>

											<div class="row">
                                               <div class="col-3">
													<select-box formControlName="evaluationWine"  label="romanità Vini" [items]="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]" [multiple]="false"></select-box>
													
											   </div>
                                                <div class="col-3">
													<select-box formControlName="evaluationDessert"  label="romanità dessert e formaggi" [items]="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]" [multiple]="false"></select-box>
                                                </div>
                                                <div class="col-3">
													<select-box formControlName="evaluationMenu"  label="romanità ricetta" [items]="[1,2,3,4,5,6,7,8,9,10]" [multiple]="false"></select-box>
                                                </div>
                                               
                                            </div>
											<div class="row">
                                               
                                                <div class="col-6">
													<input-int [disabled]="true" formControlName="overallEvaluation"  label="ranking totale"></input-int>
													<!-- <select-box [items]="[1,2,3,4,5,6,7,8,9,10]" [multiple]="false"></select-box> -->
                                                </div>
												
                                            </div>
											<div class="row">
                                               
                                                
												<div class="col-6">
													<input-string formControlName="companyEvaluation"  label="descrizione della valutazione dell'esercizio" rows="3"></input-string>
													<!-- <select-box formControlName="companyEvaluation"  label="descrizione della valutazione dell'esercizio" [items]="[1,2,3,4,5,6,7,8,9,10]" [multiple]="false"></select-box> -->
                                                </div>
                                            </div>
											
										</form>
										
                                        <button type="button" awPreviousStep class="wizardBtn">INDIETRO</button>
                                        <button id="btnNext4" type="button" class="wizardBtn" [disabled]="!stepThreeValid" awNextStep>AVANTI</button>

                                        <div *ngIf="!stepThreeValid" class="subtitle-form">
                                            <p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aw-wizard-step>


					<aw-wizard-step stepTitle="wallet">
                        <div class="card-content form-content">
                            <div class="card-body">
                                <div class="row formContent">
                                    <div class="col-12">
                                       
										<div class="subtitle-form">
                                            <h3>Informazioni del tuo wallet</h3>
                                        </div>
										<form [formGroup]="walletForm">
                                            <div class="row">
                                                <div class="col-12 ">
                                                    <input-string formControlName="secret" label="Chiave privata del tuo wallet*"></input-string>
                                                </div>
                                            </div>
											
                                        </form>
                                        <button type="button" awPreviousStep class="wizardBtn">INDIETRO</button>
                                        <button type="button"  *ngIf="isMerchant()" (click)="save(false)" class="wizardBtn btn form-submit" [disabled]="!stepFourNoCertValid" id="btnNext3">Salva</button>
                                        <button type="button" (click)="save(true)" class="wizardBtn btn form-submit" [disabled]="!steFourValid" id="btnNext3">Salva e certifica dati</button>

                                        <div *ngIf="!steFourValid" class="subtitle-form">
                                            <p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aw-wizard-step>

                </aw-wizard>
            </form>
        </div>
    </ng-container>
</base-page>