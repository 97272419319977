<modal [title]="title" (onClose)="close()" headerBgColor="gradient-man-of-steel">
	<div body class="row">
		<div class="col-12">
			<form [formGroup]="userEvaluation" *ngIf="userEvaluation">
				<div class="row">
					<div class="col-12">
						<input-int formControlName="indiceQualitativo"  label="indice di romanità qualitativa"></input-int>
					</div>
					<div class="col-12">
						<input-slide formControlName="directExperience" label="ho visitato il ristorante" [horizontal]="false">
						</input-slide>
					</div>
					<div class="col-12">
						<input-slide formControlName="evaluationValidated" label="ho validato il processo di valutazione" [horizontal]="false">
						</input-slide>
					</div>
				</div>
				<div class="row">
				   <div class="col-12">
						<input-string formControlName="evaluationDescription"  label="Giudizio descrittivo" rows="4"></input-string>
					</div>
				</div>
			</form>
		</div>
	</div>
    <div class="text-right" footer *ngIf="evaluation">
		<button type="button" (click)="close()" class="btn btn-outline-secondary"><i class="{{iconCancel}}"></i> {{'common.cancelButtonLabel' | translate}}</button>
		<button type="button" (click)="save()" [disabled]="!evaluation.valid || !walletForm.valid" class="btn btn-primary"><i class="{{iconSave}}"></i> {{'common.saveButtonLabel' | translate}}</button>
    </div>
</modal>
