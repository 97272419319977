<base-page>
    <ng-container page-body>
        <!-- <form [formGroup]="form" *ngIf="form"> -->
            <div class="card form-card">
                <div class="card-header logo-form">
                    <img class="" [src]="logoImage">
                </div>
                <div class="card-content form-content pecora-nera-form-content" *ngIf="restaurantInfo">
                    <div class="card-body">
                        <div class="row formContent">
                            <div class="col-12">
                                <aw-wizard>
                                    <aw-wizard-step stepTitle="Dati Generali del ristorante">


                                        <div class="subtitle-form">
                                            <h3>{{'formLabels.title' | translate}}</h3>
                                        </div>
                                        <form [formGroup]="restaurantInfo">
                                            <div class="row">
                                                <div class="col-12">
													<select-box formControlName="guide" label="Guida*" [items]="guides" [multiple]="false">
                                                    </select-box>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="sign" label="{{'formLabels.sign' | translate}}"></input-string>
                                                </div>

                                            </div>
											<div class="row">
                                                <div class="col-6">
                                                    <input-slide formControlName="presentInTheOldEdition" label="già presente nell’edizione precedente della guida" [horizontal]="false">
                                                    </input-slide>
                                                </div>
                                               
                                            </div>
                                            
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="address" label="{{'formLabels.address' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="zipCode" label="{{'formLabels.zipCode' | translate}}"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="city" label="{{'formLabels.city' | translate}}"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="province" label="{{'formLabels.province' | translate}}"></input-string>
                                                </div>
                                            </div>
											<!-- <div class="subtitle-form">
												<h3>Altri dati del Locale</h3>
											</div>
											<div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="businessName" label="{{'formLabels.businessName' | translate}}">
                                                    </input-string>
                                                </div>
												<div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="vatNumber" label="{{'formLabels.vatNumber' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="lat" label="Latitudine"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="lon" label="Longitudine"></input-string>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="phone" label="{{'formLabels.phone' | translate}}" placeHolder="06 3333...">
                                                    </input-string>
                                                </div>

                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="mobilePhone" label="{{'formLabels.mobilePhone' | translate}}" placeHolder="06 3333...">
                                                    </input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="email" label="{{'formLabels.email' | translate}}" placeHolder="{{'formPlaceholder.email' | translate}}">
                                                    </input-string>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="website" label="{{'formLabels.website' | translate}}" placeHolder="{{'formPlaceholder.website' | translate}}">
                                                    </input-string>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="socialFacebook" label="{{'formLabels.socialFacebook' | translate}}" placeHolder="{{'formPlaceholder.socialFacebook' | translate}}">
                                                    </input-string>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="socialInstagram" label="{{'formLabels.socialInstagram' | translate}}" placeHolder="{{'formPlaceholder.socialInstagram' | translate}}">
                                                    </input-string>
                                                </div>

                                            </div>


                                            <div class="subtitle-form">
                                                <h3>{{'infoLabels.utilTitle' | translate}}</h3>
                                            </div> -->
                                        </form>
                                        <!-- <form [formGroup]="usefullInfo" class="my-4">
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="openingTime" label="{{'infoLabels.openingTime' | translate}}" placeHolder="{{'formPlaceholder.openingTime' | translate}}">
                                                    </input-string>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="weeklyClosing" label="{{'infoLabels.weeklyClosing' | translate}}" placeHolder="{{'formPlaceholder.weeklyClosing' | translate}}">
                                                    </input-string>

                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="annualClosing" label="{{'infoLabels.annualClosing' | translate}}" placeHolder="{{'formPlaceholder.annualClosing' | translate}}">
                                                    </input-string>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-12">
                                                    <select-box formControlName="accessibility" label="{{'infoLabels.accessibility' | translate}}" [items]="selectItemsAcc" [multiple]="true">
                                                    </select-box>
                                                </div>

                                            </div>
                                            <div class="row">
                                                
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <select-box formControlName="specialDietsDishes" label="{{'infoLabels.specialDietsDishes' | translate}}" [items]="selectItemsProd" [multiple]="true">
                                                    </select-box>
                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="kitchenType" label="{{'infoLabels.kitchenType' | translate}}" placeHolder="{{'formPlaceholder.kitchenType' | translate}}">
                                                    </input-string>
                                                </div>
                                            </div>
                                            <div class="row">
												<div class="col-12 col-lg-4 col-md-4 col-sm-12">
													<input-slide formControlName="externalSeats" label="{{'pecoraNeraLabel.externalSeats' | translate}}" [horizontal]="false"> </input-slide>
                                                </div>
                                                
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-slide formControlName="specialProductsUsage" label="{{'infoLabels.specialProductsUsage' | translate}}" [horizontal]="false"> </input-slide>

                                                </div>

                                            </div>
                                        </form> -->

                                        <button id="btnNext2" type="button" class="wizardBtn" [disabled]="!stepOneValid" awNextStep>Avanti</button>
                                        <br>
                                        <div *ngIf="!stepTwoValid" class="subtitle-form">
                                            <p class="stepControl">Controlla di aver inserito tutti i campi obbligatori
                                            </p>
                                        </div>
                                    </aw-wizard-step>


                                    <aw-wizard-step stepTitle="Recensione del ristorante">
										<div *ngFor="let evaluation of restaurantInfo.get('evaluations').controls; let i = index">
											<div class="row">
												<div class="col-6 subtitle-form">
													<h3>Recensione del ristorante</h3>
												</div>
												<div class="col-6 text-right">
													<button type="button" (click)="removeEvaluation(i)"  class="wizardBtn">
														<!-- <i class="{{iconAdd}}"></i> -->
														Rimuovi la valutazione
													</button>
												</div>
											</div>

											
											<form [formGroup]="evaluation">
												<div class="row">
													<div class="col-3">
														<input-string formControlName="firstName" label="Nome del valutatore*">
														</input-string>
													</div>
	
													<div class="col-3">
														<input-string formControlName="lastName" label="Cognome del valutatore*">
														</input-string>
													</div>
													<div class="col-3">
														<input-date formControlName="visitDate" label="Data della visita*">
														</input-date>
													</div>
													<div class="col-3">
														<input-slide formControlName="removedFromBlockChain" label="recensione NON in block chain" [horizontal]="false">
                                                    	</input-slide>
													</div>
													
												</div>
												<div class="row">
												   
	
													<div class="col-12 col-lg-6 col-md-6 col-sm-12">
														<select-box formControlName="testedFor" label="Provato per*" [items]="selectItemsTestedFor" [multiple]="false">
														</select-box>
													</div>
	
													<!-- <div class="col-12 col-lg-6 col-md-6 col-sm-12">
														<select-box formControlName="proposedEvaluation" label="Voto proposto" [items]="selectItemsProposedEvaluation" [multiple]="false">
														</select-box>
													</div> -->
												</div>
												
												<div class="row">
													<div class="col-12">
														<input-file formControlName="receiptPhoto" [possibileMediaTypes]="possibileMediaTypes" label="foto dello scontrino"></input-file>
													</div>
												</div>
												<div class="row">
													<div class="col-12 col-lg-2 col-md-6 col-sm-12">
														<input-file formControlName="firstDish" [possibileMediaTypes]="possibileMediaTypes" label="piatto 1">
														</input-file>
													</div>
													<div class="col-12 col-lg-2 col-md-6 col-sm-12">
														<input-file formControlName="secondDish" [possibileMediaTypes]="possibileMediaTypes" label="piatto 2">
														</input-file>
													</div>
													<div class="col-12 col-lg-2 col-md-6 col-sm-12">
														<input-file formControlName="thirdDish" [possibileMediaTypes]="possibileMediaTypes" label="piatto 3">
														</input-file>
													</div>
													<div class="col-12 col-lg-2 col-md-6 col-sm-12">
														<input-file formControlName="fourthDish" [possibileMediaTypes]="possibileMediaTypes" label="piatto 4">
														</input-file>
													</div>
													<div class="col-12 col-lg-2 col-md-6 col-sm-12">
														<input-file formControlName="fifthDish" [possibileMediaTypes]="possibileMediaTypes" label="piatto 5">
														</input-file>
													</div>
												</div>
												<div class="row">
													<div class="col-12 col-lg-2 col-md-6 col-sm-12">
														<input-file formControlName="sixtDish" [possibileMediaTypes]="possibileMediaTypes" label="piatto 6">
														</input-file>
													</div>
													<div class="col-12 col-lg-2 col-md-6 col-sm-12">
														<input-file formControlName="sevenDish" [possibileMediaTypes]="possibileMediaTypes" label="piatto 7">
														</input-file>
													</div>
													<div class="col-12 col-lg-2 col-md-6 col-sm-12">
														<input-file formControlName="eighthDish" [possibileMediaTypes]="possibileMediaTypes" label="piatto 8">
														</input-file>
													</div>
													<div class="col-12 col-lg-2 col-md-6 col-sm-12">
														<input-file formControlName="ninthDish" [possibileMediaTypes]="possibileMediaTypes" label="piatto 9">
														</input-file>
													</div>
												   
												</div>
												<div class="row">
													<div class="col-12 col-lg-2 col-md-6 col-sm-12">
														<input-file formControlName="firstOil" [possibileMediaTypes]="possibileMediaTypes" label="olio 1">
														</input-file>
													</div>
													<div class="col-12 col-lg-2 col-md-6 col-sm-12">
														<input-file formControlName="secondOil" [possibileMediaTypes]="possibileMediaTypes" label="olio 2">
														</input-file>
													</div>
													<div class="col-12 col-lg-2 col-md-6 col-sm-12">
														<input-file formControlName="thirdOil" [possibileMediaTypes]="possibileMediaTypes" label="olio 3">
														</input-file>
													</div>
	
												</div>
											</form>
											
	
										</div>
										<div class="row">
											<div class="col-12 text-right">
												<button type="button" (click)="addEvaluation()"  class="wizardBtn">
													<!-- <i class="{{iconAdd}}"></i> -->
													Aggiungi nuova valutazione
												</button>
											</div>
										</div>
                                        



                                        <button type="button" class="wizardBtn" awPreviousStep>Indietro</button>
                                        <button type="button" (click)="save()"  [disabled]="!stepTwoValid" class="wizardBtn btn form-submit" id="btnNext3">Salva</button>
                                        <!-- <button type="button" (click)="save()" class="wizardBtn btn form-submit" [disabled]="true" id="btnNext3">Salva</button> -->
                                        <div *ngIf="!stepTwoValid" class="subtitle-form">
                                            <p class="stepControl">Controlla di aver inserito tutti i campi obbligatori
                                            </p>
                                        </div>

                                    </aw-wizard-step>
                                </aw-wizard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- </form> -->
    </ng-container>
</base-page>