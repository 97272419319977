import { Injectable } from "@angular/core";
import { Restaurant } from "app/models/restaurant.model";
import { environment } from "environments/environment";
import { Observable, of } from "rxjs";
import { HttpService } from "./http.service";

import moment from 'moment';

export enum DOMAINS_CONFIG {
	edr = "edr",
	ROMANESCO = "ROMANESCO",
	PECORA_NERA = "PECORA_NERA",
	GIUSTA = "GIUSTA",
	BONFIT = "BONFIT",
	NEW_PAT_2 = "NEW_PAT_2",
	HQFAgricola = "hqfagricola_demo",
	casearia = "casearia",
	casearia_pecorino = "casearia_pecorino",
	agricola_san_maurizio = "agricola_san_maurizio",
	riso_buono = "riso_buono",
	biolu = "biolu"
}

@Injectable({
	providedIn: 'root'
})
export class RestaurantProvider {
	constructor(private _httpService: HttpService) {
	}
	public getAll = (domain: DOMAINS_CONFIG, fieldsName?: string[]): Observable<Restaurant[]> => {
		let queryParams = ""
		if (fieldsName && fieldsName.length>0) {
			queryParams = "?";
			fieldsName.forEach(field => {
				queryParams += "fields="+field+ "&";
			})
		}

		return this._httpService.get(environment.services.restaurantService + "/" + domain + queryParams, "json");
	}
	public getRestaurant = (domain: DOMAINS_CONFIG, id: any): Observable<Restaurant> => {
		let url = environment.services.restaurantService + "/" + domain + "/" + id;
		return this._httpService.get(url);
	}
	public getRestaurantByField = (domain: DOMAINS_CONFIG, fiendId: string, value: any): Observable<Restaurant> => {
		let url = environment.services.restaurantService + "/" + domain + "/" + fiendId + "/" + value;
		return this._httpService.get(url);
	}
	public deleteRestaurant = (domain: DOMAINS_CONFIG, id: any): Observable<Restaurant> => {
		let url = environment.services.restaurantService + "/" + domain + "/" + id;
		return this._httpService.delete(url);
	}
	public saveOrUpdateRestaurant = (domain: DOMAINS_CONFIG, restaurant: Restaurant, toNotify: boolean = false): Observable<Restaurant> => {
		let url = environment.services.restaurantService + "/" + domain;
		restaurant.lastUpdateDate = moment().format("DDMMYYYY");
		if (restaurant.id)
			return this._httpService.put(url + "/"+restaurant.id + "?notify="+toNotify, restaurant,"json");
		return this._httpService.post(url + "?notify="+toNotify,restaurant,"json");
	}
	public certifyRestaurant = (data: any): Observable<any> => {
		let url = environment.services.blockChainService;
		return this._httpService.post(url,data,"json");
	}

	public getImageHash(imageId): Observable<string>{
		return this._httpService.get(environment.services.mediaContentsBasePath + "/"+imageId + "/hash", "text");
	}
}