<base-page>
	<ng-container page-body>
		<div class="card form-card" *ngIf="pageLoaded">
			<div class="card-content form-content">
				<div class="card-body">
					<div class="row formContent">
						<div class="col-12">
							<div class="row fistStepTit">
								<div class="col-12">
									<h3>Seleziona il form</h3>
								</div>
							</div>
							<div class="row ">
								<div class="col-12 col-md-4 col-lg-4 col-sm-12 choiceBtn my-4">
									<button type="button" class="wizardBtn"
										(click)="goToRestaurant()">GIUSTA</button>
								</div>
								<div class="col-12 col-md-4 col-lg-4 col-sm-12 choiceBtn my-4">
									<button type="button" class="wizardBtn"
										(click)="goToRomanesco()">ROMANESCO</button>
								</div>
								<div class="col-12 col-md-4 col-lg-4 col-sm-12 choiceBtn my-4 ">
									<button type="button" class="wizardBtn"
										(click)="goToPecoraNera()">Valutazione Pecora Nera</button>
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</base-page>