<base-page>
    <ng-container page-body>
        <div class="card">
            <div class="card-header">
                Elenco Utenti
            </div>
			<div class="card-header">
				<div class="row">
					<div class="col-6">
						<span class="badge badge-success">{{numberOfAvailableItems}}</span> utenti trovati
					</div>
					
				</div>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <list-table [columns]="tableColumns" [manager]="dataTableManager" [actions]="tableActions">
                            </list-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</base-page>