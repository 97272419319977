import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from 'app/common/components/components.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ArchwizardModule } from 'angular-archwizard';
import { UserProfilePage } from './user-profile-page/user-profile.page';
import { PipeModule } from 'app/pipes/pipe.module';



export const UserProfileRoutes: Routes = [
    { path: 'user-profile', component: UserProfilePage, canActivate: []},
    
];


@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        RouterModule.forChild(UserProfileRoutes),
		NgJsonEditorModule,
		ArchwizardModule,
		PipeModule
    ],
    exports: [
		
	],
    declarations: [
		UserProfilePage
    ],
    providers: [],
	entryComponents: []
})
export class UserProfileModule { }
