import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of } from 'rxjs';

import { iconAdd, iconTrash } from "app/common/utils/icons.utils";
import { DOMAINS_CONFIG, RestaurantProvider } from 'app/services/restaurant.service';

import { ActivatedRoute, Router } from '@angular/router';
import { SubmissionResult } from 'app/pages/forms/result-form-page/result-form-submission.page';
import { catchError } from 'rxjs/operators';
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { FORM_SUBMISSION_RESULT } from '../routing-constants';
import { environment } from 'environments/environment';
import { domainConfig } from 'app/services/DomainConfigProvider';
import { NewCertificationBaseFormPage } from '../NewCertificationBaseFormPage';
import { BlockChainService } from 'app/services/blockchain.service';
import { ProfileService } from 'app/services/profile.service';

@Component({
    selector: 'biolu-form-page',
    templateUrl: './biolu-form.page.html'
})

export class BioluFormPage extends NewCertificationBaseFormPage implements OnInit {
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	iconAdd = iconAdd;
	iconDelete = iconTrash;
	editorOptions;

	public entityForm: FormGroup = null;
	
	public lotInfo: FormGroup = null;

	

	ngOnInit() {
		this.certificationConfig = environment.certificationConfig.DEFAULT_CONFIG;
		this.currentDomainConfig = domainConfig.biolu;
		this._activatedRoute.params.subscribe(params => {
			let id = params['id'];
			if (id != undefined || id != null) {
				this._restaurantProvider.getRestaurant(DOMAINS_CONFIG.biolu,id).subscribe((result) => {
					this.initForm(result);					
					this.entityForm.patchValue(this.unmarshalData(result));
				})
			}
			else {
				this.initForm();
			}
		});
	}

    constructor( _chainService: BlockChainService, private _activatedRoute: ActivatedRoute, _fb: FormBuilder, _restaurantProvider: RestaurantProvider, private _router: Router, _userProfileService: ProfileService) {
		super(_restaurantProvider, _chainService, _fb, _userProfileService);
    }


	private initForm = (initData?: any) => {
		
		this.lotInfo = this._fb.group({
			lotNumber: [null, CommonValidators.required],
			giftDescription: [null],
			collectionDate: [null, CommonValidators.required],
			productionDate: [null, CommonValidators.required],
			bottlingDate: [null, CommonValidators.required],
		})

		this.entityForm =this._fb.group({
			id: [null],
			image: [],
			companyName: ["biolu", CommonValidators.required],
			city: ["Calvi", CommonValidators.required],
			province: ["Benevento", CommonValidators.required],
			productName: ["Olio extra vergine d'oliva bio", CommonValidators.required],
			productSpecie: ["Ravece", CommonValidators.required],
			lotInfo: this.lotInfo
		});
	}

	
	private marshalData = (data: any): any => {
		return data;		
	}

	private unmarshalData = (data: any): any => {
		return data;
	}

	
    public save() {
        if (this.canSaveForm) {
            this._restaurantProvider.saveOrUpdateRestaurant(DOMAINS_CONFIG.biolu, this.marshalData(this.entityForm.value))
			.pipe(
				catchError(error => {
					return of(null);
				})
			)
			.subscribe(
				(result) => {
					
					this.certifyData(result).then((certResult) => {
						this._activatedRoute.queryParams.subscribe(qParam => {
							if (qParam.returnUrl) {
								this._router.navigateByUrl(qParam.returnUrl);
							}
							else {
								if (result) {
									//console.log("Salvataggio completato correttamente");
									this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_OK]);
								}
								else {
									//console.log("Salvataggio completato correttamente");
									this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
								}
							}
						})
					})
				},
				(error) => {
					//console.log("Salvataggio completato correttamente");
					this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
				}
			)
        }
		else {
			//console.log("form not valid");
			
		}
    }


	public get logoImage(): string {
		return "assets/img/logos/logo_bicolor.svg";
	}

	public get canSaveForm(): boolean {
		return this.entityForm.valid && this.valid;
	}
}


