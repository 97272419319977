import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export enum SubmissionResult {
	RESULT_OK = "OK",
	RESULT_ERROR = "ERROR"
}
@Component({
    selector: 'result-form-submission-page',
    templateUrl: './result-form-submission.page.html'
})
export class ResultSubmissionFormPage implements OnInit {
	result: SubmissionResult;

	constructor(private _activatedRoute: ActivatedRoute, _router: Router) {
	}

	public ngOnInit() {
		this._activatedRoute.params.subscribe(params => {
			this.result = params['submissionResult'];
		});
	}
}