<div class="form-group">
    <label [for]="id" [ngClass]="{ 'text-danger': !valid}">{{label | translate}}</label>
    <!-- <input type="text" [id]="id" class="form-control" placeholder="{{correctPlaceHolder | translate}}"
        [(ngModel)]="value" [disabled]="disabled" *ngIf="rows==1" [maxlength]="maxLength"
        [ngClass]="{ 'is-invalid': !valid}" />
    <textarea [id]="id" class="form-control" placeholder="{{correctPlaceHolder | translate}}" [(ngModel)]="value"
        [disabled]="disabled" *ngIf="rows>1" [maxlength]="maxLength" [ngClass]="{ 'is-invalid': !valid}"
        [attr.rows]="rows"></textarea> -->

		<json-editor [options]="editorOptions" [id]="id" [(ngModel)]="value" ></json-editor>
    <small *ngIf="!valid" class="form-text text-muted danger"><em>{{errorMessage}}</em></small>
</div>