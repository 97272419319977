<div class="form-group">
    <ng-container *ngIf="horizontal">
        <div class="custom-switch custom-switch-primary custom-control-inline">
            <input type="checkbox" [id]="id" class="custom-control-input" [(ngModel)]="value" [disabled]="disabled"
                [ngClass]="{ 'is-invalid': !valid}" />
            <label [for]="id" class="custom-control-label mr-1" [ngClass]="{ 'text-danger': !valid}">{{label |
                translate}}</label>
        </div>
    </ng-container>
    <ng-container *ngIf="!horizontal">
        <label [for]="id" class="d-block" [ngClass]="{ 'text-danger': !valid}">{{label |
            translate}}</label>
        <div class="custom-switch custom-switch-primary">
            <input type="checkbox" [id]="id" class="custom-control-input" [(ngModel)]="value" [disabled]="disabled"
                [ngClass]="{ 'is-invalid': !valid}" />
            <label [for]="id" class="custom-control-label mr-1"> </label>
        </div>
    </ng-container>
    <small *ngIf="!valid" class="form-text text-muted danger"><em>{{errorMessage}}</em></small>
</div>