import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { SelectListitem } from 'app/common/models/models';
import { of } from 'rxjs';

import { DOMAINS_CONFIG, RestaurantProvider } from 'app/services/restaurant.service';

import { ActivatedRoute, Router } from '@angular/router';
import { SubmissionResult } from 'app/pages/forms/result-form-page/result-form-submission.page';
import { catchError } from 'rxjs/operators';
import { FORM_SUBMISSION_RESULT } from '../routing-constants';
import { CommonValidators } from 'app/common/components/form/validators/common.validator';
import { CertificationBaseFormPage } from '../CertificationBaseFormPage';
import { environment } from 'environments/environment';
import { domainConfig } from 'app/services/DomainConfigProvider';

@Component({
    selector: 'giusta-form-page',
    templateUrl: './giusta-form.page.html'
})

export class GiustaFormPage  extends CertificationBaseFormPage implements OnInit {
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif', 'application/pdf'];

	editorOptions;
	data = {test: "Hello"}
	getData($event) {
		//console.log("$event", $event);
	
	}

    // public form: FormGroup = null;
	public initialized = false;
	public menuInfo: FormGroup = null;
	public contactInfo: FormGroup = null;
	public deliveryInfo: FormGroup = null;
	public restaurantInfo: FormGroup = null;
	public usefullInfo: FormGroup = null;
	public deliveryMenu: FormGroup = null;
	public stringValue = null;
    public selectItemsAcc: SelectListitem[] = [];
    public initialItemAcc: SelectListitem = null;
	public selectItemsProd: SelectListitem[] = [];
    public initialItemProd: SelectListitem = null
	public selectItemsPayment: SelectListitem[] = [];
    public initialItemPayment: SelectListitem = null;
	public selectItemsMenuType: SelectListitem[] = [];
    public initialItemMenuType: SelectListitem = null;

	public traditional:boolean = false;

	ngOnInit() {
		this.certificationConfig = environment.certificationConfig.DEFAULT_CONFIG;
		this.currentDomainConfig = domainConfig.GIUSTA;

		this.contactInfo = this._fb.group({
			firstName: [null, Validators.required],
			lastName: [null, Validators.required],
       		phone: [null, [ Validators.required, Validators.pattern("^[0-9]*$")]],
			email: [null,[Validators.email, Validators.required]]
		});


		this.usefullInfo = this._fb.group({
			openingTime: [null, Validators.required],
			reservation: [false],
			weeklyClosing: [null, Validators.required],
			annualClosing: [null, Validators.required],
			creditCards: [null],
			accessibility: [null],
			parking: [false],
			petsAllowed: [false],
			wifi: [false],
			internalSeats: [null],
			externalSeats: [null],
			kitchenType: [null, Validators.required],
			specialDietsDishes: [null],
			specialProductsUsage: [false],
			priceRange: [null, Validators.required]
		});



		
		




	
		this.deliveryInfo = this._fb.group({
			// logo: [null, Validators.required],
			paymentType: [null],
			menuType: [null],
			deliveryDays: [null, Validators.required],
			lunchDeliveryHours: [null, Validators.required],
			dinnerDeliveryHours: [null, Validators.required],
			deliverySolutionsTimeInterval: [null, Validators.required],
			// menuImage: [null, Validators.required],
			orderRestrictions: [null, Validators.required],
			menuSectionsToExclude: [null, Validators.required],
			notes: [null, Validators.required],
		});

		

		this.deliveryMenu = this._fb.group({
			menuImage: [null],
		});
		this.restaurantInfo = this._fb.group({
			id: [null],

			businessName: [null, Validators.required],
			logo: [null, Validators.required],
			sign: [null, Validators.required],
       		vatNumber: [null, Validators.required],
			sdiOrPec:  [null, CommonValidators.stringMinLengthValidator(7)],
			pec:  [null, Validators.email],
			representative: [null, Validators.required],
			address: [null, Validators.required],
			zipCode: [null, Validators.required],
			city: [null, Validators.required],
			province: [null, Validators.required],
			phone: [null],
			fipePartner: [false],
			fipeCardNumber: [null],
			mobilePhone: [null],
			email: [null, Validators.email],
			website: [null],
			social: [null],
			socialFacebook: [null],
			socialInstagram:[null],
			socialTikTok: [null],
			description: [null, Validators.required],
			ownerStory: [null, Validators.required],

			usefullInfo: this.usefullInfo,
			contactInfo: this.contactInfo,
			deliveryInfo: this.deliveryInfo,
			deliveryMenu: this.deliveryMenu
		});

        // this.form = this._fb.group({
		// 	id: [null],
		// 	restaurantInfo: this.restaurantInfo,
		// 	deliveryInfo: this.deliveryInfo,
		// 	deliveryMenu: this.deliveryMenu
		// });

		// this.form.updateValueAndValidity();

		let accessibility = [
			{id: "servizi per disabili (rampe e servizi igienici)", name: "servizi per disabili (rampe e servizi igienici)"},
			{id: "assenza di barriere architettoniche", name: "assenza di barriere architettoniche"},
		];

		this.selectItemsAcc = accessibility.map(m => new SelectListitem(m.id, m.name));
		this.initialItemAcc = this.selectItemsAcc[0];

		let payment = [
			{id: "Pagamento alla consegna", name: "Pagamento alla consegna"},
			{id: "Ritira e paga presso il locale", name: "Ritira e paga presso il locale"},
			{id: "PayPal", name: "PayPal"},
			{id: "Stripe", name: "Stripe"},
		];

		this.selectItemsPayment = payment.map(m => new SelectListitem(m.id, m.name));
		this.initialItemPayment = this.selectItemsPayment[0];


		let menuType = [
			{id: "Confezionati caldi", name: "Confezionati caldi"},
			{id: "Confezionati freddi", name: "Confezionati freddi"},
			{id: "Sottovuoto", name: "Sottovuoto"},
		];

		this.selectItemsMenuType = menuType.map(m => new SelectListitem(m.id, m.name));
		this.initialItemMenuType = this.selectItemsMenuType[0];


		let productsType = [
			{id: "Vegetariana", name: "Vegetariana"},
			{id: "Vegana", name: "Vegana"},
			{id: "Senza Glutine", name: "Senza Glutine"},
			{id: "Senza Lattosio", name: "Senza Lattosio"},
	
		];

		this.selectItemsProd = productsType.map(m => new SelectListitem(m.id, m.name));
		this.initialItemProd = this.selectItemsProd[0];


		this._activatedRoute.params.subscribe(params => {
			let id = params['id'];
			if (id != undefined || id != null) {
				this._restaurantProvider.getRestaurant(DOMAINS_CONFIG.GIUSTA,id).subscribe((result) => {
					// this.patchingInAction = true;
					// this.initForm(result);
					//console.log("Result: ", result);
					
					this.restaurantInfo.patchValue(result)
					//console.log(this.restaurantInfo.get("restautantLogo"));
					this.initialized = true;
					// this.patchingInAction = false;
				})
			}
			else {
				this.initialized = true;
			}
		});
	}
	


    constructor(private _fb: FormBuilder, _restaurantProvider: RestaurantProvider, private _router: Router, private _activatedRoute: ActivatedRoute) {
		super(_restaurantProvider);
		this.editorOptions = new JsonEditorOptions()
		this.editorOptions.modes = ['code', 'text', 'tree', 'view'];

    }











	
    public save(toNotify: boolean = false) {
        if (this.stepTwoValid) {
            this._restaurantProvider.saveOrUpdateRestaurant(DOMAINS_CONFIG.GIUSTA, this.restaurantInfo.value, toNotify)
			.pipe(
				catchError(error => {
					return of(null);
				})
			)
			.subscribe(
				(result) => {
					// this._activatedRoute.queryParams.subscribe(qParam => {
					// 	if (qParam.returnUrl) {
					// 		this._router.navigateByUrl(qParam.returnUrl);
					// 	}
					// 	else {
					// 		if (result) {
					// 			//console.log("Salvataggio completato correttamente");
					// 			this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_OK]);
					// 		}
					// 		else {
					// 			//console.log("Salvataggio completato correttamente");
					// 			this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
					// 		}
					// 	}
					// })

					this.certifyData(result).then((certResult) => {
						this._activatedRoute.queryParams.subscribe(qParam => {
							if (qParam.returnUrl) {
								this._router.navigateByUrl(qParam.returnUrl);
							}
							else {
								if (result) {
									//console.log("Salvataggio completato correttamente");
									this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_OK]);
								}
								else {
									//console.log("Salvataggio completato correttamente");
									this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
								}
							}
						})
					})
					
					
				},
				(error) => {
					//console.log("Salvataggio completato correttamente");
					this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
				}
			)
        }
		else {
			//console.log("form not valid");
			
		}
    }

	

	public get logoImage(): string {
		if (this.traditional)
			return "assets/img/logo-form.png";
		return "assets/img/logo-giusta.jpeg";
	}

	public get stepOneValid(): boolean {
		return true;
		// return this.contactInfo.valid && this.usefullInfo.valid && this.restaurantInfo.valid;
	}
	
	public get stepTwoValid(): boolean {
		if (this.stepOneValid) {
			// if (this.deliveryInfo.value.sameMenu)
			// 	return this.deliveryInfo.valid && this.restaurantInfo.get('deliveryMenu').valid
			// return this.deliveryInfo.valid
			return this.restaurantInfo.valid;
		}
		return false;
	}
}


