<base-page>
    <ng-container page-body>
        <div class="card form-card">
            <form [formGroup]="panettoneBonfit" *ngIf="panettoneBonfit">
                <aw-wizard>
                    <aw-wizard-step stepTitle="Dati Generali">
                        <div class="card-header logo-form" style="background-color: white;">
                            <img class="" [src]="logoImage">
                        </div>
                        <div class="card-content form-content">
                            <div class="card-body">
                                <div class="row formContent">
                                    <div class="col-12">
                                        <div class="subtitle-form">
                                            <h3>Informazioni Generali</h3>
                                        </div>
                                        <form [formGroup]="producerInfo">
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="company" label="Azienda"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="city" label="Città"></input-string>
                                                </div>
                                            </div>
                                            <div class="row ">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="address" label="Indirizzo" placeHolder="Inserisci un indirizzo"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="product" label="Prodotto" placeHolder="Inserisci il prodotto"></input-string>
                                                </div>
                                            </div>
                                        </form>
                                        <div class="subtitle-form">
                                            <h3>Informazioni generiche dipendenti dal lotto</h3>
                                        </div>
                                        <form [formGroup]="generalInfo">
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-file formControlName="photo" [possibileMediaTypes]="possibileMediaTypes" label="Foto del panettone"></input-file>
                                                </div>
                                            </div>
											<div class="row">
												<div class="col-12">
													<input-string formControlName="productionLot" label="Lotto di produzione">
													</input-string>
												</div>
											</div>
                                            <div class="row">
                                                <div class="col-12 ">
                                                    <input-string formControlName="productCode" label="Codice etichetta"></input-string>
                                                </div>
                                            </div>
											<div class="row">
                                                <div class="col-12 ">
													<input-date formControlName="packagingDate" label="Data di confezionamento"></input-date>
                                                </div>
                                            </div>
											<div class="row">
                                                <div class="col-12 ">
													<input-date formControlName="expiryDate" label="Data di scadenza"></input-date>
                                                </div>
                                            </div>
                                        </form>
										<div class="subtitle-form">
											<h3>
												Ingredienti <button type="button" (click)="addIngrediant()"  class="wizardBtn">
												<i class="{{iconAdd}}"></i>
											</button></h3>
											
										</div>
                                        
										<div *ngFor="let ingrediant of panettoneBonfit.get('ingrediants').controls; let i = index">
											<form [formGroup]="ingrediant">
												<div class="row">
													<div class="col-3">
														<input-string formControlName="ingrediant" label="Ingrediente">
														</input-string>
													</div>
													<div class="col-3">
														<input-string formControlName="lot" label="Lotto">
														</input-string>
													</div>
													
													<div class="col-3">
														<input-string formControlName="producer" label="Produttore">
														</input-string>
													</div>
													<div class="col-3 text-right">
														<button type="button" (click)="removeIngrediant(i)"  class="wizardBtn">
															<i class="{{iconDelete}}"></i>
														</button>
													</div>
												</div>
												
											</form>
											
	
										</div>
										<!-- <div class="row">
											<div class="col-12 text-right">
												
											</div>
										</div> -->

                                        <button type="button" class="wizardBtn" awPreviousStep>INDIETRO</button>
                                        <button id="btnNext1" type="button" class="wizardBtn" [disabled]="!stepOneValid" (click)="save()" >SALVA</button>
                                        <br>
                                        <div *ngIf="!stepOneValid" class="subtitle-form">
                                            <p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aw-wizard-step>

                    
                </aw-wizard>
            </form>
        </div>
    </ng-container>
</base-page>