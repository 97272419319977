<base-page>
    <ng-container page-body>
        <div class="card form-card">
			<div class="subtitle-form">
				<h1>{{title}}</h1>
			</div>
			<aw-wizard *ngIf="entityForm">
				<aw-wizard-step stepTitle="Dati generali">
					<div class="card-header logo-form" style="background-color: white;">
						<img class="" [src]="logoImage">
					</div>
					<div class="card-content form-content">
						<div class="card-body">
							<div class="row formContent">
								<div class="col-12">
									<form [formGroup]="entityForm">
										<div class="subtitle-form">
											<h3>Definizione dei dati generali</h3>
										</div>
										<div class="row">
											<div class="col-12">
												<input-string formControlName="entityType" label="Identificativo univoco"></input-string>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<input-string formControlName="entityName" label="Nome"></input-string>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<input-string formControlName="landingPageUrl" label="Url alla landing page"></input-string>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<input-slide formControlName="needsCertificationInChain" label="Questi dati possono essere certificait" [horizontal]="false">
												</input-slide>
											</div>
										</div>
										<div class="subtitle-form">
											<h3>Dati specifici</h3>
										</div>
										<div  *ngFor="let field of entityForm.get('properties').controls; let i=index" >
											<form [formGroup]="field">
												<div class="row">
													<div class="col col-5">
														<input-string formControlName="fieldLabel" label="Etichetta del campo"></input-string>
														
													</div>
													<div class="col col-5">
														<input-string formControlName="fieldValue" label="Valore del campo"></input-string>
													</div>
													<div class="col col-2">
														<button type="button" class="wizardBtn" (click)="removeField(i)">elimina</button>
													</div>
												
												</div>
	
											</form>
										</div>
										
										
										<div class="row" >
											<div class="col-12">
												<button type="button" class="wizardBtn" (click)="addField()">Aggiungi nuovo campo</button>
											</div>
										</div>
									</form>
									
									<p>&nbsp;</p>
									<hr>
								
									<button type="button" class="wizardBtn" awPreviousStep>INDIETRO</button>
									<button id="btnNext1" type="button" class="wizardBtn" [disabled]="!entityForm.valid" awNextStep>AVANTI</button>
									
									<br>
									<div *ngIf="!entityForm.valid" class="subtitle-form">
										<p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</aw-wizard-step>
				<aw-wizard-step [stepTitle]="'Riepilogo'">
					<div class="card-header logo-form" style="background-color: white;">
						<img class="" [src]="logoImage">
					</div>
					<div class="card-content form-content">
						<div class="card-body">
							<div class="row formContent">
								<div class="col-12">
									<div class="subtitle-form">
										<h3>Definizione dei dati generali</h3>
									</div>
									<div class="row"><strong>Identificativo:</strong> {{entityForm.value.entityType}}</div>
									<div class="row"><strong>Nome:</strong> {{entityForm.value.entityName}}</div>
									<div class="row"><strong>Url alla landing page:</strong> {{entityForm.value.landingPageUrl}}</div>
									<div class="row"><strong>Questi dati possono essere certificait:</strong> {{(entityForm.value.needsCertificationInChain)?'SI':'NO'}}</div>
									
									<div class="subtitle-form" *ngIf="additionalFields.value.length > 0">
										<h3>Dati specifici</h3>
									</div>

									
									<div class="row" *ngFor="let field of additionalFields.value" >
										<div class="col-12 text-white">
											<strong>{{field.value?.fieldLabel}}:</strong> {{field.value?.fieldValue}}
										</div>
									</div>
									
									
									
									<form [formGroup]="walletForm">
										<div class="subtitle-form">
											<h3>chiavi di sicurezza</h3>
										</div>
										
										<div class="row">
											<div class="col-12 ">
												<input-string formControlName="secret" label="Chiave privata del tuo wallet"></input-string>
											</div>
										</div>
										
									</form>
									<button type="button" class="wizardBtn" awPreviousStep>INDIETRO</button>
									<button id="btnNext1" type="button" class="wizardBtn" [disabled]="!entityForm.valid" (click)="save(false)" >SALVA</button>
									<button id="btnNext1" type="button" class="wizardBtn" [disabled]="!canSaveForm" (click)="save(true)" >SALVA E CERTIFICA</button>
									<br>
									<div *ngIf="!canSaveForm" class="subtitle-form">
										<p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</aw-wizard-step>

			</aw-wizard>
        </div>
    </ng-container>
</base-page>