<modal [title]="title" (onClose)="close()" headerBgColor="gradient-man-of-steel">
		<div body class="card">
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <list-table [columns]="tableColumns" [manager]="dataTableManager" [actions]="tableActions">
                            </list-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <div class="text-right" footer>
		<button type="button" (click)="close()" class="btn btn-outline-secondary"><i class="{{iconCancel}}"></i> {{'common.cancelButtonLabel' | translate}}</button>
    </div>
</modal>
