<div class="form-group">
    <label [for]="id" [ngClass]="{ 'text-danger': !valid}">{{label | translate}}</label>
    <div class="input-group">
        <input type="text" [id]="id" class="form-control" placeholder="{{correctPlaceHolder | translate}}"
            [(ngModel)]="value" [disabled]="disabled" [ngClass]="{ 'is-invalid': !valid}" ngbDatepicker
            #d="ngbDatepicker" />
        <div class="input-group-append">
            <button class="btn btn-secondary far fa-calendar-alt" (click)="d.toggle()" type="button"></button>
        </div>
    </div>
    <small *ngIf="!valid" class="form-text text-muted danger"><em>{{errorMessage}}</em></small>
</div>