import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { iconCancel, iconPolygon, iconSave, iconCenter, iconTrash } from "app/common/utils/icons.utils";
import { Collection, Feature, Map, View } from "ol";
import Point from "ol/geom/Point";
import Polygon from "ol/geom/Polygon";
import { Draw, Modify, Select } from "ol/interaction";
import Layer from "ol/layer/Layer";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat } from "ol/proj";
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import { Fill, Icon, Stroke, Style } from "ol/style";
import { defaults as defaultsControls } from 'ol/control';
import MultiPoint from "ol/geom/MultiPoint";
import { timer } from "rxjs";
import { GeoSpatialReference } from "../object-space.model";



export class MapEditorInputDataDef {
	title: string;
	message: string;
	geoSpatialReference: GeoSpatialReference;
}

@Component({
	selector: 'map-editor-modal',
	templateUrl: './map-editor.component.html',
	styleUrls: ['./map-editor.component.css']
})
export class MapEditorModal implements OnInit {
	public data: MapEditorInputDataDef;
	iconCancel = iconCancel;
	iconSave = iconSave;
	iconPolygon = iconPolygon;
	iconCenter = iconCenter;
	iconTrash = iconTrash;
	deleteEnabled: boolean = false;
	selectedFeature: Feature;
	dataValid = false;
	isCenterPresent: boolean = false;
	isPolygonPresent: boolean = false;
	resultData: GeoSpatialReference;
	@ViewChild('mapModal') mapRef: ElementRef<HTMLInputElement>;
	map: Map;
	vectorLayer: VectorLayer = new VectorLayer();
	vectorSource: VectorSource = new VectorSource();
	layers: Layer[] = [];
	view: View;
	private _defaultCenterCoordinates = fromLonLat([14.781447642043426, 41.130769884646625], 'EPSG:4326');


	private _polygonArea: number[][][];
	private _center: any[];

	private mapProjection = 'EPSG:4326';
	private geometryTypeToDraw;
	private modifyInteraction: Modify;
	private drawInteraction: Draw;
	private selectInteraction: Select;
	private defaultStyle: Style = new Style({
		fill: new Fill({
			color: '#ffffff52'
		}),
		stroke: new Stroke({
			color: 'green',
			width: 2
		}),
		image: new Icon({
			src: 'assets/img/icons/marker.png',
		})
	})

	private _reference: GeoSpatialReference;
	get reference(): GeoSpatialReference {
		return this._reference;
	}

	set reference(reference: GeoSpatialReference) {
		this.polygonArea = (reference.polygon && reference.polygon.coordinates && reference.polygon.coordinates[0]) ? reference.polygon.coordinates : null;
		this.center = (reference.centerPoint && reference.centerPoint.coordinates) ? reference.centerPoint.coordinates : null;
		this._reference = reference;
		this.dataValid = this.isDataValid();
	};


	set polygonArea(polygonArea: number[][][]) {
		console.log("polygonArea", polygonArea);

		if (polygonArea) {
			this._polygonArea = [[]];
			this._polygonArea[0] = polygonArea[0].map(el => { return fromLonLat([el[0], el[1]], this.mapProjection) });
			let polygon = new Polygon(this._polygonArea);
			let feature: Feature = new Feature({
				name: "polygon",
				geometry: polygon
			})
			feature.setStyle(this.defaultStyle);
			this.vectorSource.addFeature(feature);
			this.isPolygonPresent = true;
		}
	}

	set center(center: any) {
		console.log("center", center);
		if (center) {
			this._center = fromLonLat(center, this.mapProjection);
			let point = new Point(this._center);
			let feature = new Feature({
				name: "center",
				geometry: point
			})
			feature.setStyle(this.defaultStyle);
			this.vectorSource.addFeature(feature);
			this.isCenterPresent = true;
		}
	}

	ngOnInit() {
		console.log("data on init modal", this.data);
		
		this.resultData = new GeoSpatialReference();
		this.resultData.id = (this.data.geoSpatialReference) ? this.data.geoSpatialReference.id : null;
		if(this.data.geoSpatialReference){
			if(this.data.geoSpatialReference.centerPoint){
				this.resultData.centerPoint = this.data.geoSpatialReference.centerPoint;
			}
			else this.resultData.centerPoint = null;
			if(this.data.geoSpatialReference.polygon){
				this.resultData.polygon = this.data.geoSpatialReference.polygon;
			}
			else this.resultData.polygon = null;
		}
		this.reference = this.resultData;
	}

	ngAfterViewInit() {
		this.initMap();
		this.map.setTarget(this.mapRef.nativeElement);
		this.fitMap();
		timer(500).subscribe(val => {
			this.map.updateSize();
		});
		this.updateInteractions()

	}
	constructor(public activeModal: NgbActiveModal) {

	}

	private initMap(): void {
		this.vectorLayer.setSource(this.vectorSource);
		var tileLayer = new TileLayer({ source: new OSM() });

		if (this.layers.length == 0) {
			this.layers.push(tileLayer);
			this.layers.push(this.vectorLayer);
		}
		else {
			this.layers.splice(0, 1, tileLayer);
			this.layers.splice(1, 1, this.vectorLayer);
		}
		this.view = new View({
			center: (this._center) ? this._center : this._defaultCenterCoordinates,
			projection: this.mapProjection,
			zoom: 5,
			enableRotation: false,
		})
		this.map = new Map({
			controls: defaultsControls({
				attribution: false,
				zoom: false,
				rotate: false
			}),
			layers: this.layers,
			view: this.view,
		});
	}

	fitMap() {
		let multiPoint: MultiPoint = new MultiPoint([]);
		let pointCounter = 0;
		
		if (this._polygonArea && this._polygonArea[0] && this._polygonArea[0].length > 0) {
			this._polygonArea[0].forEach(c => {
				pointCounter++;
				multiPoint.appendPoint(new Point([c[0], c[1]]));
			});
		}

		if (this._center && this._center.length > 0) {
			pointCounter++;
			multiPoint.appendPoint(new Point(this._center));
		}

		if (pointCounter > 1) {
			this.map.getView().fit(multiPoint.getExtent(), {
				duration: 2500
			});
		}
		else if (this._center && this._center.length > 0) {
			this.map.getView().animate({
				center: this._center,
				zoom: 17,
				duration: 2500
			})
		}
	}

	updateInteractions() {
		if (this.drawInteraction)
			this.map.removeInteraction(this.drawInteraction);
		if (this.modifyInteraction)
			this.map.removeInteraction(this.modifyInteraction);
		if(this.selectInteraction)
			this.map.removeInteraction(this.selectInteraction);

	
		// this.map.addInteraction(this.modifyInteraction)
		// this.modifyInteraction.on("modifyend", this.onModifyEnd);

		if (this.geometryTypeToDraw) {
			this.drawInteraction = new Draw({
				source: this.vectorSource,
				type: this.geometryTypeToDraw,
			})
			this.drawInteraction.on('drawend', this.onDrawEndEvent);
			this.map.addInteraction(this.drawInteraction);
		}
		else{
			this.selectInteraction = new Select();
			this.selectInteraction.on("select", this.onSelect)
			this.map.addInteraction(this.selectInteraction)

		}
	}

	private onModifyEnd = (evt) => {
		evt.features.forEach(f => {
			let type = f.getProperties().name;
			if (type == "center") {
				this._center = f.getGeometry().getCoordinates();
				this._reference.centerPoint = {
					type: "Point",
					coordinates: this._center
				}
				// this._center = f.getGeometry().getCoordinates();
			}
			else if (type == "polygon") {
				let newPolygon = [];
				f.getGeometry().getCoordinates()[0].forEach(e => {
					newPolygon.push(e)
				});;
				this._polygonArea = [newPolygon];
				this._reference.polygon = {
					type: "Polygon",
					coordinates: this._polygonArea
				}
			}
		});
	}

	private onSelect = (evt) => {
		if(this.modifyInteraction)
			this.map.removeInteraction(this.modifyInteraction)
		this.deleteEnabled = true;
		this.selectedFeature = evt.selected[0]
		// var featureCollection = new Collection<Feature>();
		// featureCollection.push(evt.selected[0])
		// this.map.removeInteraction(this.selectInteraction)
		this.modifyInteraction = new Modify({source: this.vectorSource})
		// this.modifyInteraction = new Modify({features: featureCollection})
		this.modifyInteraction.on("modifyend", this.onModifyEnd);

		this.map.addInteraction(this.modifyInteraction)
	}

	
	delete(){
		if(this.selectedFeature){
			this.deleteEnabled = false;
			this.vectorSource.removeFeature(this.selectedFeature);
			if(this.selectedFeature.getProperties().name == "polygon"){
				this._polygonArea = [[]]
				this._reference.polygon.coordinates = this._polygonArea;
				this.isPolygonPresent = false;
			}
			else{
				this._center = []
				this._reference.centerPoint.coordinates = this._center;
				this.isCenterPresent = false;
			}
			this.selectedFeature = null;
			this.selectInteraction.getFeatures().clear();

		}
	}

	private onDrawEndEvent = (evt) => {

		let f: Feature = evt.feature;
		let geometryName;
		switch (this.geometryTypeToDraw) {
			case "Point":
				this.vectorSource.getFeatures().forEach(f => {
					if (f.getProperties().name == "center")
						this.vectorSource.removeFeature(f);
				})
				if (!this._reference) {
					this._reference = new GeoSpatialReference;
				}
				this._center = evt.feature.getGeometry().getCoordinates();

				this._reference.centerPoint = {
					type: "Point",
					coordinates: this._center
				}
				geometryName = "center"
				this.isCenterPresent = true;
				break;
			case "Polygon":
				this.vectorSource.getFeatures().forEach(f => {
					if (f.getProperties().name == "polygon")
						this.vectorSource.removeFeature(f);
				})
				geometryName = "polygon";
				let newPolygon = []
				evt.feature.getGeometry().getCoordinates()[0].forEach(e => {
					newPolygon.push(e)
				});;
				if (!this._reference) {
					this._reference = new GeoSpatialReference;
				}
				this._reference.polygon = {
					type: "Polygon",
					coordinates: [newPolygon]
				}
				this._polygonArea = [newPolygon];
				this.isPolygonPresent = true;
				break;
		}
		this.dataValid = this.isDataValid();
		f.setStyle(this.defaultStyle);
		f.setProperties({ name: geometryName })
		this.map.removeInteraction(this.drawInteraction)
		this.selectInteraction = new Select();
		this.selectInteraction.on("select", this.onSelect)
		this.map.addInteraction(this.selectInteraction)
	}

	drawOnMap(geometry: string) {
		this.deleteEnabled = false;
		this.geometryTypeToDraw = geometry
		this.updateInteractions();
	}

	isDataValid(): boolean{
		return ((this._reference.centerPoint && this._reference.centerPoint.coordinates) || (this._reference.polygon && this._reference.polygon.coordinates) ) ? false : true;
	}


	public close = (): void => {
		this.activeModal.close(null);
	}
	public ok = (): void => {
		if (this._reference && this._reference.polygon)
			this.resultData.polygon = this._reference.polygon
		if (this._reference && this._reference.centerPoint)
			this.resultData.centerPoint = this._reference.centerPoint

		this.activeModal.close(this.resultData);
	}

}