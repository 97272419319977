import { Component, Input, OnInit } from '@angular/core';
import { DataTableColumn, DataTableAction, DataTableColumnAlignment, ListTableManager } from './types';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { SubscriptionLike } from 'rxjs';
import { BaseComponent } from '../base.component';
import { SortCriteria } from 'app/common/models/models';

@Component({
    selector: 'list-table',
    templateUrl: './list-table.component.html',
    styleUrls: ["./list-table.component.scss"]
})
export class ListTableComponent extends BaseComponent implements OnInit {
    constructor(private _sanitizer: DomSanitizer) {
        super();
    }

	@Input() public filterText: string = "";
	@Input() public filterKeys: string[];
    @Input() public columns: DataTableColumn[] = []
    @Input() public actions: DataTableAction[] = []
    @Input()
    public get manager() {
        return this._manager;
    }
    public set manager(value: ListTableManager) {
        this.destroySubscriptions();
        this._manager = value;
        this._managerSubscription = this._manager.data.subscribe(result => {
            this.items = [...result.data];
        });
    }

    public sortColumn: string = null;
    public sortAscending?: boolean = null;
    public items: any[] = [];
    private _manager: ListTableManager = null;;
    private _managerSubscription: SubscriptionLike = null;

    ngOnInit(): void {
        //Imposto i parametri
		console.log(" this.manager.sortCriteria", this.manager.sortCriteria);
		
        this.sortColumn = this.manager.sortCriteria.field;
        this.sortAscending = this.manager.sortCriteria.ascending;
    }

    public changeSort = (column: DataTableColumn): void => {
        let sortableColumn = this.columns.find(f => f.name == column.name && f.sortable);
        if (sortableColumn) {
            if (this.sortColumn == column.name) {
                this.sortAscending = !this.sortAscending;
            }
            else {
                this.sortColumn = column.name;
                this.sortAscending = true;
            }
            let sortCriteria = new SortCriteria();
            sortCriteria.ascending = this.sortAscending
            sortCriteria.field = column.name;
            this.manager.search(sortCriteria);
        }
    }

    public getAlignment = (column: DataTableColumn, index: number): SafeStyle => {
        if (index == 0) {
            return this._sanitizer.bypassSecurityTrustStyle("justify-content:flex-start");
        }
        switch (column.alignment) {
            case DataTableColumnAlignment.CENTER:
                return this._sanitizer.bypassSecurityTrustStyle("justify-content:center");
            case DataTableColumnAlignment.RIGHT:
                return this._sanitizer.bypassSecurityTrustStyle("justify-content:flex-end");
            default:
                return this._sanitizer.bypassSecurityTrustStyle("justify-content:flex-start");
        }
    }

    private destroySubscriptions = (): void => {
        if (this._managerSubscription != null) {
            this.off(this._managerSubscription);
            this._managerSubscription = null;
        }
    }

    protected destroy = (): void => {
        this.destroySubscriptions();
    }
	invokeAction = ($event, action: DataTableAction, item): void => {
		if (action.enableFunc(item))
			action.funcToInvoke(item)
		$event.preventDefault();
	}

	get firstColumn() {
		if (this.columns) return this.columns[0];
	}
	get otherColumns() {
		if (this.columns) return this.columns.slice(1);
	}
}  
