
export class ActionCommand {
    public funcToInvoke: (element: any) => void = (element) => { };
    public label: string;
    public icon: string;
    public enableFunc: (element: any) => boolean = (element) => true;
	public enablePermission: string;
}

export class SelectListitem {
    constructor(public id: any, public label: string) {

    }
}

export class SearchResult<T>{
    public items: T[] = [];
    public totalCount: number = 0;
}

export class SortCriteria {
    public ascending: boolean = true;
    public field: string = null;
}
export class SearchCriteria extends SortCriteria {
    public itemsPerPage: number = 10;
    public pageNumber: number = 1;
}

export type ModalSizes = 'sm' | 'md' | 'lg' | 'xl'

export class ModalOptions<T> {
    public callback?: (data: T) => void = (data) => { };
    public size?: ModalSizes = 'md';
}

export class ModalConfirmData {
    constructor(public title: string, public message: string) {

    }
}

export enum ToastTypes {
    Info = 'info',
    Success = "success",
    Danger = "danger"
}

export class ToastItem {
    constructor(public message: string, public type: ToastTypes) {

    }
}


export class NotificationMessage {
    public static code: string = null;
}