import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { of } from 'rxjs';

import { iconAdd, iconTrash } from "app/common/utils/icons.utils";
import { DOMAINS_CONFIG, RestaurantProvider } from 'app/services/restaurant.service';

import { ActivatedRoute, Router } from '@angular/router';
import { SubmissionResult } from 'app/pages/forms/result-form-page/result-form-submission.page';
import { catchError } from 'rxjs/operators';
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { FORM_SUBMISSION_RESULT } from '../routing-constants';
import { environment } from 'environments/environment';
import { domainConfig } from 'app/services/DomainConfigProvider';
import { NewCertificationBaseFormPage } from '../NewCertificationBaseFormPage';
import { BlockChainService } from 'app/services/blockchain.service';
import { ProfileService } from 'app/services/profile.service';

@Component({
    selector: 'casearia-form-page',
    templateUrl: './casearia-form.page.html'
})

export class CaseariaFormPage extends NewCertificationBaseFormPage implements OnInit {
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	iconAdd = iconAdd;
	iconDelete = iconTrash;
	editorOptions;

	public entityForm: FormGroup = null;
	
	public socialImpact: FormGroup = null;
	public productInfo: FormGroup = null;

	

	ngOnInit() {
		this.certificationConfig = environment.certificationConfig.DEFAULT_CONFIG;
		this.currentDomainConfig = domainConfig.casearia;
		this._activatedRoute.params.subscribe(params => {
			let id = params['id'];
			if (id != undefined || id != null) {
				this._restaurantProvider.getRestaurant(DOMAINS_CONFIG.casearia,id).subscribe((result) => {
					this.initForm(result);					
					this.entityForm.patchValue(this.unmarshalData(result));
				})
			}
			else {
				this.initForm();
			}
		});
	}

    constructor( _chainService: BlockChainService, private _activatedRoute: ActivatedRoute, _fb: FormBuilder, _restaurantProvider: RestaurantProvider, private _router: Router, _userProfileService: ProfileService) {
		super(_restaurantProvider, _chainService, _fb, _userProfileService);
    }


	private initForm = (initData?: any) => {
		this.socialImpact = this._fb.group({
			s1: ["costituzione e inserimento prodotto all'interno della rete Iolatte", CommonValidators.required],
			s2: ["logo aggiuntivo", CommonValidators.required],
			s3: ["certificazioni aziendali volontarie", CommonValidators.required],
			s4: ["benessere animale", CommonValidators.required]

		})
		this.productInfo = this._fb.group({
			productName: ["Conciato di San Vittore", CommonValidators.required],
			producerSpecie: ["ovina", CommonValidators.required],
			diet: ["pascolo/semi-brado", CommonValidators.required],
			milkOrigin: ["Lazio", CommonValidators.required]
		})

		let lots = [];
		if (initData && initData.lots && initData.lots.length>0) {
			initData.lots.forEach((lot) => {
				lot.push(this.createLotFormGroup());
			})
		}

		this.entityForm =this._fb.group({
			id: [null],
			image: [null],
			companyName: ["Casearea Agri In", CommonValidators.required],
			bolloCe: ["CE d8s5z IT", CommonValidators.required],
			productionPlace: ["Torrita Tiberina", CommonValidators.required],
			province: ["Roma", CommonValidators.required],
			giftDescription: [null, CommonValidators.required],

			productInfo: this.productInfo,
			lotsInfo: this._fb.array(lots),
			socialImpact: this.socialImpact
		});
	}

	createLotFormGroup(): FormGroup {
		return this._fb.group({
			ingrediant: [null, CommonValidators.required],
			lot: [null],
			producer: [null],
		});

	}
	addLot() {
		(<FormArray>this.entityForm.get("lotsInfo")).push(this.createLotFormGroup());
	}
	removeLot(index) {
		(<FormArray>this.entityForm.get("lotsInfo")).removeAt(index);
	}



	private marshalData = (data: any): any => {
		return data;		
	}

	private unmarshalData = (data: any): any => {
		return data;
	}

	
    public save() {
        if (this.canSaveForm) {
            this._restaurantProvider.saveOrUpdateRestaurant(DOMAINS_CONFIG.casearia, this.marshalData(this.entityForm.value))
			.pipe(
				catchError(error => {
					return of(null);
				})
			)
			.subscribe(
				(result) => {
					
					this.certifyData(result).then((certResult) => {
						this._activatedRoute.queryParams.subscribe(qParam => {
							if (qParam.returnUrl) {
								this._router.navigateByUrl(qParam.returnUrl);
							}
							else {
								if (result) {
									//console.log("Salvataggio completato correttamente");
									this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_OK]);
								}
								else {
									//console.log("Salvataggio completato correttamente");
									this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
								}
							}
						})
					})
				},
				(error) => {
					//console.log("Salvataggio completato correttamente");
					this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
				}
			)
        }
		else {
			//console.log("form not valid");
			
		}
    }


	public get logoImage(): string {
		return "assets/img/logos/logo_bicolor.svg";
	}

	public get canSaveForm(): boolean {
		return this.entityForm.valid && this.valid;
	}
}


