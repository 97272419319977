<modal title="common.errors" (onClose)="close()"  headerBgColor="gradient-man-of-steel">
    <ng-container body>
        <ul>
            <li *ngFor="let error of data">
                {{error}}
            </li>
        </ul>
    </ng-container>
    <div class="text-right" footer>
        <button type="button" class="btn btn-outline-secondary" (click)="close()">{{'common.close' |
            translate}}</button>
    </div>
</modal>