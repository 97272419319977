import { Component, forwardRef, Optional, Host, SkipSelf, Input, Inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseInputComponent } from './base-input.component';
import { TranslateService } from '@ngx-translate/core';
import { TagModel, TagModelClass } from 'ngx-chips/core/accessor';
@Component({
    selector: 'input-tags',
    templateUrl: './input-tags.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputTagsComponent),
        multi: true
    }]
})
export class InputTagsComponent extends BaseInputComponent<string[], TagModelClass[]> {
    @Input() public horizontal: boolean = true;
	@Input() public initialPlaceholder: string = "common.insertAnyKeyAndPressEnter";
    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, translateService: TranslateService) {
        super(controlContainer, translateService);
    }


    protected toInternalFormat(value: string[]): TagModelClass[] {
        return value.map(m => <TagModelClass>{
            display: m,
            value: m
        });
    }
    protected toExternalFormat(value: TagModelClass[]): string[] {
        return value.map(m => m.value);
    }
}
