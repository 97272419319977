<div [class]="headerClass">
    <h4 class="modal-title">
		<i *ngIf="iconClass" class="font-medium-1 {{iconClass}}"></i> {{title | translate}}</h4>
    <button type="button" class="close modal-text-color" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng-content select="[body]"></ng-content>
</div>
<div class="modal-footer">
    <ng-content select="[footer]"></ng-content>
</div>