<!-- <base-page [title]="'Tables' | translate" [description]="'Componenti Tabelle' | translate"> -->
<base-page>
    <ng-container page-body>
        <div class="card">

            <div class="card-header">
                {{entityName}} {{domainName}}
            </div>
			
            <div class="card-content">
                <div class="card-body">

							<form [formGroup]="formControl">
								<div class="row">
									<div class="col-6 col-lg-6 col-md-6 col-sm-12">
										<input-slide formControlName="certifyData" label="certifica dopo l'inserimento" [horizontal]="false"> </input-slide>
									</div>
									<div class="col-6 col-lg-6 col-md-6 col-sm-12">
										<input id="fileExcel" type="file" formControlName="fileExcel" (change)="onFileChange($event)" label="scegli il file" />
										<!-- <input type="file" (change)="onFileChange($event)" /> -->
									</div>
								</div>
								<div class="row ">
									<div class="col-12 text-right">
										<button type="button" (click)="loadExcel()"  class="btn btn-primary">
											<i class="fas fa-file-excel"></i>&nbsp;Importa dati
										</button>
										
									</div>
								</div>
								<div class="row ">
									<div class="col-12 text-right">
										<div id="output"></div>

									</div>
								</div>
							</form>

					
					<!-- <hello name="{{ name }}"></hello>
						<p>
							Start editing to see some magic happen :)
						</p>

						

						
						<br>
						<div *ngIf="willDownload">
							<a id="download"> Download JSON File </a>
						</div> -->

                </div>
            </div>
        </div>
		
		<div>
			<h1>LOTTI importati: {{counter}}</h1>
			<p *ngFor="let l of lots">
				{{l}}
			</p>
		</div>
    </ng-container>
</base-page>