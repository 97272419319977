<modal title="{{title | translate}}" (onClose)="close()" headerBgColor="gradient-man-of-steel">
	<div body>
		<div class="row" *ngIf="data">
			<div class="col-12 d-flex flex-sm-row flex-column justify-content-center">
				<qrcode [qrdata]="url" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
			</div>
		</div>
	</div>
	
    <div class="text-right" footer>
		<button type="button" (click)="close()" class="btn btn-outline-secondary"><i class="{{iconCancel}}"></i> {{'common.close' | translate}}</button>
    </div>
</modal>