import { Component, Input, OnInit } from "@angular/core";
import { ModalOptions } from "app/common/models/models";
import { ModalService } from "app/common/services/modal.service";
import { iconQRCode } from "app/common/utils/icons.utils";
import { ClaimedNFT } from "app/models/profile.models";
import { domainConfig } from "app/services/DomainConfigProvider";
import { RestaurantProvider } from "app/services/restaurant.service";
import { SimpleQRCodeComponent } from "../modals/simple-qrcode.component";

@Component({
    selector: 'claimed-nft',
    templateUrl: './claimed-nft.component.html'
})
export class ClaimedNFTComponent implements OnInit {
	@Input() claimedNFT: ClaimedNFT;

	qrCodeIcon = iconQRCode;
	claimedDescription: any = "";

	ngOnInit(): void {
		if (this.claimedNFT) {
			let toCall;
			if (this.claimedNFT.certIdFieldName) {
				toCall = this._restaurantProvider.getRestaurantByField(this.claimedNFT.domain, this.claimedNFT.certIdFieldName, this.claimedNFT.cert_id);

			}
			else {
				toCall = this._restaurantProvider.getRestaurant(this.claimedNFT.domain, this.claimedNFT.cert_id);
			}

			toCall.subscribe((result) => {
				if (result) {
					if (this.claimedNFT.claimedFieldName &&  result[this.claimedNFT.claimedFieldName])
						this.claimedDescription = result[this.claimedNFT.claimedFieldName];
					else this.claimedDescription = this.claimedNFT.domain;
				}
			})
		
		}	
	}
	private _nftUrl;
	get nftUrl() {
		if (this._nftUrl) return this._nftUrl;
		if (this.claimedNFT) {
			if (domainConfig[this.claimedNFT.domain] && domainConfig[this.claimedNFT.domain].blockchainUrlVerify) {
				this._nftUrl = domainConfig[this.claimedNFT.domain].blockchainUrlVerify.replaceAll("{domain}", this.claimedNFT.domain).replaceAll("{id}", this.claimedNFT.cert_id);
			}
			else {
				this._nftUrl = `https://verifier.posti.world/${this.claimedNFT.domain}/${this.claimedNFT.cert_id}/verify/token`;
			}
			return this._nftUrl;
		}
	}

	showCode() {
		let modalOption: ModalOptions<boolean> = {
			size: 'md'
		}
		this._modalService.show(SimpleQRCodeComponent, {url: this._nftUrl, title: "QRcode del tuo NFT"}, modalOption);
	}
    constructor(private _restaurantProvider: RestaurantProvider, private _modalService: ModalService) {

    }

   
}

