<div class="card">
	<div class="card-header">
		{{'common.filterResult' | translate}}
	</div>
	<div class="card-content">
		<div class="card-body">
			<form [formGroup]="form" *ngIf="form">
				<div class="row" *ngFor="let field of filterFielsDescription">
					<div class="col-12">
						<input-string *ngIf="field.fieldType == 'inputstring'" [formControlName]="field.fieldName" [label]="field.label"></input-string>
						<input-string *ngIf="field.fieldType == 'multiline'" [formControlName]="field.fieldName" [label]="field.label" [rows]="3"></input-string>
						<input-html *ngIf="field.fieldType == 'html'" [formControlName]="field.fieldName" [label]="field.label"></input-html>
					
						<select-box *ngIf="field.fieldType == 'miltipleselect'" [formControlName]="field.fieldName"  [label]="field.label" [items]="field.possibleDataValues | async" [multiple]="true"></select-box>
					</div>
				</div>
				<div class="row text-right">
					<div class="col">
						<button type="button" (click)="requestReset()" class="btn btn-outline-secondary"><i class="{{iconCancel}}"></i> {{'common.resetFormButtonLabel' | translate}}</button>
						&nbsp;&nbsp;
						<button type="button" (click)="requestUpdate()" [disabled]="!form.valid" class="btn btn-primary"><i class="{{iconSave}}"></i> {{'common.applyFilterButtonLabel' | translate}}</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>