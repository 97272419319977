import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';

import { iconAdd, iconTrash } from "app/common/utils/icons.utils";
import { DOMAINS_CONFIG, RestaurantProvider } from 'app/services/restaurant.service';

import { ActivatedRoute, Router } from '@angular/router';
import { SubmissionResult } from 'app/pages/forms/result-form-page/result-form-submission.page';
import { catchError } from 'rxjs/operators';
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { FORM_SUBMISSION_RESULT } from '../routing-constants';
import { environment } from 'environments/environment';
import { domainConfig } from 'app/services/DomainConfigProvider';
import { BlockChainService } from 'app/services/blockchain.service';
import { ProfileService } from 'app/services/profile.service';
import { ValidatorUtils } from 'app/common/components/form/validators/validator-utils';
import { SelectListitem } from 'app/common/models/models';
import { EntityCertificationBaseFormPage } from '../EntityCertificationBaseFormPage';
import { ModalService } from 'app/common/services/modal.service';

@Component({
    selector: 'edit-entity-page',
    templateUrl: './edit-entity.page.html'
})

export class EditEntityPage extends EntityCertificationBaseFormPage implements OnInit {
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	iconAdd = iconAdd;
	iconDelete = iconTrash;
	editorOptions;
	private currentEntityClass = "";
	public currentEntityDef: any = {};
	public entityForm: FormGroup = null;	

	ngOnInit() {
		this.certificationConfig = environment.certificationConfig.DEFAULT_CONFIG;
		this._activatedRoute.params.subscribe(params => {
			let id = params['id'];
			this.currentEntityClass = params["entityClass"];

			let domain = params["domain"];
			this.currentDomainConfig = domainConfig[domain];
			
			if (id != undefined || id != null) {
				this._restaurantProvider.getRestaurant(domain,id).subscribe((result) => {
					this.initForm(result);					
					this.entityForm.patchValue(this.unmarshalData(result));
				})
			}
			else {
				this.initForm();
			}
		});
	}

    constructor( _chainService: BlockChainService, private _activatedRoute: ActivatedRoute, _fb: FormBuilder, _restaurantProvider: RestaurantProvider, private _router: Router, _userProfileService: ProfileService, private _modalService: ModalService) {
		super(_restaurantProvider, _chainService, _fb, _userProfileService);
    }


	private initForm = (initData?: any) => {
		let entityFormDef: any = {
			id: [null]
		};

		this.currentDomainConfig.entityDefinition.forEach(def => {
			if (def.entityType == this.currentEntityClass) {
				this.currentEntityDef = def;
				entityFormDef["entityType"] = [def.entityType];
				entityFormDef["entityName"] = [def.entityName];
				def.groups.forEach(group => {
					let entityDef: any = {};
					group.fields.forEach((field) => {
						entityDef[""+field.fieldId] = [field.defaultValue, field.validators];
					})
					entityFormDef[""+group.groupId] = this._fb.group(entityDef);
				});
			}
		});
		this.entityForm = this._fb.group(entityFormDef);
	}

	private marshalData = (data: any): any => {
		return data;		
	}

	private unmarshalData = (data: any): any => {
		return data;
	}

	
    public save(certData?: boolean) {
		console.log("saving" , this.entityForm);
		
        if (this.entityForm.valid) {
            this._restaurantProvider.saveOrUpdateRestaurant(this.currentDomainConfig.domainId, this.marshalData(this.entityForm.value))
			.pipe(
				catchError(error => {
					this._modalService.showErrors(["Si è verificato un errore durante il salvataggio dei dati."]);
					return of(null);
				})
			)
			.subscribe(
				(result) => {
					if (certData) {
						this.certifyData(this.currentEntityDef, result)
							.catch((error) => {
								this._modalService.showErrors(["Si è verificato un errore durante la fase di certificazione dei dati. Si prega di riprovare più tardi o contattare il servizio clienti."]);
							})
							.then((certResult) => {
								this._activatedRoute.queryParams.subscribe(qParam => {
									if (qParam.returnUrl) {
										this._router.navigateByUrl(qParam.returnUrl);
									}
									else {
										if (result) {
											this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_OK]);
										}
										else {
											this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
										}
									}
								})
							})
					}
					else {
						this._activatedRoute.queryParams.subscribe(qParam => {
							if (qParam.returnUrl) {
								this._router.navigateByUrl(qParam.returnUrl);
							}
							else {
								if (result) {
									this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_OK]);
								}
								else {
									this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
								}
							}
						})
					}
				},
				(error) => {
					//console.log("Salvataggio completato correttamente");
					this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
				}
			)
        }
		else {
			//console.log("form not valid");
			
		}
    }

	public get logoImage(): string {
		return "assets/img/logos/logo_bicolor.svg";
	}
	public get canSaveForm(): boolean {
		return this.entityForm.valid && this.valid;
	}
}


