<base-page>
	<ng-container page-body>
		<div class="card form-card">
			<form [formGroup]="restaurantInfo" *ngIf="initialized">
				<aw-wizard>
					

					<aw-wizard-step stepTitle="Dati Generali">
						<div class="card-header logo-form">
							<img class="" [src]="logoImage">
						</div>
						<div class="card-content form-content">
							<div class="card-body">
								<div class="row formContent">
									<div class="col-12">
                                        <div class="subtitle-form">
                                            <h3>{{'formContactLabels.contactsTitle' | translate}}</h3>
                                        </div>
                                        <form [formGroup]="contactInfo">
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="firstName" label="{{'formContactLabels.firstName' | translate}}"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="lastName" label="{{'formContactLabels.lastName' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row ">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="phone" label="{{'formContactLabels.phone' | translate}}" placeHolder="{{'formPlaceholder.phone' | translate}}"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="email" label="{{'formContactLabels.email' | translate}}" placeHolder="{{'formPlaceholder.email' | translate}}"></input-string>
                                                </div>
                                            </div>
                                        </form>
										
                                        <div class="subtitle-form">
                                            <h3>{{'formLabels.title' | translate}}</h3>
                                        </div>
                                        <!-- <form [formGroup]="restaurantInfo"> -->
											<div class="row">
                                                <div class="col-12">
                                                    <input-file formControlName="logo" [possibileMediaTypes]="possibileMediaTypes" label="{{'deliveryLabel.logo' | translate}}*">
													</input-file>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-2 col-md-2 col-sm-12">
                                                    <input-slide formControlName="fipePartner" label=formLabels.fipePartner [horizontal]="false">
                                                    </input-slide>
                                                </div>
                                                <div *ngIf="restaurantInfo && restaurantInfo.value.fipePartner" class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="fipeCardNumber" label=formLabels.fipeCardNumber placeHolder=formPlaceholder.fipeCardNumber></input-string>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="businessName" label="{{'formLabels.businessName' | translate}}*"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-122">
                                                    <input-string formControlName="sign" label="{{'formLabels.sign' | translate}}"></input-string>
                                                </div>
                                            </div>
											<div class="row">
                                                <div class="col-6">
													<input-string formControlName="sdiOrPec" maxLength="7" label="SDI">
													</input-string>
                                                </div>
												<div class="col-6">
													<input-string formControlName="pec" label="PEC">
													</input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="vatNumber" label="{{'formLabels.vatNumber' | translate}}*"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="representative" label="{{'formLabels.representative' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="address" label="{{'formLabels.address' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="zipCode" label="{{'formLabels.zipCode' | translate}}"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="city" label="{{'formLabels.city' | translate}}"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="province" label="{{'formLabels.province' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="phone" label="{{'formLabels.phone' | translate}}" placeHolder="{{'formPlaceholder.phone' | translate}}"></input-string>
                                                </div>

                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="mobilePhone" label="{{'formLabels.mobilePhone' | translate}}" placeHolder="{{'formPlaceholder.phone' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="email" label="{{'formLabels.email' | translate}}*" placeHolder="{{'formPlaceholder.email' | translate}}"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="website" label="{{'formLabels.website' | translate}}" placeHolder="{{'formPlaceholder.website' | translate}}"></input-string>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="socialFacebook" label="{{'formLabels.socialFacebook' | translate}}" placeHolder="{{'formPlaceholder.socialFacebook' | translate}}"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="socialInstagram" label="{{'formLabels.socialInstagram' | translate}}" placeHolder="{{'formPlaceholder.socialInstagram' | translate}}"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="socialTikTok" label="{{'formLabels.socialTikTok' | translate}}" placeHolder="{{'formPlaceholder.socialTikTok' | translate}}"></input-string>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="description" label="{{'formLabels.description' | translate}}" placeHolder="{{'formPlaceholder.description' | translate}}" [rows]="5"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="ownerStory" label="{{'formLabels.ownerStory' | translate}}" placeHolder="{{'formPlaceholder.ownerStory' | translate}}" [rows]="5"></input-string>
                                                </div>
                                            </div>
                                            <div class="subtitle-form">
                                                <h3>{{'infoLabels.utilTitle' | translate}}</h3>
                                            </div>
                                        <!-- </form> -->
                                        <form [formGroup]="usefullInfo" class="my-4">
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="openingTime" label="{{'infoLabels.openingTime' | translate}}*" placeHolder="{{'formPlaceholder.openingTime' | translate}}"></input-string>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="weeklyClosing" label="{{'infoLabels.weeklyClosing' | translate}}*" placeHolder="{{'formPlaceholder.weeklyClosing' | translate}}"></input-string>

                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="annualClosing" label="{{'infoLabels.annualClosing' | translate}}*" placeHolder="{{'formPlaceholder.annualClosing' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="creditCards" label="{{'infoLabels.creditCards' | translate}}" placeHolder="{{'formPlaceholder.creditCards' | translate}}"></input-string>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <select-box formControlName="accessibility" label="{{'infoLabels.accessibility' | translate}}" [items]="selectItemsAcc" [multiple]="true">
                                                    </select-box>
                                                </div>
                                                <div class="col-12">
                                                    <input-slide formControlName="reservation" label="{{'infoLabels.reservation' | translate}}" [horizontal]="true">
                                                    </input-slide>
                                                </div>
                                                <div class="col-12">
                                                    <input-slide formControlName="parking" label="{{'infoLabels.parking' | translate}}" [horizontal]="true">
                                                    </input-slide>
                                                </div>
                                                <div class="col-12">
                                                    <input-slide formControlName="petsAllowed" label=infoLabels.petsAllowed [horizontal]="true">
                                                    </input-slide>
                                                </div>
                                                <div class="col-12">
                                                    <input-slide formControlName="wifi" label="{{'infoLabels.wifi' | translate}}" [horizontal]="true"> </input-slide>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-int formControlName="internalSeats" label="{{'infoLabels.internalSeats' | translate}}"></input-int>
                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-int formControlName="externalSeats" label="{{'infoLabels.externalSeats' | translate}}"></input-int>
                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="kitchenType" label="{{'infoLabels.kitchenType' | translate}}*" placeHolder="{{'formPlaceholder.kitchenType' | translate}}"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <select-box formControlName="specialDietsDishes" label="{{'infoLabels.specialDietsDishes' | translate}}" [items]="selectItemsProd" [multiple]="true">
                                                    </select-box>
                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-slide formControlName="specialProductsUsage" label="{{'infoLabels.specialProductsUsage' | translate}}" [horizontal]="false"> </input-slide>

                                                </div>
                                                <div class="col-12 col-lg-4 col-md-4 col-sm-12">
                                                    <input-string formControlName="priceRange" label="{{'infoLabels.priceRange' | translate}}" placeHolder="{{'formPlaceholder.priceRange' | translate}}"></input-string>
                                                </div>
                                            </div>
                                        </form>

                                        <button type="button" class="wizardBtn" awPreviousStep>INDIETRO</button>
                                        <button id="btnNext2" type="button" class="wizardBtn" [disabled]="!stepOneValid" awNextStep>AVANTI</button>
                                        <br>
                                        <div *ngIf="!stepOneValid" class="subtitle-form">
                                            <p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
                                        </div>
									</div>
								</div>
							</div>
						</div>
					</aw-wizard-step>

					

					<aw-wizard-step stepTitle="Asporto e Delivery">
						<div class="card-content form-content">
							<div class="card-body">
								<div class="row formContent">
									<div class="col-12">
										<div class="subtitle-form">
                                            <h3>{{'deliveryLabel.title' | translate}}</h3>
                                        </div>
                                        
											
										<div class="row">
											<div class="col-12 col-lg-6 col-md-6 col-sm-12">
												<form [formGroup]="deliveryMenu" class="my-4">
													
													<input-file formControlName="menuImage" [possibileMediaTypes]="possibileMediaTypes" label="Menù specifico per il delivery (PDF, DOC, IMMAGINI)"></input-file>
													
												</form>
											</div>

										</div>
										<form [formGroup]="deliveryInfo">
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <select-box formControlName="paymentType" label="{{'deliveryLabel.paymentType' | translate}}" [items]="selectItemsPayment" [multiple]="true">
                                                    </select-box>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <select-box formControlName="menuType" label="{{'deliveryLabel.menuType' | translate}}" [items]="selectItemsMenuType" [multiple]="true">
                                                    </select-box>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="deliveryDays" label="{{'deliveryLabel.deliveryDays' | translate}}*"></input-string>
                                                </div>
                                            </div>
                                            <div class="row ">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="lunchDeliveryHours" label="{{'deliveryLabel.lunchDeliveryHours' | translate}}*"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="dinnerDeliveryHours" label="{{'deliveryLabel.dinnerDeliveryHours' | translate}}*"></input-string>
                                                </div>
                                            </div>
                                            <div class="row ">
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="deliverySolutionsTimeInterval" label="{{'deliveryLabel.deliverySolutionsTimeInterval' | translate}}*"></input-string>
                                                </div>
                                                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                                                    <input-string formControlName="orderRestrictions" label="{{'deliveryLabel.orderRestrictions' | translate}}*"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="menuSectionsToExclude" label="{{'deliveryLabel.menuSectionsToExclude' | translate}}*"></input-string>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <input-string formControlName="notes" label="{{'deliveryLabel.notes' | translate}}*" [rows]="5"></input-string>
                                                </div>
                                            </div>
											
                                        </form>

                                        <button type="button" awPreviousStep class="wizardBtn">INDIETRO</button>
                                        <button type="button" (click)="save()" class="wizardBtn btn form-submit" [disabled]="!stepTwoValid" id="btnNext3">SALVA</button>
                                        <button type="button" (click)="save(true)" class="wizardBtn btn form-submit" [disabled]="!stepTwoValid" id="btnNext3">SALVA E INVIA EMAIL</button>

                                        <div *ngIf="!stepTwoValid" class="subtitle-form">
                                            <p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
                                        </div>
									</div>
								</div>
							</div>
						</div>
					</aw-wizard-step>
				</aw-wizard>
			</form>
		</div>
	</ng-container>
</base-page>