import { FormBuilder, FormGroup } from "@angular/forms";
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { UserProfile } from "app/models/profile.models";
import { BlockChainService } from "app/services/blockchain.service";
import { ProfileService } from "app/services/profile.service";
import { RestaurantProvider } from "app/services/restaurant.service";

export class CertificationConfig {
	name: string;
    identity?: string;
    pubkey?: string;
    external_api?: string;
}

export class EntityCertificationBaseFormPage {
	protected certificationConfig: CertificationConfig;
	protected currentDomainConfig;
	public walletForm: FormGroup = null;

	constructor(protected _restaurantProvider: RestaurantProvider, private _chainService: BlockChainService, protected _fb: FormBuilder, protected _userProfileService: ProfileService) {
		this.walletForm =this._fb.group({
			secret: [null, CommonValidators.required]
		});
	}
	private path = (obj,path) => {
        try{
            return eval("obj."+path);
        } catch(e) {
            return undefined;
        }
    }

	protected get valid() {
		if (this.walletForm) {
			return this.walletForm.valid;
		}
		return false;
	}

	protected  certifyData = async (entityDef, dataToCertify: any) => {
		let data = this.currentDomainConfig.marshalCerdData(dataToCertify);
		let certificationFieldsSpec: any = entityDef.resetCertificationFieldsSpec(dataToCertify);
		
		
		let fieldsToCertify : any = {};
		for (const element of certificationFieldsSpec) {
			if (element.isArray) {
				fieldsToCertify = [];
				for (const obj of data[element.fieldId]) {
					if (!obj.removedFromBlockChain) {
						let objToCert: any = {};
						for (const spec of element.arrayFieldsSpec) {
							if (this.path(obj, spec.fieldId)) {
								objToCert[spec.fieldLabel] =  (spec.startValue) ? spec.startValue + this.path(obj, spec.fieldId): this.path(obj, spec.fieldId);
								if (spec.isImage) {
									await this._restaurantProvider.getImageHash(this.path(obj, spec.fieldId)).toPromise().then((imgHash) => {
										objToCert[spec.fieldLabel.replaceAll("_url","_hash")] = imgHash;
									});
								}
	
							}
						}
						fieldsToCertify.push(objToCert);
					}
					
				}
			}
			else {
				if (this.path(data, element.fieldId)) {
					if (element.isImage) {
						fieldsToCertify[element.fieldLabel + "_url"] =  (element.startValue) ? element.startValue + this.path(data, element.fieldId): this.path(data, element.fieldId);
						await this._restaurantProvider.getImageHash(this.path(data, element.fieldId)).toPromise().then((imgHash) => {
							fieldsToCertify[element.fieldLabel+ "_hash"]= imgHash;
						});
					}
					else {
						fieldsToCertify[element.fieldLabel] =  (element.startValue) ? element.startValue + this.path(data, element.fieldId): this.path(data, element.fieldId);
	
					}
				}
				

			}
		}
		let toSend: any = new Object();
		let userProfile: UserProfile;
		await this._userProfileService.getLoggedProfile().toPromise().then((profile) => {
			userProfile = profile;
		});
		
		if (userProfile) {
			toSend.identity = userProfile.email;
			toSend.name = userProfile.firstName + " " + userProfile.lastName;
			toSend.pubkey = userProfile.walletInfo.pubKey;
		}
		else {
			Object.assign(toSend, this.certificationConfig);

		}
	



		toSend.azienda = this.currentDomainConfig.domainId;
		toSend.cert_id = this.path(data, this.currentDomainConfig.certIdField);

		toSend.data = fieldsToCertify;

		console.log("this.walletForm.value,", this.walletForm.value.secret);
		
		
		return this._chainService.certifyEntity(toSend, this.walletForm.value.secret).toPromise();
	}

}