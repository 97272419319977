import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { HttpService } from './http.service';
import jwtDecode from "jwt-decode";
import { environment } from 'environments/environment';
import moment from 'moment'
import { DOMAINS_CONFIG } from './restaurant.service';
import { UserProfile } from '../models/profile.models';

// access control rule
export class ACR {
	id: any;
	entityDomain: DOMAINS_CONFIG;
	entityId: any;
	entityName: string;
	userId: string;
}

var ACR_DOMAINS_CONFIG = "__ACR"
@Injectable()
export class ACRService {
	private baseUrl = environment.services.restaurantService + "/" + ACR_DOMAINS_CONFIG;
	
	constructor(private _oauthService: OAuthService, protected _httpService: HttpService) {
	}
	
	public getAll = (fieldsName?: string[]): Observable<ACR[]> => {
		let queryParams = ""
		if (fieldsName && fieldsName.length>0) {
			queryParams = "?";
			fieldsName.forEach(field => {
				queryParams += "fields="+field+ "&";
			})
		}

		return this._httpService.get(this.baseUrl + queryParams, "json");
	}
	public getACR = (id: any): Observable<ACR> => {
		let url = this.baseUrl + "/" + id;
		return this._httpService.get(url);
	}
	public getACRByDomain = (domainId: DOMAINS_CONFIG): Observable<ACR[]> => {
		let url = this.baseUrl + "?entityDomain=" +domainId;
		return this._httpService.get(url).pipe(
			catchError(r => {
				return of([])
			}),
			map((result: ACR[]) => {
				return result.filter((r) => {
					return r.entityDomain == domainId;
				})
			})
		);
	}
	public getACRByEntityId = (entityId: string): Observable<ACR[]> => {
		
		let url = this.baseUrl + "?entityId=" + entityId;
		return this._httpService.get(url).pipe(
			catchError(r => {
				return of([])
			}),
			map((result: ACR[]) => {
				return result.filter((r) => {
					return r.entityId == entityId;
				})
			})
		);
	}
	public getACROfProfile = (userProfile: UserProfile): Observable<ACR[]> => {
		let url = this.baseUrl + "?userId=" +userProfile.userId;
		return this._httpService.get(url).pipe(
			catchError(r => {
				return of([])
			}),
			map((result: ACR[]) => {
				return result.filter((r) => {
					return r.userId == userProfile.userId;
				})
			})
		);
	}

	public getACRByField = (fieldId: string, value: any): Observable<ACR[]> => {
		let url = this.baseUrl + "/" + fieldId + "/" + value;
		return this._httpService.get(url);
	}
	
	public deleteACR = (acr: ACR): Observable<ACR> => {
		let url =  this.baseUrl + "/" + acr.id;
		return this._httpService.delete(url);
	}
	public saveOrUpdateACR = (acr: ACR, toNotify: boolean = false): Observable<ACR> => {
		let url = this.baseUrl;
		if (acr.id)
			return this._httpService.put(url + "/"+acr.id + "?notify="+toNotify, acr, "json");
		return this._httpService.post(url + "?notify="+toNotify, acr,"json");
	}
}

