<modal [title]="data.title | translate" (onClose)="close()" headerBgColor="gradient-man-of-steel">
	<div body>
		<div #mapModal class="map-container">
			<div class="action-container">
				<button type="button" style="align-items: center;" class="btn btn-primary btn-sm"
					(click)="drawOnMap('Point')" [disabled]="isCenterPresent"><i class="{{iconCenter}}" ></i>

				</button>
				<!-- <button type="button" style="align-items: center;" class="btn btn-primary btn-sm"
					(click)="drawOnMap('Polygon')" [disabled]="isPolygonPresent"><i class="{{iconPolygon}}"></i>
				</button> -->
				<button type="button" style="align-items: center;" class="btn btn-primary btn-sm" (click)="delete()"  [disabled]="!deleteEnabled"><i
						class="{{iconTrash}}"></i>
				</button>
			</div>

		</div>
	</div>
	<div class="text-right" footer>
		<button type="button" (click)="close()" class="btn btn-outline-secondary"><i class="{{iconCancel}}"></i>
			{{'common.cancelButtonLabel' | translate}}</button>
		&nbsp;&nbsp;
		<button type="button" (click)="ok()" [disabled]="dataValid"
			class="btn btn-primary"><i class="{{iconSave}}"></i> {{'common.confirmButtonLabel' | translate}}</button>
	</div>
</modal>