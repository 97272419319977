<base-page>
    <ng-container page-body>
        <div class="card form-card">
            <form [formGroup]="entityForm" *ngIf="entityForm">
                <aw-wizard>
                    <aw-wizard-step stepTitle="Dati Generali">
                        <div class="card-header logo-form" style="background-color: white;">
                            <img class="" [src]="logoImage">
                        </div>
                        <div class="card-content form-content">
                            <div class="card-body">
                                <div class="row formContent">
                                    <div class="col-12">
                                        <div class="subtitle-form">
                                            <h3>Informazioni Generali</h3>
                                        </div>
                                        
										<div class="row">
											<div class="col-12">
												<input-string formControlName="companyName" label="Azienda"></input-string>
											</div>
										</div>
										<div class="row ">
											<div class="col-12 col-lg-6 col-md-6 col-sm-12">
												<input-string formControlName="city" label="Provenienza"></input-string>
											</div>
											<div class="col-12 col-lg-6 col-md-6 col-sm-12">
												<input-string formControlName="province" label="Provincia di provenienza"></input-string>
											</div>
											
										</div>
										<div class="row ">
											<div class="col-12 col-lg-6 col-md-6 col-sm-12">
												<input-string formControlName="productName" label="Prodotto"></input-string>
											</div>
											<div class="col-12 col-lg-6 col-md-6 col-sm-12">
												<input-string formControlName="productSpecie" label="Varietà"></input-string>
											</div>
										</div>
										<div class="row ">
											<div class="col-12">
												<input-file formControlName="image" [possibileMediaTypes]="possibileMediaTypes" label="Immagine">
												</input-file>
											</div>
										</div>
										
                                        <div class="subtitle-form">
                                            <h3>Informazioni generiche dipendenti dal lotto</h3>
                                        </div>
                                        <form [formGroup]="lotInfo">
                                           
											<div class="row">
												<div class="col-12">
													<input-string formControlName="lotNumber" label="Lotto di produzione">
													</input-string>
												</div>
											</div>
                                            <div class="row">
                                                <div class="col-4">
													<input-date utc="false" formControlName="collectionDate" label="data di raccolta"></input-date>
												</div>
												<div class="col-4">
													<input-date utc="false" formControlName="productionDate" label="data della molitura"></input-date>
												</div>
												<div class="col-4">
													<input-date utc="false" formControlName="bottlingDate" label="data di imbottigliamento"></input-date>
                                                    
                                                </div>
                                            </div>
											<div class="row ">
												<div class="col-12">
													<input-string formControlName="giftDescription" label="Descrizione del regalo associato"></input-string>
												</div>
											</div>
                                        </form>
										
										<div class="subtitle-form">
                                            <h3>chiavi di sicurezza</h3>
                                        </div>
                                        <form [formGroup]="walletForm">
                                            <div class="row">
                                                <div class="col-12 ">
                                                    <input-string formControlName="secret" label="Chiave privata del tuo wallet"></input-string>
                                                </div>
                                            </div>
											
                                        </form>
                                        <button type="button" class="wizardBtn" awPreviousStep>INDIETRO</button>
                                        <button id="btnNext1" type="button" class="wizardBtn" [disabled]="!canSaveForm" (click)="save()" >SALVA</button>
                                        <br>
                                        <div *ngIf="!canSaveForm" class="subtitle-form">
                                            <p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aw-wizard-step>

                    
                </aw-wizard>
            </form>
        </div>
    </ng-container>
</base-page>