<base-page>
    <ng-container page-body>
        <div class="card form-card">
            <form [formGroup]="entityForm" *ngIf="entityForm">
                <aw-wizard>
                    <aw-wizard-step stepTitle="Dati Generali">
                        <div class="card-header logo-form" style="background-color: white;">
                            <img class="" [src]="logoImage">
                        </div>
                        <div class="card-content form-content">
                            <div class="card-body">
                                <div class="row formContent">
                                    <div class="col-12">
                                        <div class="subtitle-form">
                                            <h3>Informazioni Generali</h3>
                                        </div>
										<div class="row ">
											<div class="col-12">
												<input-file formControlName="image" [possibileMediaTypes]="possibileMediaTypes" label="Immagine">
												</input-file>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-lg-6 col-md-6 col-sm-12">
												<input-string formControlName="companyName" label="Azienda"></input-string>
											</div>
											<div class="col-12 col-lg-6 col-md-6 col-sm-12">
												<input-string formControlName="bolloCe" label="Bollo CE"></input-string>
											</div>
										</div>
										<div class="row ">
											<div class="col-12 col-lg-6 col-md-6 col-sm-12">
												<input-string formControlName="productionPlace" label="Luogo di produzione"></input-string>
											</div>
											<div class="col-12 col-lg-6 col-md-6 col-sm-12">
												<input-string formControlName="province" label="Provincia provenienza"></input-string>
											</div>
										</div>
										<div class="row ">
											<div class="col-12">
												<input-string formControlName="giftDescription" label="Descrizione del regalo associato"></input-string>
											</div>
										</div>
										


                                        <div class="subtitle-form">
                                            <h3>Informazioni specifiche</h3>
                                        </div>
                                        <form [formGroup]="productInfo">
                                           
											<div class="row">
												<div class="col-6">
													<input-string formControlName="productName" label="Nome prodotto">
													</input-string>
												</div>
												<div class="col-6">
													<input-string formControlName="producerSpecie" label="Specie produttrice">
													</input-string>
												</div>
											</div>
											<div class="row">
												<div class="col-6">
													<input-string formControlName="diet" label="Alimentazione animale">
													</input-string>
												</div>
												<div class="col-6">
													<input-string formControlName="milkOrigin" label="Provenienza latte utilizzato">
													</input-string>
												</div>
											</div>
                                        </form>
										<div class="subtitle-form">
											<h3>
												Informazioni di dettaglio dipendenti dal lotto <button type="button" (click)="addLot()"  class="wizardBtn"><i class="{{iconAdd}}"></i></button>
											</h3>
										</div>
                                        
										<div *ngFor="let lot of entityForm.get('lotsInfo').controls; let i = index">
											<form [formGroup]="lot">
												<div class="row">
													<div class="col-3">
														<input-string formControlName="ingrediant" label="Ingrediente">
														</input-string>
													</div>
													<div class="col-3">
														<input-string formControlName="lot" label="Lotto">
														</input-string>
													</div>
													
													<div class="col-3">
														<input-string formControlName="producer" label="Produttore">
														</input-string>
													</div>
													<div class="col-3 text-right">
														<button type="button" (click)="removeLot(i)"  class="wizardBtn">
															<i class="{{iconDelete}}"></i>
														</button>
													</div>
												</div>
												
											</form>
											
	
										</div>
										
										<div class="subtitle-form">
                                            <h3>Impatto sociale</h3>
                                        </div>
                                        <form [formGroup]="socialImpact">
                                            <div class="row">
                                                <div class="col-12 ">
                                                    <input-string formControlName="s1" label="Principio etico n.1"></input-string>
                                                </div>
                                            </div>
											<div class="row">
                                                <div class="col-12 ">
                                                    <input-string formControlName="s2" label="Principio etico n.2"></input-string>
                                                </div>
                                            </div>
											<div class="row">
                                                <div class="col-12 ">
                                                    <input-string formControlName="s3" label="Principio etico n.3"></input-string>
                                                </div>
                                            </div>
											<div class="row">
                                                <div class="col-12 ">
                                                    <input-string formControlName="s4" label="Principio etico n.4"></input-string>
                                                </div>
                                            </div>
                                        </form>
										<div class="subtitle-form">
                                            <h3>chiavi di sicurezza</h3>
                                        </div>
                                        <form [formGroup]="walletForm">
                                            <div class="row">
                                                <div class="col-12 ">
                                                    <input-string formControlName="secret" label="Chiave privata del tuo wallet"></input-string>
                                                </div>
                                            </div>
											
                                        </form>
                                        <button type="button" class="wizardBtn" awPreviousStep>INDIETRO</button>
                                        <button id="btnNext1" type="button" class="wizardBtn" [disabled]="!canSaveForm" (click)="save()" >SALVA</button>
                                        <br>
                                        <div *ngIf="!canSaveForm" class="subtitle-form">
                                            <p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aw-wizard-step>

                    
                </aw-wizard>
            </form>
        </div>
    </ng-container>
</base-page>