import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PECORA_NERA_PATH, GIUSTA_PATH, ROMANESCO_PATH } from '../routing-constants';

@Component({
    selector: 'form-choice',
    templateUrl: './form-choice.page.html'
})

export class FormChoicePage implements OnInit{
	pageLoaded = false;
	constructor(private _router: Router, private _activatedRoute: ActivatedRoute) {

	}

	ngOnInit() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if (params["formType"] == "romanesco") this.goToRomanesco();
			else if (params["formType"] == "giusta") this.goToRestaurant();
			else if (params["formType"] == "pecoranera") this.goToPecoraNera();
			else this.pageLoaded = true;
		})
	}

	goToRomanesco() {
		this._router.navigate([ROMANESCO_PATH]);

	}
	goToRestaurant() {
		this._router.navigate([GIUSTA_PATH]);
	}
	goToPecoraNera() {
		this._router.navigate([PECORA_NERA_PATH]);
	}

}